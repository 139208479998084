import React from 'react';
import "./index.scss";
import Button from "../customComponents/Button";
import { FilteredCustomTable } from "../customComponents/Table";
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import { useParams } from 'react-router-dom';
import Spinner from '../customComponents/Spinner';
import EditArticle from "../EditArticle";
import FilterPanel from '../customComponents/FilterPanel';


export default function Articles() {

    const newArticleRef = React.useRef(null);
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }

    const [addArticleModal, setAddArticleModal] = React.useState(false);
    const addArticleHandler = () => {
        setAddArticleModal(a => !a);
        scrollToRef(newArticleRef);
        setMsg(false);
    }

    const [infoError, setInfoError] = React.useState(false);
    const [msg, setMsg] = React.useState(false);
    const { id } = useParams();
    const addArticle = () => {
        if (isNaN(priceRef.current.value)) {
            return setInfoError(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/addArticle`,
            data: {
                BusinessID: id,
                Name: nameRef.current.value,
                Price: priceRef.current.value,
                MPC: mpcRef.current.value,
                Quantity: quantityRef.current.value ? quantityRef.current.value : null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getAllArticles(id);
                setMsg(true);
                setTimeout(() => {
                    addArticleHandler();
                }, 1500);
            };
        }).catch(() => {

        });
    }

    const nameRef = React.useRef(null);
    const priceRef = React.useRef(null);
    const mpcRef = React.useRef(null);
    const nameFieldRef = React.useRef(null);
    const priceFieldRef = React.useRef(null);
    const mpcFieldRef = React.useRef(null);
    const quantityFieldRef = React.useRef(null);
    const quantityRef = React.useRef(null);

    const [editInfo, setEditInfo] = React.useState(null);
    const editHandler = (item) => {
        setEditInfo(item);
    }

    const changeInformations = (fieldRef, inputRef) => {
        inputRef.current.addEventListener('change', () => {
            fieldRef.current.innerText = inputRef.current.value;
        });

        inputRef.current.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                if (inputRef.current.value === '') {
                    setEditInfo(null);
                }
                else {
                    fieldRef.current.innerText = inputRef.current.value;
                    setEditInfo(null);
                }
            }
            else {
                fieldRef.current.innerText = inputRef.current.value;
            }
        });
    }

    const [filters, setFilters] = React.useState([]);
    const [orders, setOrders] = React.useState();
    const [articlesData, setArticlesData] = React.useState([]);
    const [noData, setNoData] = React.useState(false);
    const [serverError, setServerError] = React.useState(false);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(false);

    const getAllArticles = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/getAllArticles`,
            data: {
                filters: [
                    ...filters,
                    { name: 'BusinessID', op: 'eq', value: id }
                ],
                orders: orders,
                pagination: 0,

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
                if (res.data.data.length === 20) {
                    setTimeout(() => setCanPaginate(true), 1000);
                }
                return setArticlesData(res.data);
            };
        }).catch(() => {
            setArticlesData({ status: 'error', data: 'Data fetch failed' });
            return setServerError(true);
        });
    }

    const paginationData = (isInViewPort) => {
        if (isInViewPort) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/articles/getAllArticles`,
                data: {
                    filters: [
                        ...filters,
                        { name: 'BusinessID', op: 'eq', value: id }
                    ],
                    orders: orders,
                    pagination: articlesData.data.length,

                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    /*setArticlesData(old => {
                        return{
                            ...old,
                            data: [
                                ...old.data,
                                ...res.data.data
                            ]
                        };
                    });*/
                    if (res.data.data.length > 0) {
                        setLoadingData(true);
                        setTimeout(() => setLoadingData(false), 100);
                        res.data.data?.map((item) => {
                            articlesData.data.push(item);
                        });
                    }
                };
            }).catch(() => {
                setArticlesData({ status: 'error', data: 'Data fetch failed' });
                return setServerError(true);
            });
        }
    }

    const scrollTriggerRef = React.useRef(null);
    const useIsInViewport = () => {
        const [isIntersecting, setIsIntersecting] = React.useState(false);

        const observer = React.useMemo(
            () =>
                new IntersectionObserver(([entry]) =>
                    setIsIntersecting(entry.isIntersecting),
                ),
            [],
        );

        React.useEffect(() => {
            observer.observe(scrollTriggerRef.current);

            return () => {
                observer.disconnect();
            };
        }, [scrollTriggerRef, observer]);
        if (isIntersecting) {
            paginationData(isIntersecting);
        }
    }

    React.useEffect(() => {
        getAllArticles(id);
    }, [filters]);

    useIsInViewport();

    const deleteArticle = (item_id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/removeArticle`,
            data: {
                ID: item_id,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getAllArticles(id)
            };
        }).catch(() => {

        });
    }

    const [editArticleModal, setEditArticleModal] = React.useState(false);
    const [articleDataForEdit, setArticleDataForEdit] = React.useState([]);
    const editArticleHandler = (id) => {
        setEditArticleModal(e => !e);
        articlesData?.data.map((item, index) => {
            if (item.ID === id) {
                setArticleDataForEdit([item.Name, item.Price, item.MPC, item.ID, item.Quantity]);
            }
        });
    }


    const addArticlesToTable = () => {
        if (articlesData.data) {
            return articlesData?.data.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.Name}</h3><p>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.Price} EUR</h3></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.MPC}</h3></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.Quantity}</h3></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            { keyID: String(item.ID), type: "button", text: "Edit", onClick: e => { editArticleHandler(item.ID) } },
                            {
                                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>Are you sure?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='rgb(157, 195, 241)' value='YES' onClick={() => deleteArticle(item.ID)} />
                                            <Button accent='rgb(157, 195, 241)' value='NO' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    return (
        <div className='component__articles'>
            <div className='component__articles__head'>
                <h1>Articles</h1>
                <Button onClick={() => addArticleHandler()} className='component__articles__head__addArticleButton' accent='#67E564' value={addArticleModal ? 'Cancel' : 'Add article'} />
            </div>
            <br></br>
            <hr></hr>
            <br></br>
            <br></br>
            <p id='add-article-msg' style={{ display: msg ? 'block' : 'none' }}>New article sucessfully added</p>
            <p id='error-article-msg' style={{ display: infoError ? 'block' : 'none' }}>Price must be a number</p>
            <div className='component__articles__body'>

                {(() => {
                    if (editArticleModal === true) {
                        return <EditArticle data={[articlesData, setArticlesData]} handler={editArticleHandler} Name={articleDataForEdit[0]} Price={articleDataForEdit[1]} MPC={articleDataForEdit[2]} id={articleDataForEdit[3]} Quantity={articleDataForEdit[4]} />
                    }
                })()}

                <div ref={newArticleRef} style={{ display: addArticleModal ? 'block' : 'none' }} className='component__articles__body__addArticle'>
                    <h1>Add new article</h1>
                    <br></br>
                    <hr></hr>
                    <br></br>
                    <p>Name</p>
                    <span><strong style={{ display: editInfo === 'name' ? 'none' : 'flex' }} id='article-name' ref={nameFieldRef}>Article name</strong><input ref={nameRef} id='name-input' onChange={() => changeInformations(nameFieldRef, nameRef)} style={{ display: editInfo === 'name' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('name')} src='images/editPen.png' alt='' /></span>
                    <p>Price (EUR)</p>
                    <span><strong style={{ display: editInfo === 'price' ? 'none' : 'flex' }} id='article-price' ref={priceFieldRef}>Price</strong><input ref={priceRef} id='price-input' onChange={() => changeInformations(priceFieldRef, priceRef)} style={{ display: editInfo === 'price' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('price')} src='images/editPen.png' alt='' /></span>
                    <p>Unit of measure</p>
                    <span><strong style={{ display: editInfo === 'mpc' ? 'none' : 'flex' }} id='article-mpc' ref={mpcFieldRef}>UoM</strong><input ref={mpcRef} id='mpc-input' onChange={() => changeInformations(mpcFieldRef, mpcRef)} style={{ display: editInfo === 'mpc' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('mpc')} src='images/editPen.png' alt='' /></span>
                    <p>Quantity (not required)</p>
                    <span><strong style={{ display: editInfo === 'quantity' ? 'none' : 'flex' }} id='article-quantity' ref={quantityFieldRef}>Quantity</strong><input ref={quantityRef} id='quantity-input' onChange={() => changeInformations(quantityFieldRef, quantityRef)} style={{ display: editInfo === 'quantity' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('quantity')} src='images/editPen.png' alt='' /></span>
                    <br></br>
                    <Button onClick={() => addArticle()} accent='rgb(103, 229, 100)' value='Add' />
                </div>

                <Spinner style={{ display: serverError ? 'block' : 'none', width: "64px", height: "64px" }} color="rgb(157, 195, 241)" align="center" />
                <p style={{ display: noData || serverError ? 'block' : 'none', fontFamily: 'Roboto', fontSize: '18px', color: 'red' }}>{noData ? 'No data to display' : ''}{serverError ? articlesData.data : ''}</p>
                <div style={{ display: serverError ? 'none' : 'block' }} className='component__articles__body__allArticles'>
                    <FilteredCustomTable
                        id='table'
                        accent='rgb(157 195 241)'
                        theme='light'
                        headers={['Name', 'Price', 'Unit of measure', 'Quantity']}
                        filters={[
                            { name: "Name", friendlyName: "Name", type: "string" }
                        ]}
                        /*filterCB={(arg)=>{
                          customersData?.data.map((item)=>{
                            if(item.ID != arg[0].value){
                              
                            }
                          })
                        }}*/
                        filterCB={f => setFilters(f)}

                        data={!serverError && !noData ? addArticlesToTable() : [[{keyID: 'noData', type:'custom', data: <p>No data to display</p>}]]}
                    />
                    <br></br>
                    <div style={{ display: canPaginate ? 'block' : 'none' }} ref={scrollTriggerRef}></div>
                    <Spinner style={{ display: loadingData ? 'block' : 'none', width: "64px", height: "64px" }} color="rgb(157, 195, 241)" align="center" />
                </div>
            </div>
        </div>
    )
}
