import React from 'react';
import "./index.scss";
import Button from "../../components/customComponents/Button";
import Spinner from "../../components/customComponents/Spinner";
import { useParams } from 'react-router-dom';
import { FilteredCustomTable } from "../customComponents/Table";
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import EditVehicle from '../EditVehicle';
import TravelOrder from "../TravelOrder";

export default function Vehicles() {

  const manuFieldRef = React.useRef(null);
  const manuRef = React.useRef(null);
  const modelFieldRef = React.useRef(null);
  const modelRef = React.useRef(null);
  const regFieldRef = React.useRef(null);
  const regRef = React.useRef(null);
  const yearFieldRef = React.useRef(null);
  const yearRef = React.useRef(null);
  const colorFieldRef = React.useRef(null);
  const colorRef = React.useRef(null);

  const [editInfo, setEditInfo] = React.useState(null);
  const editHandler = (item) => {
    setEditInfo(item);
  }

  const changeInformations = (fieldRef, inputRef) => {
    inputRef.current.addEventListener('change', () => {
      fieldRef.current.innerText = inputRef.current.value;
    });

    inputRef.current.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        if (inputRef.current.value === '') {
          setEditInfo(null);
        }
        else {
          fieldRef.current.innerText = inputRef.current.value;
          setEditInfo(null);
        }
      }
      else {
        fieldRef.current.innerText = inputRef.current.value;
      }
    });
  }

  const { id } = useParams();
  const [vehicles, setVehicles] = React.useState([]);
  const [serverError, setServerError] = React.useState(false);
  const [noData, setNoData] = React.useState(false);
  const [vehicleModal, setVehicleModal] = React.useState(false);
  const msgRef = React.useState(null);
  const [filters, setFilters] = React.useState([]);

  const vehicleModalHandler = () => {
    setVehicleModal(m => !m);
  }

  const getAllVehicles = (id) => {
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/vehicles/getAllVehicles`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id }
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        //res.data.data.length = 0;
        res.data.data.length > 0 ? setNoData(false) : setNoData(true);
        setVehicles(res.data);
      };
    }).catch(() => {
      setServerError({ type: 'SERVER_ERROR', data: 'Failed to fetch data' });
    });
  }

  React.useEffect(() => {
    getAllVehicles(id);
  }, [filters]);

  const addVehicle = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/vehicles/addVehicle`,
      data: {
        Manufacturer: manuRef.current.value,
        Model: modelRef.current.value,
        RegistrationMarks: regRef.current.value,
        Year: yearRef.current.value,
        Color: colorRef.current.value,
        BusinessID: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        msgRef.current.style.background = 'rgb(103, 229, 100)';
        msgRef.current.style.display = 'block';
        msgRef.current.innerText = 'New vehicle successfully added!';
        getAllVehicles(id);
        setTimeout(() => {
          document.querySelector('#msg').style.display = 'none';
          setVehicleModal(false);
        }, 1500);
      }
      if (res.data.status === 'error') {
        msgRef.current.style.background = 'red';
        msgRef.current.style.display = 'block';
        msgRef.current.innerText = 'Info missing';
        setTimeout(() => {
          msgRef.current.style.display = 'none';
        }, 1500);
      }
    }).catch(() => {

    });
  }

  const addVehiclesToTable = () => {
    if (vehicles.data) {
      return vehicles?.data.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.ID}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.Manufacturer}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.Year}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.Model}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.RegistrationMarks}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.Color}</h3></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "button", text: "Edit", onClick: e => { editVehicleHandler(item.ID) } },
              {
                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                  return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: 'black' }}>Are you sure?</p>
                    <br></br>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button style={{ marginRight: '10px' }} accent='rgb(157, 195, 241)' value='YES' onClick={() => deleteVehicle(item.ID)} />
                      <Button accent='rgb(157, 195, 241)' value='NO' onClick={c => { e() }} />
                    </div>
                  </div>);
                }
              },
            ]
          }
        ];
      });
    }
  }

  const [edit, setEdit] = React.useState(false);
  const [vehicleForEdit, setVehicleForEdit] = React.useState([]);

  const editVehicleHandler = (id) => {
    setEdit(e => !e);
    vehicles?.data.map((item, index) => {
      if (item.ID === id) {
        setVehicleForEdit([item.Manufacturer, item.Model, item.ID, item.Year, item.Color, item.RegistrationMarks]);
      }
    });
  }

  const deleteVehicle = (item_id) => {
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/vehicles/removeVehicle`,
      data: {
        ID: item_id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      getAllVehicles(id);
    }).catch(() => {
    });
  }

  const [travelOrderModal, setTravelOrderModal] = React.useState(false);

  const travelOrderModalHandler = () => {
    setTravelOrderModal(t => !t);
  }

  return (
    <div className='component__vehicles'>
      <div className='component__vehicles__head'>
        <h1>Vehicles</h1>
        <div>
          <Button className='addNewVehicles' accent='rgb(103, 229, 100)' value={vehicleModal ? 'Close' : 'Add new vehicle'} onClick={() => vehicleModalHandler()} />
          <Button className='addNewWarrant' accent='rgb(103, 229, 100)' value={travelOrderModal ? 'Close' : 'Create a new travel order'} onClick={() => travelOrderModalHandler()} />
        </div>
      </div>
      <br></br>
      <hr></hr>
      <br></br>
      <p id='msg' ref={msgRef}></p>
      <div style={{ display: vehicleModal ? 'block' : 'none' }} className='component__vehicles__createNewVehicle'>
        <strong>Add new vehicle</strong>
        <hr></hr>
        <p>Manufacturer</p>
        <span><strong style={{ display: editInfo === 'manu' ? 'none' : 'flex' }} id='vehcile-manu' ref={manuFieldRef}>Some manufacturer</strong><input ref={manuRef} id='manu-input' onChange={() => changeInformations(manuFieldRef, manuRef)} style={{ display: editInfo === 'manu' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('manu')} src='images/editPen.png' alt='' /></span>
        <p>Model</p>
        <span><strong style={{ display: editInfo === 'model' ? 'none' : 'flex' }} id='vehicle-model' ref={modelFieldRef}>Some model</strong><input ref={modelRef} id='model-input' onChange={() => changeInformations(modelFieldRef, modelRef)} style={{ display: editInfo === 'model' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('model')} src='images/editPen.png' alt='' /></span>
        <p>Registration Marks</p>
        <span><strong style={{ display: editInfo === 'reg' ? 'none' : 'flex' }} id='vehicle-req' ref={regFieldRef}>Some registration marks</strong><input ref={regRef} id='reg-input' onChange={() => changeInformations(regFieldRef, regRef)} style={{ display: editInfo === 'reg' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('reg')} src='images/editPen.png' alt='' /></span>
        <p>Year</p>
        <span><strong style={{ display: editInfo === 'year' ? 'none' : 'flex' }} id='vehicle-year' ref={yearFieldRef}>Some year</strong><input ref={yearRef} id='year-input' onChange={() => changeInformations(yearFieldRef, yearRef)} style={{ display: editInfo === 'year' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('year')} src='images/editPen.png' alt='' /></span>
        <p>Color</p>
        <span><strong style={{ display: editInfo === 'color' ? 'none' : 'flex' }} id='vehicle-color' ref={colorFieldRef}>Some color</strong><input ref={colorRef} id='color-input' onChange={() => changeInformations(colorFieldRef, colorRef)} style={{ display: editInfo === 'color' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('color')} src='images/editPen.png' alt='' /></span>
        <br></br>
        <Button onClick={() => addVehicle(id)} accent='rgb(103, 229, 100)' value='Add' />
      </div>
      {
        (() => {
          if (edit) return <EditVehicle bid={id} getAll={getAllVehicles} data={[vehicles, setVehicles]} handler={editVehicleHandler} manufacturer={vehicleForEdit[0]} model={vehicleForEdit[1]} id={vehicleForEdit[2]} year={vehicleForEdit[3]} color={vehicleForEdit[4]} regMarks={vehicleForEdit[5]} />
        })()
      }
      {
        (() => {
          if (travelOrderModal) return <TravelOrder handler = {travelOrderModalHandler} />
        })()
      }
      <Spinner style={{ display: serverError === false ? 'none' : 'block', width: '64px', height: '64px' }} align='center' color="rgb(157, 195, 241)" />
      <br></br>
      <p style={{ display: serverError === false && !noData ? 'none' : 'block', fontFamily: 'Roboto', textAlign: 'center', color: 'red' }}>{serverError != false ? serverError.data : 'No data to display'}</p>
      <div style={{ display: serverError === false ? 'block' : 'none' }}>
        <FilteredCustomTable
          id='table'
          accent='rgb(157 195 241)'
          theme='light'
          headers={['ID', 'Vehicle', 'Year', 'Model', 'Reg. marks', 'Color']}
          filters={[
            { name: "Manufacturer", friendlyName: "Manufacturer", type: "string" }
          ]}
          filterCB={f => setFilters(f)}

          data={!noData ? addVehiclesToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
        />
      </div>
    </div>
  )
}
