import React from 'react';
import "./index.scss";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import Button from '../customComponents/Button';
import moment from 'moment';

export default function ConfirmationTravel(props) {

    const [noData, setNoData] = React.useState(false);
    const [serverError, setServerError] = React.useState(false);
    const [filters, setFilters] = React.useState([]);

    const { id } = useParams();
    const [businesses, setBusinesses] = React.useState([]);
    const getAllBusinesses = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/business/getAllBusinesses`,
            data: {
                filters: [
                    ...filters,
                    { name: 'ID', op: 'eq', value: id }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return setBusinesses(res.data.data);
            }
        }).catch(() => {
            setBusinesses({ status: 'error', data: 'Data fetch failed' });
            setServerError(prevError => {
                return prevError + '_ALL_INVOICES_ERROR_';
            });
        });
    }

    React.useEffect(() => {
        getAllBusinesses(id);
    }, []);

    const msgRef = React.useRef(null);
    const workersRefOne = React.useRef(null);
    const dateStartRefOne = React.useRef(null);
    const dateEndRefOne = React.useRef(null);
    const workersRefTwo = React.useRef(null);
    const dateStartRefTwo = React.useRef(null);
    const dateEndRefTwo = React.useRef(null);
    const workersRefThree = React.useRef(null);
    const dateStartRefThree = React.useRef(null);
    const dateEndRefThree = React.useRef(null);
    const workersRefFour = React.useRef(null);
    const dateStartRefFour = React.useRef(null);
    const dateEndRefFour = React.useRef(null);
    const workersRefFive = React.useRef(null);
    const dateStartRefFive = React.useRef(null);
    const dateEndRefFive = React.useRef(null);

    const dateStartRef = React.useRef(null);
    const dateEndRef = React.useRef(null);
    const timeStartRef = React.useRef(null);
    const timeEndRef = React.useRef(null);
    const daysRef = React.useRef(null);
    const hoursRef = React.useRef(null);

    const diariesTotalRef = React.useRef(null);
    const transportTotalRef = React.useRef(null);
    const overnightTotalRef = React.useRef(null);
    const otherTotalRef = React.useRef(null);
    const sumRef = React.useRef(null);

    const recievedRef = React.useRef(null);
    const remainsRef = React.useRef(null);

    const calculate = () => {
        sumRef.current.value = (Number(String(diariesTotalRef.current.value).replace(',', '.')) + Number(String(transportTotalRef.current.value).replace(',', '.')) + Number(String(overnightTotalRef.current.value).replace(',', '.')) + Number(String(otherTotalRef.current.value).replace(',', '.'))).toFixed(2);
    }

    const addConfirmation = (orderID, id, currency, travels) => {

        let fields = [
            {
                who: workersRefOne.current.value,
                from: dateStartRefOne.current.value,
                to: dateEndRefOne.current.value
            },
            {
                who: workersRefTwo.current.value,
                from: dateStartRefTwo.current.value,
                to: dateEndRefTwo.current.value
            },
            {
                who: workersRefThree.current.value,
                from: dateStartRefThree.current.value,
                to: dateEndRefThree.current.value
            },
            {
                who: workersRefFour.current.value,
                from: dateStartRefFour.current.value,
                to: dateEndRefFour.current.value
            },
            {
                who: workersRefFive.current.value,
                from: dateStartRefFive.current.value,
                to: dateEndRefFive.current.value
            },
        ];

        let diaries = document.querySelectorAll('#diaries');
        let diariesValues = [];
        diaries.forEach((item) => {
            diariesValues.push(item.value);
        });

        let transport = document.querySelectorAll('#transport');
        let transportValues = [];
        transport.forEach((item) => {
            transportValues.push(item.value);
        });

        let overnight = document.querySelectorAll('#overnight');
        let overnightValues = [];
        overnight.forEach((item) => {
            overnightValues.push(item.value);
        });

        let other = document.querySelectorAll('#other');
        let otherValues = [];
        other.forEach((item) => {
            otherValues.push(item.value);
        });

        let expenses = [
            {
                Diaries: diariesValues,
                DiariesTotal: diariesTotalRef.current.value
            },
            {
                Transport: transportValues,
                TransportTotal: transportTotalRef.current.value
            },
            {
                Overnight: overnightValues,
                OvernightTotal: overnightTotalRef.current.value
            },
            {
                Other: otherValues,
                OtherTotal: otherTotalRef.current.value
            }
        ];

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/travelconfirmations/addConfirmation`,
            data: {
                OrderID: orderID,
                BusinessID: id,
                Currency: currency,
                Fields: fields,
                DateStart: dateStartRef.current.value,
                DateEnd: dateEndRef.current.value,
                Year: moment().year(),
                Date: moment(),
                TimeStart: timeStartRef.current.value,
                TimeEnd: timeEndRef.current.value,
                Days: daysRef.current.value,
                Hours: hoursRef.current.value,
                Expenses: expenses,
                Total: sumRef.current.value,
                Recieved: recievedRef.current.value,
                Remains: remainsRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                msgRef.current.style.display = 'flex';
                msgRef.current.style.background = 'rgb(103, 229, 100)';
                msgRef.current.innerText = 'You have successfully made a confirmation!';
                setTimeout(() => {
                    msgRef.current.style.display = 'none';
                    props.handler();
                    props.handlerSecond();
                }, 1500);
            }
            else {
                msgRef.current.style.display = 'flex';
                msgRef.current.innerText = 'Info missing!';
                setTimeout(() => {
                    msgRef.current.style.display = 'none';
                }, 1500);
            }
        }).catch(() => {
            msgRef.current.style.display = 'flex';
            msgRef.current.innerText = 'Server error!';
            setTimeout(() => {
                msgRef.current.style.display = 'none';
            }, 1500);
        });
    }

    return (
        <div className='component__confirmationTravel'>
            <Button className='saveConBtn' value='Save' onClick={() => addConfirmation(props.orderID, id, props.currency)} />
            <p id='msg' ref={msgRef}></p>
            <div className='component__confirmationTravel__left'>
                <div className='component__confirmationTravel__left__item'>
                    <span><p>1. Potvrđuje se da je </p> <input defaultValue={props.travels} ref={workersRefOne} id='long' type='' /></span>
                    <p>boravio-la u vezi sa prednjim putnim nalogom o ovoj radnoj organizaciji - organu</p>
                    <span><p>od</p><input defaultValue={props.dateStart} ref={dateStartRefOne} type='date' /> do <input defaultValue={props.dateEnd} ref={dateEndRefOne} type='date' /><p>{moment().year()} godine</p></span>
                    <span id='item-foo'><p>M.P.</p><div><p>- - - - - - - - - - - - - - -</p><small>(potpis ovlaštenog lica)</small></div></span>
                </div>
                <div className='component__confirmationTravel__left__item'>
                    <span><p>2. Potvrđuje se da je </p> <input ref={workersRefTwo} id='long' type='' /></span>
                    <p>boravio-la u vezi sa prednjim putnim nalogom o ovoj radnoj organizaciji - organu</p>
                    <span><p>od</p><input ref={dateStartRefTwo} type='date' /> do <input ref={dateEndRefTwo} type='date' /><p>2022 godine</p></span>
                    <span id='item-foo'><p>M.P.</p><div><p>- - - - - - - - - - - - - - -</p><small>(potpis ovlaštenog lica)</small></div></span>
                </div>
                <div className='component__confirmationTravel__left__item'>
                    <span><p>3. Potvrđuje se da je </p> <input ref={workersRefThree} id='long' type='' /></span>
                    <p>boravio-la u vezi sa prednjim putnim nalogom o ovoj radnoj organizaciji - organu</p>
                    <span><p>od</p><input ref={dateStartRefThree} type='date' /> do <input ref={dateEndRefThree} type='date' /><p>2022 godine</p></span>
                    <span id='item-foo'><p>M.P.</p><div><p>- - - - - - - - - - - - - - -</p><small>(potpis ovlaštenog lica)</small></div></span>
                </div>
                <div className='component__confirmationTravel__left__item'>
                    <span><p>4. Potvrđuje se da je </p> <input ref={workersRefFour} id='long' type='' /></span>
                    <p>boravio-la u vezi sa prednjim putnim nalogom o ovoj radnoj organizaciji - organu</p>
                    <span><p>od</p><input ref={dateStartRefFour} type='date' /> do <input ref={dateEndRefFour} type='date' /><p>2022 godine</p></span>
                    <span id='item-foo'><p>M.P.</p><div><p>- - - - - - - - - - - - - - -</p><small>(potpis ovlaštenog lica)</small></div></span>
                </div>
                <div className='component__confirmationTravel__left__item'>
                    <span><p>5. Potvrđuje se da je </p> <input ref={workersRefFive} id='long' type='' /></span>
                    <p>boravio-la u vezi sa prednjim putnim nalogom o ovoj radnoj organizaciji - organu</p>
                    <span><p>od</p><input ref={dateStartRefFive} type='date' /> do <input ref={dateEndRefFive} type='date' /><p>2022 godine</p></span>
                    <span id='item-foo'><p>M.P.</p><div><p>- - - - - - - - - - - - - - -</p><small>(potpis ovlaštenog lica)</small></div></span>
                </div>
            </div>
            <div className='component__confirmationTravel__right'>
                <p>U {businesses[0]?.City}, dana <span id='date'>{moment().format('DD.MM.YYYY.')}</span></p>
                <br></br>
                <h1>OBRAČUN TROŠKOVA PUTOVANJA</h1>
                <br></br>
                <p>U vezi sa prednjim putnim nalogom podnosim sljedeći obračun:</p>
                <span><p>Na put sam krenuo -la dana</p><input defaultValue={props.dateStart} ref={dateStartRef} type='date' /> u <input ref={timeStartRef} type='text' /></span>
                <span><p>Vratio -la sam se dana</p><input defaultValue={props.dateEnd} ref={dateEndRef} type='date' /> u <input ref={timeEndRef} type='text' /></span>
                <span><p>Na putu sam proveo-la dana</p><input id='medium' ref={daysRef} type='text' /> sati <input id='medium' ref={hoursRef} type='text' /></span>
                <br></br>
                <div className='component__confirmationTravel__right__table'>
                    <table>
                        <span id='tspan'>1) Dnevnice</span>
                        <div><input id='diaries' type='text' /><input id='diaries' type='text' /></div>
                        <div><input id='diaries' type='text' /><input id='diaries' type='text' /></div>
                        <div><input id='diaries' type='text' /><input id='diaries' type='text' /></div>
                    </table>
                    <div id='right'>
                        <p>1. UKUPNO dnevnice</p>
                        <textarea onChange={() => calculate()} ref={diariesTotalRef} />
                    </div>
                </div>
                <div className='component__confirmationTravel__right__table'>
                    <table>
                        <span id='tspan'>2) Troškovi prijevoza</span>
                        <div><input id='transport' type='text' /><input id='transport' type='text' /></div>
                        <div><input id='transport' type='text' /><input id='transport' type='text' /></div>
                        <div><input id='transport' type='text' /><input id='transport' type='text' /></div>
                    </table>
                    <div id='right'>
                        <p>2. UKUPNO prijevoz</p>
                        <textarea onChange={() => calculate()} ref={transportTotalRef} />
                    </div>
                </div>
                <div className='component__confirmationTravel__right__table'>
                    <table>
                        <span id='tspan'>3) Izdaci za noćenje</span>
                        <div><input id='overnight' type='text' /><input id='overnight' type='text' /></div>
                        <div><input id='overnight' type='text' /><input id='overnight' type='text' /></div>
                        <div><input id='overnight' type='text' /><input id='overnight' type='text' /></div>
                    </table>
                    <div id='right'>
                        <p>3. UKUPNO noćenje</p>
                        <textarea onChange={() => calculate()} ref={overnightTotalRef} />
                    </div>
                </div>
                <div className='component__confirmationTravel__right__table'>
                    <table>
                        <span id='tspan'>4) Ostali troškovi</span>
                        <div><input id='other' type='text' /><input id='other' type='text' /></div>
                        <div><input id='other' type='text' /><input id='other' type='text' /></div>
                        <div><input id='other' type='text' /><input id='other' type='text' /></div>
                    </table>
                    <div id='right'>
                        <p>4. UKUPNO troškovi</p>
                        <textarea onChange={() => calculate()} ref={otherTotalRef} />
                    </div>
                </div>
                <span id='sum'><strong>UKUPNI TROŠKOVI 1+2+3+4</strong><input ref={sumRef} type='text' /></span>
                <span id='sum'><strong>Primljena akontacija:</strong><input ref={recievedRef} type='text' /></span>
                <span id='sum'><p>Ostaje za isplatu - Povrat:</p><input ref={remainsRef} type='text' /></span>
            </div>
        </div>
    )
}
