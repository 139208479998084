import React from 'react';
import "./index.scss";
import Button from '../customComponents/Button';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";

export default function EditCurrency(props) {

    const editCurrencyRef = React.useRef(null);
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }

    React.useEffect(()=>{
        scrollToRef(editCurrencyRef);
    }, []);

    const codeRef = React.useRef(null);
    const conversionRef = React.useRef(null);
    const conversionEURref = React.useRef(null);

    const [editInfo, setEditInfo] = React.useState(null);
    const editHandler = (item) => {
        setEditInfo(item);
    }

    const codeFieldRef = React.useRef(null);
    const conversionFieldRef = React.useRef(null);
    const conversionEURfieldRef = React.useRef(null);
  
    const changeInformations = (fieldRef, inputRef) => {
      inputRef.current.addEventListener('change', () => {
        fieldRef.current.innerText = inputRef.current.value;
      });
  
      inputRef.current.addEventListener('keypress', (e) => {
        if (e.key === 'Enter') {
          if (inputRef.current.value === '') {
            setEditInfo(null);
          }
          else {
            fieldRef.current.innerText = inputRef.current.value;
            setEditInfo(null);
          }
        }
        else {
          fieldRef.current.innerText = inputRef.current.value;
        }
      });
    }

    const [saveMsg, setSaveMsg] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(false);

    const saveEditedCurrency = (id, handler) => {
        if (isNaN(conversionRef.current.value)) {
            return setErrorMsg(true);
        }

        const body = {
            ID: id,
            Code: codeRef.current.value,
            Conversion: conversionRef.current.value,
            ConversionToEUR: conversionEURref.current.value
        };
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/currencies/editCurrency`,
            data: body,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setSaveMsg(true);
                props.data[0]?.data.map((item, index) => {
                    if (item.ID === id) {
                        item.Code = codeRef.current.value;
                        item.Conversion = conversionRef.current.value;
                        item.ConversionToEUR = conversionEURref.current.value;
                    }
                });
            }
        }).catch(() => {

        });

        setTimeout(() => {
            handler();
        }, 1500);
    }

    return (
        <div ref={editCurrencyRef}>
            <p id='edit-currency-msg' style={{ display: saveMsg ? 'block' : 'none' }}>Currency saved successfully</p>
            <p id='error-currency-msg' style={{ display: errorMsg ? 'block' : 'none' }}>Conversion must be a number</p>
            <div className='component__editCurrency'>
                <h1>Edit currency</h1>
                <br></br>
                <hr></hr>
                <br></br>
                <p>Code</p>
                <span><strong style={{ display: editInfo === 'code' ? 'none' : 'flex' }} id='currency-code' ref={codeFieldRef}>{props.Code}</strong><input ref={codeRef} defaultValue={props.Code} id='code-input' onChange={() => changeInformations(codeFieldRef, codeRef)} style={{ display: editInfo === 'code' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('code')} src='images/editPen.png' alt='' /></span>
                <p>Conversion</p>
                <span><strong style={{ display: editInfo === 'conversion' ? 'none' : 'flex' }} id='currency-conversion' ref={conversionFieldRef}>{props.Conversion}</strong><input ref={conversionRef} defaultValue={props.Conversion} id='conversion-input' onChange={() => changeInformations(conversionFieldRef, conversionRef)} style={{ display: editInfo === 'conversion' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('conversion')} src='images/editPen.png' alt='' /></span>
                <p>Conversion (to EUR)</p>
                <span><strong style={{ display: editInfo === 'conversionEUR' ? 'none' : 'flex' }} id='currency-conversionEUR' ref={conversionEURfieldRef}>{props.ConversionEUR}</strong><input ref={conversionEURref}  defaultValue={props.ConversionEUR} id='conversionEUR-input' onChange={() => changeInformations(conversionEURfieldRef, conversionEURref)} style={{ display: editInfo === 'conversionEUR' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('conversionEUR')} src='images/editPen.png' alt='' /></span>
                <br></br>
                <div className='component__editCurrency__controles'>
                    <Button onClick={() => saveEditedCurrency(props.id, props.handler)} accent='rgb(103, 229, 100)' value='Save' />
                    <Button onClick={props.handler} accent='rgb(103, 229, 100)' value='Cancel' />
                </div>
            </div>
        </div>
    )
}
