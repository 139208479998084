const bosnian = {
    IDbroj: 'ID broj:',
    PDVbroj: 'PDV broj:',
    TranRacun: 'Tran. račun:',
    Kupac: 'Kupac:',
    BrojFakture: 'fakture:',
    BrojPredracuna: 'predračuna:',
    BrojOtpremnice: 'otpremnice:',
    Broj: 'Broj',
    DatumIzdavanja: 'Datum izdavanja:',
    ZadnjiRokUplate: 'Zadnji rok uplate:',
    Dug: 'Dug',
    RacunIzdao: 'Račun izdao:',
    Opis: 'Opis',
    MPC: 'JM',
    Cijena: 'Cijena',
    Kolicina: 'Kolicina',
    Ukupno: 'Ukupno',
    PDV: 'PDV',
    UkupnoBezPDV: 'Ukupno bez PDV-a',
    UkupnaCijenaFakture: 'Ukupna cijena fakture',
    Rabat: 'Rabat',
    UkupnoSaRabatom: 'Ukupno sa rabatom:',
    UkupnoSaPDV: 'Ukupno sa PDV-om:',
    Avans: 'Avans:',
    Ukupno: 'Ukupno:',
    Plaćeno: 'Plaćeno:',
    NaseAdrese: 'Naše adrese:',
    PravniPodaci: 'Pravni podaci:',
    Kontakti: 'Kontakti:',
    IBAN: 'IBAN:',
    SWIFT: 'SWIFT:',
    NakonBrojaDana: 'Nakon broja dana',
    NaDatumIzdavanja: 'Na datum izdavanja',
    NaOdredeniDatum: 'Na odredeni datum',
    DanaDoRoka: 'Dana do roka',
    UnesiteKolicinu: 'Unesite količinu',
    DodajAvans: 'Dodaj avans',
    UnesiteKolikoJePlaćeno: 'Unesite koliko je plaćeno',
    UnesiteRabat: 'Unesite rabat',
    Odaberite: 'Odaberite:',
    UkupnaCijenaFakture: 'Ukupna cijena fakture:',
    UkupnoSaRabatom: 'Ukupno sa rabatom:',
    Placeno: 'Plaćeno:',
    PozivNaBroj: 'Poziv na broj:',
    Potpis: 'Potpis',
    UnesitePDV: 'Unesite PDV',
    Stornirano: 'FAKTURA JE STORNIRANA',
};

export { bosnian };