import React from 'react';
import Dashboard from "../../components/Dashboard";
import Team from "../../components/Team";
import Clients from "../../components/Clients";
import "./index.scss";
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import Spinner from '../../components/customComponents/Spinner';
import Articles from "../../components/Articles";
import Currencies from "../../components/Currencies";
import Memorandum from "../../components/Memorandum";
import { useParams } from 'react-router-dom';
import Workers from "../../components/Workers";
import Vehicles from "../../components/Vehicles";
import APItokens from "../../components/APItokens";
import Postbacks from "../../components/Postbacks";
import TravelOrders from '../../components/TravelOrders';
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

export default function LandingPage(props) {

    const { id } = useParams();
    const [activePage, setActivePage] = React.useState('dashboard');

    const pageHandler = (page) => {
        setActivePage(page);
    }

    const [settingsModal, setSettingsModal] = React.useState(false);
    const settingsModalHandler = () => {
        setSettingsModal(s => !s);
    }

    const menuRef = React.useRef(null);
    const [mobileMenu, setMobileMenu] = React.useState(false);
    const mobileMenuHandler = () => {
        setMobileMenu(m => !m);
        if (mobileMenu === false) {
            menuRef.current.style.display = 'block';
        }
        else {
            menuRef.current.style.display = 'none';
        }
    }

    const [mobileSettings, setMobileSettings] = React.useState(false);
    const mobileSettingsHandler = () => {
        setMobileSettings(s => !s);
    }

    const [businesses, setBusinesses] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const [noData, setNoData] = React.useState(false);

    const getAllBusinesses = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/business/getAllBusinesses`,
            data: {
                filters: [
                    ...filters,
                    { name: 'ID', op: 'eq', value: id },
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                res.data.data.length == 0 ? setNoData(true) : setNoData(false);
                setBusinesses(res.data);
            }
            else {
                setAccessDenied(true);
                animateNavigate(`/`);
            }
        }).catch(() => {
            setBusinesses({ status: 'error', data: 'SERVER_ERROR' });
        });
    }

    React.useEffect(() => {
        getAllBusinesses();
    }, [filters]);

    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const [accessDenied, setAccessDenied] = React.useState(false);
    React.useEffect(() => {
        if (businesses.data) {
            businesses.data.map((item, index) => {
                if (!JSON.parse(item.AllowedUsers).includes(props.loggedUserID)) {
                    setAccessDenied(true);
                    animateNavigate(`/`);
                }
            });
            if (businesses.data.length === 0) {
                setAccessDenied(true);
                animateNavigate(`/`);
            }
        }
    }, [businesses]);

    const [changePassword, setChangePassword] = React.useState(false);
    const changePasswordHandler = () => {
        setChangePassword(p => !p);
    }

    const curPasswordRef = React.useRef(null);
    const newPasswordRef = React.useRef(null);
    const msgRef = React.useRef(null);

    const saveNewPassword = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changePassword`,
            data: {
                oldPassword: curPasswordRef.current.value,
                newPassword: newPasswordRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                msgRef.current.innerText = 'Success!';
                msgRef.current.style.color = 'rgb(103, 229, 100)';
                msgRef.current.style.fontFamily = 'Roboto';
                setTimeout(() => {
                    changePasswordHandler();
                }, 1500);
            }
            else {
                msgRef.current.innerText = res.data.data;
                msgRef.current.style.color = 'red';
                msgRef.current.style.fontFamily = 'Roboto';
                setTimeout(() => {
                    msgRef.current.innerText = '';
                }, 3500);
            }
        }).catch(() => {

        });
    }

    const [pdfSpinner, setPDFspinner] = React.useState(false);

    const pdfDownloadSpinner = () => {
        setPDFspinner(s => !s);
    }

    return (
        <div style={{ display: accessDenied ? 'none' : 'grid' }} className='route__landingPage'>
            <div style={{ display: pdfSpinner ? 'block' : 'none' }} className='route__landingPage__pdfDownloadStarted'>
                <Spinner style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '25%', width: '90px', height: '90px'}} />
            </div>
            <div className='route__landingPage__menu'>
                <div className="route__landingPage__menu__head">
                    <img id="scale-logo" src="images/logo.png" alt="" />
                    <img onClick={() => settingsModalHandler()} id="settings-icon" src="images/settings.png" alt="" />
                    <img onClick={() => mobileMenuHandler()} id='hamburger' src='images/hamburger.png' alt='' />
                    <div style={{ display: settingsModal ? 'block' : 'none' }} className='route__landingPage__menu__head__settingsModal'>
                        {
                            businesses.data != 'SERVER_ERROR' ?
                                !noData ?
                                    businesses.data?.map((item) => {
                                        if (id == item.ID) {
                                            return <h2>{item.BusinessName}</h2>
                                        }
                                    })
                                    : <h2><Spinner style={{ width: "20px", height: "20px" }} color="#3F73FF" align="center" /></h2>
                                : <h2><Spinner style={{ width: "20px", height: "20px" }} color="#3F73FF" align="center" /></h2>
                        }
                        <button onClick={() => { animateNavigate(`/`); }}>Add or switch business</button>
                        <br></br>
                        <br></br>
                        <hr></hr>
                        <span onClick={() => changePasswordHandler()}><img src='images/settings 1.png' alt='' /><p>Change password</p></span>
                        <div style={{ display: changePassword ? 'flex' : 'none' }} className='route__landingPage__menu__head__settingsModal__changePassword'>
                            <input ref={curPasswordRef} type='password' placeholder='Current password' />
                            <input ref={newPasswordRef} type='password' placeholder='New password' />
                            <button id='changePassBtn' onClick={() => saveNewPassword()}>Save</button>
                            <br></br>
                            <p ref={msgRef}></p>
                        </div>
                    </div>
                </div>
                <div ref={menuRef} id='menu-wrapper'>
                    <p id="logged-user">{props.loggedUser}</p>
                    <br></br>
                    <hr></hr>
                    <div onClick={() => mobileSettingsHandler()} id='settings-icon2' className='route__landingPage__menu__navItem'>
                        <img src='images/settings2.png' alt='' />
                        <p>Settings</p>
                    </div>
                    <div style={{ display: mobileSettings ? 'block' : 'none' }} className='route__landingPage__menu__mobileSettings'>
                        {
                            businesses.data != 'SERVER_ERROR' ?
                                !noData ?
                                    businesses.data?.map((item, index) => {
                                        if (id == index + 1) {
                                            return <h2>{item.BusinessName}</h2>
                                        }
                                    })
                                    : <h2><Spinner style={{ width: "20px", height: "20px" }} color="#3F73FF" align="center" /></h2>
                                : <h2><Spinner style={{ width: "20px", height: "20px" }} color="#3F73FF" align="center" /></h2>
                        }
                        <button onClick={() => { window.location.href = '/' }}>Add or switch business</button>
                        <br></br>
                        <br></br>
                        <hr></hr>
                        <span onClick={() => changePasswordHandler()}><img src='images/settings2.png' alt='' /><p>Change password</p></span>
                        <div style={{ display: changePassword ? 'flex' : 'none' }} className='route__landingPage__menu__head__settingsModal__changePassword'>
                            <input ref={curPasswordRef} type='password' placeholder='Current password' />
                            <input ref={newPasswordRef} type='password' placeholder='New password' />
                            <button id='changePassBtn' onClick={() => saveNewPassword()}>Save</button>
                            <br></br>
                            <p ref={msgRef}></p>
                        </div>
                    </div>
                    <div onClick={() => pageHandler('dashboard')} id='nav-item1' className='route__landingPage__menu__navItem'>
                        <img src='images/dashboardIcon.png' alt='' />
                        <p style={{ color: activePage === 'dashboard' ? '#00A3FF' : 'white' }}>Dashboard</p>
                    </div>
                    <div onClick={() => pageHandler('team')} className='route__landingPage__menu__navItem'>
                        <img src='images/teamIcon.png' alt='' />
                        <p style={{ color: activePage === 'team' ? '#00A3FF' : 'white' }}>Team</p>
                    </div>
                    <div onClick={() => pageHandler('clients')} className='route__landingPage__menu__navItem'>
                        <img src='images/clientIcon.png' alt='' />
                        <p style={{ color: activePage === 'clients' ? '#00A3FF' : 'white' }}>Clients</p>
                    </div>
                    <div onClick={() => pageHandler('articles')} className='route__landingPage__menu__navItem'>
                        <img src='images/articlesIcon.png' alt='' />
                        <p style={{ color: activePage === 'articles' ? '#00A3FF' : 'white' }}>Articles</p>
                    </div>
                    <div onClick={() => pageHandler('currencies')} className='route__landingPage__menu__navItem'>
                        <img src='images/dollarWhite.png' alt='' />
                        <p style={{ color: activePage === 'currencies' ? '#00A3FF' : 'white' }}>Currencies</p>
                    </div>
                    <div onClick={() => pageHandler('memorandum')} className='route__landingPage__menu__navItem'>
                        <img src='images/memorandum.png' alt='' />
                        <p style={{ color: activePage === 'memorandum' ? '#00A3FF' : 'white' }}>Memorandums</p>
                    </div>
                    <div onClick={() => pageHandler('workers')} className='route__landingPage__menu__navItem'>
                        <img src='images/workers.svg' alt='' />
                        <p style={{ color: activePage === 'workers' ? '#00A3FF' : 'white' }}>Workers</p>
                    </div>
                    <div onClick={() => pageHandler('vehicles')} className='route__landingPage__menu__navItem'>
                        <img src='images/vehicles.svg' alt='' />
                        <p style={{ color: activePage === 'vehicles' ? '#00A3FF' : 'white' }}>Vehicles</p>
                    </div>
                    <div onClick={() => pageHandler('travelorders')} className='route__landingPage__menu__navItem'>
                        <img src='images/travelorders.svg' alt='' />
                        <p style={{ color: activePage === 'travelorders' ? '#00A3FF' : 'white' }}>Travel orders</p>
                    </div>
                    <div onClick={() => pageHandler('tokens')} className='route__landingPage__menu__navItem'>
                        <img src='images/tokens.svg' alt='' />
                        <p style={{ color: activePage === 'tokens' ? '#00A3FF' : 'white' }}>API tokens</p>
                    </div>
                    <div onClick={() => pageHandler('postbacks')} className='route__landingPage__menu__navItem'>
                        <img src='images/postbacks.svg' alt='' />
                        <p style={{ color: activePage === 'postbacks' ? '#00A3FF' : 'white' }}>Postbacks</p>
                    </div>
                </div>
            </div>
            <div className='route__landingPage__body'>
                {(() => {
                    if (activePage === 'dashboard') {
                        return <Dashboard pdfDownloadSpinner={pdfDownloadSpinner} loggedUser={props.loggedUser} />
                    }
                    if (activePage === 'team') {
                        return <Team loggedUserID={props.loggedUserID} />
                    }
                    if (activePage === 'clients') {
                        return <Clients />
                    }
                    if (activePage === 'articles') {
                        return <Articles />
                    }
                    if (activePage === 'currencies') {
                        return <Currencies />
                    }
                    if (activePage === 'memorandum') {
                        return <Memorandum pdfDownloadSpinner={pdfDownloadSpinner} />
                    }
                    if (activePage === 'workers') {
                        return <Workers />
                    }
                    if (activePage === 'vehicles') {
                        return <Vehicles />
                    }
                    if (activePage === 'travelorders') {
                        return <TravelOrders pdfDownloadSpinner={pdfDownloadSpinner} />
                    }
                    if (activePage === 'tokens') {
                        return <APItokens loggedUserID={props.loggedUserID} />
                    }
                    if (activePage === 'postbacks') {
                        return <Postbacks loggedUserID={props.loggedUserID} />
                    }
                })()}
            </div>
        </div>
    )
}
