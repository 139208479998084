import React from 'react';
import "./index.scss";
import Button from "../customComponents/Button";
import { FilteredCustomTable } from "../customComponents/Table";
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import Spinner from '../customComponents/Spinner';
import EditCurrency from '../EditCurrency';

export default function Currencies() {

    const newCurrencyRef = React.useRef(null);
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }

    const [currencyData, setCurrencyData] = React.useState();

    const [msg, setMsg] = React.useState(false);
    const [infoError, setInfoError] = React.useState(false);
    const [noData, setNoData] = React.useState(false);
    const [serverError, setServerError] = React.useState(false);
    const [filters, setFilters] = React.useState([]);

    const [addCurrencyModal, setAddCurrencyModal] = React.useState(false);
    const addCurrencyHandler = () => {
        setAddCurrencyModal(c => !c);
        scrollToRef(newCurrencyRef);
    }


    const codeRef = React.useRef(null);
    const conversionRef = React.useRef(null);
    const conversionEURref = React.useRef(null);
    const codeFieldRef = React.useRef(null);
    const conversionFieldRef = React.useRef(null);
    const conversionEURfieldRef = React.useRef(null);
  
    const [editInfo, setEditInfo] = React.useState(null);
    const editHandler = (item) => {
      setEditInfo(item);
    }
  
    const changeInformations = (fieldRef, inputRef) => {
      inputRef.current.addEventListener('change', () => {
        fieldRef.current.innerText = inputRef.current.value;
      });
  
      inputRef.current.addEventListener('keypress', (e) => {
        if (e.key === 'Enter') {
          if (inputRef.current.value === '') {
            setEditInfo(null);
          }
          else {
            fieldRef.current.innerText = inputRef.current.value;
            setEditInfo(null);
          }
        }
        else {
          fieldRef.current.innerText = inputRef.current.value;
        }
      });
    }

    const addCurrency = () => {
        if (isNaN(conversionRef.current.value)) {
            return setInfoError(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/currencies/addCurrency`,
            data: {
                Code: codeRef.current.value,
                Conversion: conversionRef.current.value,
                ConversionToEUR: conversionEURref.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getAllCurrencies();
                setMsg(true);
                setTimeout(() => {
                    addCurrencyHandler();
                    setMsg(false);
                    setInfoError(false);
                }, 1500);
            };
        }).catch(() => {

        });
    }

    const getAllCurrencies = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/currencies/getAllCurrencies`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
                setCurrencyData(res.data);
            };
        }).catch(() => {
            setCurrencyData({ status: 'error', data: 'Data fetch failed' });
            return setServerError(true);
        });
    }

    React.useEffect(() => {
        getAllCurrencies();
    }, [filters]);

    const [editCurrencyModal, setEditCurrencyModal] = React.useState(false);
    const [currencyDataForEdit, setCurrencyDataForEdit] = React.useState([]);
    const editCurrencyHandler = (id) => {
        setEditCurrencyModal(e => !e);
        currencyData?.data.map((item, index) => {
            if (item.ID === id) {
                setCurrencyDataForEdit([item.Code, item.Conversion, item.ID, item.ConversionToEUR]);
            }
        });
    }

    const deleteCurrency = (item_id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/currencies/removeCurrency`,
            data: {
                ID: item_id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getAllCurrencies();
            };
        }).catch(() => {

        });
    }

    const addCurrenciesToTable = () => {
        if (currencyData) {
            return currencyData?.data.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.Code}</h3></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.Conversion}</h3></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.ConversionToEUR}</h3></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            { keyID: String(item.ID), type: "button", text: "Edit", onClick: e => { editCurrencyHandler(item.ID) } },
                            {
                                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>Are you sure?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='rgb(157, 195, 241)' value='YES' onClick={() => deleteCurrency(item.ID)} />
                                            <Button accent='rgb(157, 195, 241)' value='NO' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    return (
        <div className='component__currencies'>
            <div className='component__currencies__head'>
                <h1>Currencies</h1>
                <Button onClick={() => addCurrencyHandler()} className='component__currencies__head__addCurrencyButton' accent='#67E564' value={addCurrencyModal ? 'Cancel' : 'Add currency'} />
            </div>
            <br></br>
            <hr></hr>
            <br></br>
            <br></br>
            <p id='add-currency-msg' style={{ display: msg ? 'block' : 'none' }}>New currency sucessfully added</p>
            <p id='error-currency-msg' style={{ display: infoError ? 'block' : 'none' }}>Conversion must be a number</p>
            {(() => {
                if (editCurrencyModal === true) {
                    return <EditCurrency data={[currencyData, setCurrencyData]} handler={editCurrencyHandler} Code={currencyDataForEdit[0]} Conversion={currencyDataForEdit[1]} id={currencyDataForEdit[2]} ConversionEUR={currencyDataForEdit[3]} />
                }
            })()}
            <div ref={newCurrencyRef} style={{ display: addCurrencyModal ? 'block' : 'none' }} className='component__currencies__addCurrencyModal'>
                <h1>Add new currency</h1>
                <br></br>
                <hr></hr>
                <br></br>
                <p>Currency code</p>
                <span><strong style={{ display: editInfo === 'code' ? 'none' : 'flex' }} id='currency-code' ref={codeFieldRef}>Currency code</strong><input ref={codeRef} id='code-input' onChange={() => changeInformations(codeFieldRef, codeRef)} style={{ display: editInfo === 'code' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('code')} src='images/editPen.png' alt='' /></span>
                <p>Conversion (from EUR)</p>
                <span><strong style={{ display: editInfo === 'conversion' ? 'none' : 'flex' }} id='currency-conversion' ref={conversionFieldRef}>Currency conversion</strong><input ref={conversionRef} id='conversion-input' onChange={() => changeInformations(conversionFieldRef, conversionRef)} style={{ display: editInfo === 'conversion' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('conversion')} src='images/editPen.png' alt='' /></span>
                <p>Conversion (to EUR)</p>
                <span><strong style={{ display: editInfo === 'conversionEUR' ? 'none' : 'flex' }} id='currency-conversionEUR' ref={conversionEURfieldRef}>Currency conversion to EUR</strong><input ref={conversionEURref} id='conversionEUR-input' onChange={() => changeInformations(conversionEURfieldRef, conversionEURref)} style={{ display: editInfo === 'conversionEUR' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('conversionEUR')} src='images/editPen.png' alt='' /></span>
                <br></br>
                <Button onClick={() => addCurrency()} accent='rgb(103, 229, 100)' value='Add' />
            </div>

            <Spinner style={{ display: serverError ? 'block' : 'none', width: "64px", height: "64px" }} color="rgb(157, 195, 241)" align="center" />
            <p style={{ display: noData || serverError ? 'block' : 'none', fontFamily: 'Roboto', fontSize: '18px', color: 'red' }}>{noData ? 'No data to display' : ''}{serverError ? currencyData.data : ''}</p>
            <FilteredCustomTable
                id='table'
                accent='rgb(157 195 241)'
                theme='light'
                headers={['Code', 'Conversion', 'Conversion to EUR']}
                filters={[
                    { name: "Code", friendlyName: "Code", type: "string" }
                ]}
                /*filterCB={(arg)=>{
                  customersData?.data.map((item)=>{
                    if(item.ID != arg[0].value){
                      
                    }
                  })
                }}*/
                filterCB={f => setFilters(f)}

                data={!serverError && !noData ? addCurrenciesToTable() : [[{keyID: 'noData', type:'custom', data: <p>No data to display</p>}]]}
            />
            <br></br>
        </div>
    )
}
