import React from 'react';
import Button from '../customComponents/Button';
import "./index.scss";
import { useParams } from 'react-router-dom';
import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import Spinner from '../customComponents/Spinner';

export default function RemarksModal(props) {

  const { id } = useParams();
  const remarkTextRef = React.useRef(null);

  const addRemark = (id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/remarks/addRemark`,
      data: {
        BusinessID: id,
        Text: remarkTextRef.current.value
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.handler();
      };
    }).catch(() => {

    });
  }

  return (
    <div className='component__remarksModal'>
            <Button className='cancelNewRemarkButton' accent='transparent' value='Cancel' onClick={props.handler} />
      <h1>Add new remark</h1>
      <textarea placeholder='Remark text' ref={remarkTextRef} />
      <Button className='addNewRemarkButton' accent='rgb(103, 229, 100)' value='Add' onClick={() => addRemark(id)} />
    </div>
  )
}
