import React from 'react';
import "./index.scss";
import Button from '../customComponents/Button';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import { FilteredCustomTable } from "../customComponents/Table";

export default function APItokens(props) {

  const msgRef = React.useRef(null);

  const [addNew, setAddNew] = React.useState(false);

  const addNewTokenHandler = () => {
    setAddNew(n => !n);
  }

  const [edit, setEdit] = React.useState(false);
  const [tokenForEdit, setTokenForEdit] = React.useState([]);

  const nameRef = React.useRef(null);
  const nameFieldRef = React.useRef(null);

  const [editInfo, setEditInfo] = React.useState(null);
  const editHandler = (item) => {
    setEditInfo(item);
  }

  const changeInformations = (fieldRef, inputRef) => {
    inputRef.current.addEventListener('change', () => {
      fieldRef.current.innerText = inputRef.current.value;
    });

    inputRef.current.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        if (inputRef.current.value === '') {
          setEditInfo(null);
        }
        else {
          fieldRef.current.innerText = inputRef.current.value;
          setEditInfo(null);
        }
      }
      else {
        fieldRef.current.innerText = inputRef.current.value;
      }
    });
  }

  const [noData, setNoData] = React.useState(false);
  const [flags, setFlags] = React.useState([]);
  const [serverError, setServerError] = React.useState();
  const getAllFlags = () => {
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/apiTokens/getAllAvailableFlags`,
      data: {
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        //res.data.data.length = 0;
        res.data.data.length > 0 ? setNoData(false) : setNoData(true);
        setFlags(res.data);
      };
    }).catch(() => {
      setServerError({ type: 'SERVER_ERROR', data: 'Failed to fetch data' });
    });
  }

  let flagsGroup = {};

  const addToken = () => {

    const flags_checkboxes = document.querySelectorAll('#checkbox');
    flags_checkboxes.forEach((c) => {
      if(c.checked){
        flagsGroup = {
          ...flagsGroup,
          [c.getAttribute('val')]: true
        }
      }
    });

    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/apiTokens/addApiToken`,
      data: {
        Name: nameFieldRef.current.textContent,
        Flags: flagsGroup,
        UserID: props.loggedUserID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        msgRef.current.style.display = 'block';
        msgRef.current.innerText = 'New API token successfully added';
        msgRef.current.style.background = 'rgb(103, 229, 100)';
        setTimeout(() => {
          getAllTokens();
          msgRef.current.style.display = 'none';
          addNewTokenHandler();
        }, 1500);
      };
    }).catch(() => {

    });
  }

  const [filters, setFilters] = React.useState([]);
  const [tokens, setTokens] = React.useState([]);
  const getAllTokens = () => {
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/apiTokens/getAllApiTokens`,
      data: {
        pagination: 0,
        filters: filters
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        res.data.data.length < 1 ? setNoData(true) : setNoData(false);
        setTokens(res.data);
      };
    }).catch(() => {

    });
  }

  React.useEffect(() => {
    getAllFlags();
  }, []);


  React.useEffect(() => {
    getAllTokens();
  }, [filters]);

  const GetToken = (props2) => {

    const [token, setToken] = React.useState('');

    const getCurToken = (id) => {
      axios({
        method: 'POST',
        url: `${backendModule.backendURL}/apiTokens/getToken`,
        data: {
          ID: id
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          setToken(res.data);
        };
      }).catch(() => {
  
      });
    }

    React.useEffect(() => {
      getCurToken(props2.id);
    }, []);

    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
      <br></br>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <p id='token'>{token?.data ?? ''}</p>
      </div>
    </div>);
  }

  const deleteToken = (id) => {
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/apiTokens/removeApiToken`,
      data: {
        ID: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllTokens();
      };
    }).catch(() => {

    });
  }

  const addTokensToTable = () => {
    if (tokens.data) {
      return tokens?.data.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div id='table-token-info' ><h3>{item.ID}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-token-info'><h3>{item.Name}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-token-info'><h3>{item.User}</h3></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "custom", data: <div id='table-token-fhead'><p>Flags: </p></div> },
              { keyID: String(item.ID), type: "custom", data: <div id='table-flags'>{
                (() => {
                  let activeFlags = [];
                  for(const flag in item.Flags){
                    if(item.Flags[flag] === true){
                      flags.data?.map((f) => {
                        if(f.name === flag){
                          activeFlags.push(f.friendlyName);
                        }
                      });
                    }
                  }
                  return <div id='table-flags-content'>{activeFlags?.map((f) => {return <p>{f} </p>})}</div>
                })()
              }</div> },
            ]
          },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              {
                keyID: String(item.ID), type: "button", text: "Show token", triggerDropdown: true, triggerData: e => <GetToken id = {item.ID} />
              },
              {
                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                  return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: 'black' }}>Are you sure?</p>
                    <br></br>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button style={{ marginRight: '10px' }} accent='rgb(157, 195, 241)' value='YES' onClick={() => deleteToken(item.ID)} />
                      <Button accent='rgb(157, 195, 241)' value='NO' onClick={c => { e() }} />
                    </div>
                  </div>);
                }
              },
            ]
          }
        ];
      });
    }
  }

  return (
    <div className='component__apiTokens'>
      <div className='component__apiTokens__head'>
        <h1>API tokens</h1>
        <Button className='addNewToken' accent='rgb(103, 229, 100)' value={addNew ? 'Cancel' : 'Add new token'} onClick={() => addNewTokenHandler()} />
      </div>
      <br></br>
      <hr></hr>
      <br></br>
      <div id='msg' ref={msgRef}></div>
      <div style={{ display: addNew ? 'block' : 'none' }} className='component__apiTokens__addNewTokenModal'>
        <p>Name</p>
        <span><strong style={{ display: editInfo === 'name' ? 'none' : 'flex' }} id='token-name' ref={nameFieldRef}>Token name</strong><input ref={nameRef} id='name-input' onChange={() => changeInformations(nameFieldRef, nameRef)} style={{ display: editInfo === 'name' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('name')} src='images/editPen.png' alt='' /></span>
        <p>Select flags</p>
        <div id='flags-grid'>
          {
            flags.data?.map((item, index) => {
              return <div><input type='checkbox' id='checkbox' val={item.name} /><p>{item.friendlyName}</p><p id='flag-desc'>{item.description}</p></div>
            })
          }
        </div>
        <Button onClick={() => addToken()} accent='rgb(103, 229, 100)' value='Add' />
      </div>
      <div>
        <FilteredCustomTable
          id='table'
          accent='rgb(157 195 241)'
          theme='light'
          headers={['ID', 'Name', 'User']}
          filters={[
            { name: "Name", friendlyName: "Name", type: "string" }
          ]}
          filterCB={f => setFilters(f)}

          data={!noData ? addTokensToTable() : [[{keyID: 'noData', type:'custom', data: <p>No data to display</p>}]]}
        />
      </div>
    </div>
  )
}
