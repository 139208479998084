import React from 'react';
import "./index.scss";
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import { useParams } from 'react-router-dom';
import moment from 'moment';

export default function DueListModal(props) {
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const { id } = useParams();
  const [invoicesData, setInvoicesData] = React.useState([]);
  const date = new Date();

  let curColor = -1;
  const colors = [
    '#272737',
    '#444450'
  ];

  const getOrderedInvoices = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/invoices/getAllInvoices`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id },
          { name: 'dateOfIssue', op: 'like', value: props.year }
        ],
        orders: [{ name: "DueDate", order: "asc" }]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      //res.data.data.length = 0; 
      if (res.data.status === 'ok') {
        setInvoicesData(res.data);
      }
      if (res.data.status === 'error') {

      }
    }).catch(() => {

    });
  }

  React.useEffect(() => {
    getOrderedInvoices();
  }, []);

  return (
    <div className='component__dueListModal'>
      <div className='component__dueListModal__container'>
        <div className='component__dueListModal__container__head'>
          <h1>Expires today</h1>
          <img src='images/closeIcon.png' alt='' onClick={() => props.handler()} />
        </div>
        <hr></hr>
        <div className='component__dueListModal__container__body'>
          {
            invoicesData.data?.map((item) => {
              if (moment(item.DueDate).format("D.MM.YYYY") == moment(date).format("D.MM.YYYY")) {
                curColor++;
                if(curColor > colors.length - 1){
                  curColor = 0;
                }
                return <div className='component__dueListModal__container__body__item' style={{ background: colors[curColor] }}>
                <div>
                  <p style={{ color: 'rgb(0, 163, 255)' }}>Company name:</p>
                  <p>{props.clients.data.map((client) => {
                    if (client.ID == item.ClientID) {
                      return client.Name
                    }
                  })}</p>
                </div>
                <div>
                  <p style={{ color: 'rgb(0, 163, 255)' }}>Due date:</p>
                  <p>{moment(item.DueDate).format("D.MM.YYYY")}</p>
                </div>
                <div>
                  <p style={{ color: 'rgb(0, 163, 255)' }}>Due amount:</p>
                  {JSON.parse(item.Calculation).map((d) => {
                    return <p>{d.Due}</p>
                  })}
                </div>
              </div>
              }
            })
          }
        </div>
      </div>
    </div>
  )
}
