import React from 'react';
import "./index.scss";
import Button from "../customComponents/Button";
import { element } from 'prop-types';
import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import { useParams } from 'react-router-dom';
import Spinner from '../customComponents/Spinner';
import moment, { lang } from 'moment';
import RemarksModal from '../RemarksModal';
import { bosnian } from '../../languages/bosnian';
import { english } from '../../languages/english';

export default function CreateNewInvoice(props) {

  const { id } = useParams();
  const [paid, setPaid] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);

  const newInvoiceRef = React.useRef(null);
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" })
  }
  React.useEffect(() => {
    scrollToRef(newInvoiceRef);
  }, []);

  const [mobileSettings, setMobileSettings] = React.useState(false);
  const mobileSettingsRef = React.useRef(null);
  const mobileSettingsHandler = () => {
    if (!mobileSettings) {
      mobileSettingsRef.current.style.display = 'block';
      setMobileSettings(s => !s);
    }
    else {
      mobileSettingsRef.current.style.display = 'none';
      setMobileSettings(s => !s);
    }
  }

  const [addClientModal, setAddClientModal] = React.useState(false);

  const addClientHandler = () => {
    setAddClientModal(c => !c);
  }

  const [addNewLineModal, setAddNewLineModal] = React.useState(false);

  const addNewLineHandler = () => {
    if (currency.value === '----') {
      messageRef.current.style.display = 'block';
      messageRef.current.style.background = 'red';
      messageRef.current.style.color = 'white';
      messageRef.current.textContent = 'Choose currency please';

      setTimeout(() => {
        messageRef.current.style.display = 'none';
      }, 2000);
      return;
    }

    setAddNewLineModal(l => !l);
  }

  const [customArticleModal, setCustomArticleModal] = React.useState(false);
  const addCustomArticle = () => {
    setCustomArticleModal(m => !m);
  }

  const [noData, setNoData] = React.useState(false);
  const [serverError, setServerError] = React.useState('');
  const getAllServices = (id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/articles/getAllArticles`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id }
        ],
        orders: orders,
        pagination: 0,

      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
        /*if(res.data.data.length === 20){
            setTimeout(()=>setCanPaginate(true), 1000);
        }
        return setArticlesData(res.data);*/
        return setServicesArray(res.data.data);
      };
    }).catch(() => {
      setServicesArray({ status: 'error', data: 'Data fetch failed' });
      return setServerError(prevError => {
        return prevError + 'ARTICLES_ERROR';
      });
    });
  }
  React.useEffect(() => {
    getAllServices(id);
  }, [filters]);

  const [servicesArray, setServicesArray] = React.useState([]);
  const serviceRef = React.useRef([]);
  const customServiceNameRef = React.useRef(null);
  const customServicePriceRef = React.useRef(null);
  const customServiceQuantityRef = React.useRef(null);
  const customServiceMPCref = React.useRef(null);
  const descRef = React.useRef(null);
  const serPriceRef = React.useRef([]);
  const priceFieldRef = React.useRef(null);
  const mpcRef = React.useRef([]);
  const mpcFieldRef = React.useRef(null);
  const quantityRef = React.useRef(null);
  const [quantityModal, setQuanityModal] = React.useState(false);

  const quantityModalHandler = () => {
    setQuanityModal(q => !q);
  }

  const quantityInputRef = React.useRef(null);
  const [quantityState, setQuantityState] = React.useState([]);
  const [warning, setWarning] = React.useState(false);
  const warningMsgRef = React.useRef(null);

  const addQuantity = (version) => {
    if (version === 'check') {
      servicesArray.map((ser) => {
        if (description[description.length - 1].name === ser.Name) {
          if (ser.Quantity != null) {
            if (ser.Quantity < quantityInputRef.current.value) {
              warningMsgRef.current.innerText = 'You do not have enough items in your balance, if you continue you will go into the red.';
              setWarning(true);
            }
            else {
              if (!isNaN(quantityInputRef.current.value)) {
                if (parseInt(quantityInputRef.current.value) > 0) {
                  //quantityState.push(quantityInputRef.current.value);
                  let tmp_desc = description;
                  tmp_desc[tmp_desc.length - 1] = {
                    ...tmp_desc[tmp_desc.length - 1],
                    quantity: quantityInputRef.current.value
                  };
                  setQuanityModal(false);
                }
              }
            }
          }
          else if (ser.Quantity == null) {
            if (!isNaN(quantityInputRef.current.value)) {
              if (parseInt(quantityInputRef.current.value) > 0) {
                //quantityState.push(quantityInputRef.current.value);
                let tmp_desc = description;
                tmp_desc[tmp_desc.length - 1] = {
                  ...tmp_desc[tmp_desc.length - 1],
                  quantity: quantityInputRef.current.value
                };
                setQuanityModal(false);
              }
            }
          }
        }
      })
    }
    else if (version === 'yes') {
      if (!isNaN(quantityInputRef.current.value)) {
        if (parseInt(quantityInputRef.current.value) > 0) {
          //quantityState.push(quantityInputRef.current.value);
          let tmp_desc = description;
          tmp_desc[tmp_desc.length - 1] = {
            ...tmp_desc[tmp_desc.length - 1],
            quantity: quantityInputRef.current.value
          };
          setQuanityModal(false);
          setWarning(false);
        }
      }
    }
    else if (version === 'no') {
      deleteService(quantityState.length);
      setQuanityModal(false);
      setWarning(false);
    }
  }

  const sumPrices = () => {
    const prices = document.querySelectorAll('#s-price');
    let sum = 0;
    prices.forEach(price => {
      sum = sum + parseFloat(price.textContent);
    });
    totalWithoutPDVref.current.textContent = parseFloat(sum).toFixed(2) + ' ' + currency.value;
  }

  const [description, setDescription] = React.useState([]);
  const curServiceIDref = React.useRef([]);
  const addDescription = (index) => {
    let tmp = description;
    tmp.push({
      name: serviceRef.current[index].textContent,
      price: serPriceRef.current[index].textContent,
      mpc: mpcRef.current[index].textContent,
      id: curServiceIDref.current[index].textContent,
    });
    setDescription(tmp);
    quantityModalHandler();
    addNewLineHandler();
  }

  const addCustomDescription = () => {
    let tmp = description;
    tmp.push({
      name: customServiceNameRef.current.value,
      price: customServicePriceRef.current.value,
      mpc: customServiceMPCref.current.value,
      quantity: customServiceQuantityRef.current.value,
      id: 0
    });
    setDescription(tmp);
    addNewLineHandler();
    addCustomArticle();
  }

  React.useEffect(() => {
    const prices = document.querySelectorAll('#q-price');
    const tot_price = document.querySelectorAll('#s-price');
    const quantities = document.querySelectorAll('#quantity');
    const totwopdv = document.querySelectorAll('#s-totwopdv');
    if (quantities && tot_price && prices && totwopdv) {
      for (let i = 0; i < prices.length; i++) {
        ///if (i === prices.length - 1) {
        tot_price[i].innerText = parseFloat(description[i].quantity * parseFloat(description[i].price)).toFixed(2) + ' ' + currency.value;
        totwopdv[i].innerText = parseFloat(description[i].quantity * parseFloat(description[i].price)).toFixed(2) + ' ' + currency.value;
        //}
      }
    }
    sumPrices();
  });

  const deleteService = (id) => {
    setDescription(d => d.filter((_, idx) => idx !== id));
  }

  const [clientInBox, setClientInBox] = React.useState([]);
  const addClientInBox = (index) => {

    const cid = clientRef.current[index].getAttribute('cid');
    customersData?.data.map((item) => {
      if (cid == item.ID) {
        clientInBox.length = 0;
        clientInBox.push({
          name: clientRef.current[index].textContent,
          address: item.Address,
          phone: item.PhoneNumber,
          city: item.City,
          country: item.Country,
          pdvNumber: item.PDVNumber,
          clientID: item.ID
        });
      }
    });
  }

  const clientRef = React.useRef([]);

  const addClientInNewInvoice = (index) => {
    addClientInBox(index);
    addClientHandler();
  }

  const searchTerm = (searchbar, values_list) => {
    const search = document.querySelector(searchbar);
    const values = document.querySelectorAll(values_list);
    values.forEach(value => {
      value.style.display = 'grid';
      if (!value.textContent.toLowerCase().includes(search.value.toLowerCase())) {
        value.style.display = 'none';
      }
    });
  }

  const [dueDateModal, setDueDateModal] = React.useState(false);
  const dueDateModalHandler = () => {
    setDueDateModal(d => !d);
  }
  const [showDaysInput, setShowDaysInput] = React.useState(false);
  const handleCheckbox = (e) => {
    if (e.target.checked) {
      setShowDaysInput(true);
      dateOfIssueRef.current.checked = false;
      specificDateRef.current.checked = false;
      setShowSpecificDateInput(false);
    }
    else {
      setShowDaysInput(false);
      setDueDate('');
    }
  }

  const [pdv, setPDV] = React.useState('0');

  const totalWithPDVref = React.useRef(null);
  const [PDVvalue, setPDVvalue] = React.useState(0);
  const getPDV = () => {
    if (pdv > 0) {
      const amount = parseFloat(totalWithRabatRef.current.textContent) * pdv / 100;
      setPDVvalue(amount);
      totalWithPDVref.current.textContent = parseFloat(totalWithRabatRef.current.textContent) + amount;
      totalWithPDVref.current.textContent = parseFloat(totalWithPDVref.current.textContent).toFixed(2) + ' ' + currency.value;
    }
    else {
      totalWithPDVref.current.textContent = parseFloat(totalWithRabatRef.current.textContent).toFixed(2) + ' ' + currency.value;
    }
  }

  const totalRef = React.useRef(null);

  const [rabatModal, setRabatModal] = React.useState(false);
  const rabatModalHandler = () => {
    setRabatModal(r => !r);
  }

  const rabatInputRef = React.useRef(null);
  const rabatRef = React.useRef(null);
  const totalWithoutPDVref = React.useRef(null);
  const totalWithRabatRef = React.useRef(null);

  const [rabatOption, setRabatOption] = React.useState();
  const rabatOptRef = React.useRef(null);

  const rabatOptionHandler = (option) => {
    setRabatOption(option);
    rabatOptRef.current.textContent = option;
  }

  const [rabatPercent, setRabatPercent] = React.useState(null);
  const addRabat = () => {
    if (!isNaN(rabatInputRef.current.value)) {
      if (rabatInputRef.current.value) {
        if (rabatOption === '%') {
          setRabatPercent(rabatInputRef.current.value);
          rabatRef.current.textContent = parseFloat(totalWithoutPDVref.current.textContent) * parseFloat(rabatInputRef.current.value) / 100;
          rabatRef.current.textContent = parseFloat(rabatRef.current.textContent).toFixed(2) + ' ' + currency.value;
          totalWithRabatRef.current.textContent = parseFloat(totalWithoutPDVref.current.textContent) * parseFloat(rabatInputRef.current.value) / 100;
          totalWithRabatRef.current.textContent = parseFloat(totalWithoutPDVref.current.textContent) - parseFloat(totalWithRabatRef.current.textContent);
          totalWithRabatRef.current.textContent = parseFloat(totalWithRabatRef.current.textContent).toFixed(2) + ' ' + currency.value;
          getPDV();
          rabatModalHandler();
        }
        else if (rabatOption === currency.value) {
          setRabatPercent(rabatInputRef.current.value / parseFloat(totalWithoutPDVref.current.textContent) * 100);
          rabatRef.current.textContent = parseFloat(rabatInputRef.current.value).toFixed(2) + ' ' + currency.value;
          totalWithRabatRef.current.textContent = parseFloat(totalWithoutPDVref.current.textContent).toFixed(2) - parseFloat(rabatInputRef.current.value).toFixed(2);
          totalWithRabatRef.current.textContent = parseFloat(totalWithRabatRef.current.textContent).toFixed(2) + ' ' + currency.value;
          getPDV();
          rabatModalHandler();
        }
      }
    }

    avansRef.current.textContent = '';
    paidRef.current.textContent = '';
    totalRef.current.textContent = '';
    setAvans(0.00);
    setPDVvalue(0);
  }

  const [avansModal, setAvansModal] = React.useState(false);
  const avansModalHandler = () => {
    setAvansModal(a => !a);
  }

  const avansInputRef = React.useRef(null);
  const avansRef = React.useRef(null);
  const [avans, setAvans] = React.useState(0);
  const addAvans = () => {
    if (!isNaN(avansInputRef.current.value)) {
      if (avansInputRef.current.value) {
        avansRef.current.textContent = parseFloat(avansInputRef.current.value).toFixed(2) + ' ' + currency.value;
        totalRef.current.textContent = parseFloat(totalWithPDVref.current.textContent).toFixed(2) - parseFloat(avansRef.current.textContent).toFixed(2);
        totalRef.current.textContent = parseFloat(totalRef.current.textContent).toFixed(2) + ' ' + currency.value;
        avansModalHandler();
      }
      let a = 100 / parseFloat(totalWithPDVref.current.textContent.replace('EUR', '')).toFixed(2) * avansInputRef.current.value;
      setAvans(a.toFixed(2));
    }
  }

  const [paidModal, setPaidModal] = React.useState(false);
  const paidModalHandler = () => {
    setPaidModal(p => !p);
  }

  const paidInputRef = React.useRef(null);
  const paidRef = React.useRef(null);
  const dueRef = React.useRef(null);
  const [dueAmount, setDueAmount] = React.useState(' ');
  const addPaid = () => {
    if (!isNaN(paidInputRef.current.value)) {
      if (paidInputRef.current.value) {
        paidRef.current.textContent = parseFloat(paidInputRef.current.value).toFixed(2) + ' ' + currency.value;
        dueRef.current.textContent = parseFloat(totalRef.current.textContent).toFixed(2) - parseFloat(paidInputRef.current.value).toFixed(2);
        setDueAmount(parseFloat(dueRef.current.textContent).toFixed(2) + ' ' + currency.value);
        paidModalHandler();
      }
    }
  }

  const [calculation, setCalculation] = React.useState([]);

  const getCalculation = () => {
    calculation.push({
      Due: dueAmount,
      Paid: paidRef.current.textContent,
      Total: totalRef.current.textContent,
      Avans: avansRef.current.textContent,
      TotalWithPDV: totalWithPDVref.current.textContent,
      totalWithRabat: totalWithRabatRef.current.textContent,
      Rabat: rabatRef.current.textContent,
      totalWithoutPDV: totalWithoutPDVref.current.textContent,
      PDVvalue: PDVvalue
    });

    return calculation;
  }

  const messageRef = React.useRef(null);
  const saveInvoice = (id) => {
    if (dueDate === '' || dueAmount === '' || description.length < 1 || dueAmount < 0.01 || !clientInBox[0].clientID || pdv === '') {
      messageRef.current.style.display = 'block';
      messageRef.current.style.background = 'red';
      messageRef.current.style.color = 'white';
      messageRef.current.textContent = 'Info missing';

      setTimeout(() => {
        messageRef.current.style.display = 'none';
      }, 2000);

      return;
    }

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/invoices/addInvoice`,
      data: {
        ClientID: clientInBox[0].clientID,
        DateOfIssue: moment(date, 'DD.MM.YYYY').toDate().getTime(),
        DueDate: dueDate.type == 'specific' ? dueDate.value : moment(dueDate.value, 'DD/MM/YYYY').toDate().getTime(),
        Articles: description,
        Calculation: getCalculation(),
        PDV: pdv,
        RabatPercent: rabatPercent,
        Currency: currency.value,
        Type: invoiceType.type,
        BusinessID: id,
        Remark: remark,
        InvoiceIssuedBy: props.loggedUser,
        LogoURL: invoiceLogoRef.current.src,
        EstimateNumber: '',
        isPaid: paid,
        AdditionalData: '',
        AvansPercent: avans,
        CustomNumber: invoiceType.num,
        Language: langCode
      },
      ...backendModule.axiosConfig
    }).then(res => {
      messageRef.current.style.background = '#67E564';
      messageRef.current.style.color = '#272737';
      messageRef.current.style.display = 'block';
      messageRef.current.textContent = 'Successfully saved invoice!';
      props.loadData(id);
      let qty;
      description.map((item, index) => {
        servicesArray.map((ser) => {
          if (item.name === ser.Name) {
            if (ser.Quantity != null) {
              qty = Number(ser.Quantity) - Number(item.quantity);
              return axios({
                method: "POST",
                url: `${backendModule.backendURL}/articles/editArticle`,
                data: {
                  ID: ser.ID,
                  Name: ser.Name,
                  Price: ser.Price,
                  MPC: ser.MPC,
                  Quantity: qty
                },
                ...backendModule.axiosConfig
              }).then(res => {
                if (res.data.status === "ok") {

                }
              }).catch(() => {

              });
            }
          }
        })
      });
      setTimeout(props.onClose, 1000);
    }).catch(() => {

    });
  }

  const [customersData, setCustomersData] = React.useState();
  const [noClientsData, setNoClientsData] = React.useState(false);

  const getAllClients = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/customers/getAllCustomers`,
      data: {
        filters: []
      },
      ...backendModule.axiosConfig
    }).then(res => {
      //res.data.data.length = 0;
      !res.data.data.length > 0 ? setNoClientsData(true) : setNoClientsData(false);
      return setCustomersData(res.data);
    }).catch(() => {
      setCustomersData({ status: 'error', data: 'Data fetch failed' });
      return setServerError(prevError => {
        return prevError + 'CLIENTS_ERROR';
      });
    });
  }

  React.useEffect(() => {
    getAllClients();
  }, []);

  const dateOfIssueRef = React.useRef(null);
  const [dueDate, setDueDate] = React.useState({ type: '', value: '' });
  const daysUntilDueRef = React.useRef(null);
  const openDaysUntilDueRef = React.useRef(null);
  const [date, setDate] = React.useState(null);
  const [showSpecificDateInput, setShowSpecificDateInput] = React.useState(false);
  const specificDateRef = React.useRef(null);
  const specificDateInputRef = React.useRef(null);

  const getDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(1, '0');
    let mm = String(today.getMonth() + 1).padStart(1, '0');
    let yyyy = today.getFullYear();

    today = dd + '.' + mm + '.' + yyyy;
    return setDate(today);
  }

  React.useEffect(() => {
    getDate();
  }, []);

  const getDateOfIssue = () => {
    if (dateOfIssueRef.current.checked === true) {
      openDaysUntilDueRef.current.checked = false;
      specificDateRef.current.checked = false;
      setShowSpecificDateInput(false);
      setShowDaysInput(false);
      let today = new Date();
      let dd = String(today.getDate()).padStart(1, '0');
      let mm = String(today.getMonth() + 1).padStart(1, '0');
      let yyyy = today.getFullYear();

      today = dd + '.' + mm + '.' + yyyy;

      return setDueDate({ type: 'today', value: today });
    }
    else {
      setDueDate('');
    }
  }

  const setDaysUntilDue = () => {
    daysUntilDueRef.current.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        if (openDaysUntilDueRef.current.checked === true) {
          let days = daysUntilDueRef.current.value;
          let date = new Date();
          let last = new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));
          let day = last.getDate();
          let month = last.getMonth() + 1;
          let year = last.getFullYear();

          date = day + '/' + month + '/' + year;
          setDueDate({ type: 'until', value: date });
        }
        else {

        }
      }
    });
  }

  const [defaultDate, setDefaultDate] = React.useState();
  const getDefaultDate = () => {
    let days = 5;
    let date = new Date();
    let last = new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));
    let day = last.getDate();
    let month = last.getMonth() + 1;
    let year = last.getFullYear();

    date = day + '.' + month + '.' + year;
    setDueDate({ type: 'until', value: date });
    return setDefaultDate(date);
  }

  React.useEffect(() => {
    getDefaultDate();
  }, []);

  const specificDateHandler = () => {
    openDaysUntilDueRef.current.checked = false;
    dateOfIssueRef.current.checked = false;
    setShowSpecificDateInput(s => !s);
    setShowDaysInput(false);
  }

  const setSpecificDate = () => {
    return setDueDate({ type: 'specific', value: specificDateInputRef.current.value });
  }

  const [currencyModal, setCurrencyModal] = React.useState(false);
  const [currency, setCurrency] = React.useState({ conversion: 1, value: '----' });
  const [currencyData, setCurrencyData] = React.useState();
  const [changed, setChanged] = React.useState(false);

  const currencyModalHandler = () => {
    if (changed) {
      messageRef.current.style.background = '#faca2d';
      messageRef.current.style.color = '#272737';
      messageRef.current.style.display = 'block';
      messageRef.current.textContent = 'Changing the currency is possible only when no article has been added!';
      setTimeout(() => {
        messageRef.current.style.display = 'none';
      }, 3200);
      return;
    }
    setCurrencyModal(c => !c);
  }

  const getAllCurrencies = async () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/currencies/getAllCurrencies`,
      data: {

      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
        setCurrencyData(res.data);
      };
    }).catch(() => {
      setCurrencyData({ status: 'error', data: 'Data fetch failed' });
      return setServerError(true);
    });
  }

  React.useEffect(() => {
    getAllCurrencies();
  }, []);

  const changeCurrency = (val) => {
    if (currencyData.data) {
      currencyData.data?.map((item, key) => {
        if (item.Code === val) {
          setCurrency({ conversion: item.Conversion, value: val });
        }
      });
    }
    currencyModalHandler();
  }

  //kalkulacija na svakoj promjeni
  React.useEffect(() => {
    if (!isNaN(parseFloat(rabatRef.current.textContent))) {
      if (rabatOption === '%') {
        totalWithRabatRef.current.textContent = parseFloat(totalWithoutPDVref.current.textContent) * parseFloat(rabatPercent) / 100;
        totalWithRabatRef.current.textContent = parseFloat(totalWithoutPDVref.current.textContent) - parseFloat(totalWithRabatRef.current.textContent);
        totalWithRabatRef.current.textContent = parseFloat(totalWithRabatRef.current.textContent).toFixed(2) + ' ' + currency.value;
        getPDV();
      }
      else if (rabatOption === currency.value) {
        totalWithRabatRef.current.textContent = parseFloat(totalWithoutPDVref.current.textContent).toFixed(2) - parseFloat(rabatRef.current.textContent).toFixed(2);
        totalWithRabatRef.current.textContent = parseFloat(totalWithRabatRef.current.textContent).toFixed(2) + ' ' + currency.value;
        getPDV();
      }
    }

    if (!isNaN(parseFloat(avansRef.current.textContent))) {
      totalRef.current.textContent = parseFloat(totalWithPDVref.current.textContent).toFixed(2) - parseFloat(avansRef.current.textContent).toFixed(2);
      totalRef.current.textContent = parseFloat(totalRef.current.textContent).toFixed(2) + ' ' + currency.value;
    }

    if (!isNaN(parseFloat(paidRef.current.textContent))) {
      paidRef.current.textContent = parseFloat(paidRef.current.textContent).toFixed(2) + ' ' + currency.value;
      setDueAmount(parseFloat(parseFloat(totalRef.current.textContent).toFixed(2) - parseFloat(paidRef.current.textContent).toFixed(2)).toFixed(2) + ' ' + currency.value);
    }
    //promjena valute omoguci/onemoguci
    if (parseFloat(totalWithoutPDVref.current.textContent) > 0) {
      setChanged(true);
    }
    else {
      setChanged(false);
    }
  });

  React.useEffect(() => {
    rabatRef.current.textContent = '';
    totalWithRabatRef.current.textContent = '';
    totalWithPDVref.current.textContent = '';
    avansRef.current.textContent = '';
    paidRef.current.textContent = '';
    totalRef.current.textContent = '';
    setAvans(0.00);
    setRabatPercent(0.00);
    setPDVvalue(0);
  }, [description.length]);

  const scrollTriggerRef = React.useRef(null);
  const [loadingData, setLoadingData] = React.useState(false);
  const servicesModalRef = React.useRef(null);
  const useIsInViewport = () => {
    const [isIntersecting, setIsIntersecting] = React.useState(false);

    const observer = React.useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );

    React.useEffect(() => {
      observer.observe(scrollTriggerRef.current);

      return () => {
        observer.disconnect();
      };
    }, [scrollTriggerRef, observer]);

    if (isIntersecting) {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/articles/getAllArticles`,
        data: {
          pagination: servicesArray.length,
          filters: [
            ...filters,
            { name: 'BusinessID', op: 'eq', value: id }
          ],
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === "ok") {
          if (res.data.data.length > 0) {
            setLoadingData(true);
            setTimeout(() => setLoadingData(false), 100);
            res.data.data?.map((item) => {
              servicesArray.push(item);
            });
          }
        };
      }).catch(() => {
      });
    }
  }
  useIsInViewport();

  const [typeModal, setTypeModal] = React.useState(false);
  const typeModalHandler = () => {
    setTypeModal(t => !t);
  }

  const [invoiceType, setInvoiceType] = React.useState({ num: 3000, label: 'BiH', type: 'FAKT.' });
  const changeType = (label, type) => {
    if (label === 'OTP.') setInvoiceType({ num: 2500, label: label, type: type });
    if (label === 'PRED.') setInvoiceType({ num: 2000, label: label, type: type });
    if (label === 'BiH') setInvoiceType({ num: 3000, label: label, type: type });
    if (label === 'INO.') setInvoiceType({ num: 3600, label: label, type: type });
    if (label === 'AVS.') setInvoiceType({ num: 4000, label: label, type: type });
    typeModalHandler();
  }

  const [language, setLanguage] = React.useState(bosnian);
  const [lang, setLang] = React.useState(false);
  const [langCode, setLangCode] = React.useState('ba');
  const changeLanguage = () => {
    setLang(l => !l);
  }

  const [invoiceNumber, setInvoiceNumber] = React.useState();
  const inum_date = new Date();
  const cur_year = inum_date.getFullYear();
  const cur_month = String(inum_date.getMonth() + 1).padStart(2, '0');
  const getAllInvoicesByType = (id, type) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/invoices/getAllInvoices`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id },
          { name: 'Type', op: 'eq', value: type }
        ],
      },
      ...backendModule.axiosConfig
    }).then(response => {
      if (response.data.data.length <= 1) {
        setInvoiceNumber(`${cur_year}/${cur_month}/${invoiceType.num}/${response.data.data.length + 1}`);
      }
      else {
        let date = response.data.data[response.data.data.length - 1].InvoiceNumber;
        setInvoiceNumber(`${cur_year}/${cur_month}/${invoiceType.num}/${date + 1}`);
      }
    }).catch(() => {

    });
  }

  React.useEffect(() => {
    getAllInvoicesByType(id, invoiceType.type);
  }, [invoiceType]);

  const [businesses, setBusinesses] = React.useState([]);
  const getAllBusinesses = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/business/getAllBusinesses`,
      data: {
        filters: []
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setBusinesses(res.data);
      }
    }).catch(() => {
    });
  }

  React.useEffect(() => {
    getAllBusinesses();
  }, []);

  const [remarkModal, setRemarkModal] = React.useState(false);
  const addRemarkHandler = () => {
    setRemarkModal(r => !r);
  }

  const [remark, setRemark] = React.useState([]);
  const addRemark = (item) => {
    let tmp = remark;
    tmp.push(item);
    setRemark(tmp);
    addRemarkHandler();
  }

  const deleteRemark = (id) => {
    setRemark(d => d.filter((_, idx) => idx !== id));
  }

  const [pdvModal, setPDVmodal] = React.useState(false);
  const pdvInputRef = React.useRef(null);
  const choosePDVmodalHandler = () => {
    setPDVmodal(p => !p);
  }

  const changePDV = (value) => {
    if (!isNaN(pdvInputRef.current.value)) {
      setPDV(value);
      setAvans(0.00);
      avansRef.current.textContent = '';
      totalRef.current.textContent = '';
      paidRef.current.textContent = '';
    }
  }

  const [newRemark, setNewRemark] = React.useState(false);
  const addNewRemarkHandler = () => {
    setNewRemark(r => !r);
    if (newRemark === true) {
      getAllremarks(id);
    }
  }

  const [remarksData, setRemarksData] = React.useState([]);

  const getAllremarks = (id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/remarks/getAllRemarks`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id }
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
        return setRemarksData(res.data);
      };
    }).catch(() => {
      setRemarksData({ status: 'error', data: 'Data fetch failed' });
      return setServerError(prevError => {
        return prevError + 'REMARKS_ERROR';
      });
    });
  }
  React.useEffect(() => {
    if (remarkModal) {
      getAllremarks(id);
    }
  }, [filters, remarkModal]);

  const deleteRemarkFromDB = (item_id, id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/remarks/removeRemark`,
      data: {
        ID: item_id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status = 'ok') {
        getAllremarks(id)
      }
    }).catch(() => {

    });
  }

  const [selectedImage, setSelectedImage] = React.useState(null);
  let imgFile = new FormData();
  const uploadImage = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/images/uploadImage`,
      data: imgFile,
      ...backendModule.axiosConfig
    }).then(
      res => {
        getAllImages();
      }
    ).catch();
  }

  React.useEffect(() => {
    if (selectedImage) {
      imgFile.append('image', selectedImage);
      imgFile.append('ImageName', selectedImage.name);
      imgFile.append('tag', id);
      uploadImage();
    }
  }, [selectedImage]);

  const [logos, setLogos] = React.useState([]);
  const getAllImages = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/images/getAllImages`,
      ...backendModule.axiosConfig
    }).then(
      res => {
        let tmp = [];
        res.data.data.map((item) => {
          if (item.Tag === id) {
            tmp.push(item);
            return setLogos(tmp);
          }
        });
        return setLogos(tmp);
      }
    ).catch();
  }

  React.useEffect(() => {
    getAllImages();
  }, []);

  const [logosModal, setLogosModal] = React.useState(false);
  const logoModalHandler = () => {
    setLogosModal(l => !l);
  }

  const deleteImage = (id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/images/deleteImage`,
      data: {
        id: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      getAllImages();
    }).catch();
  }

  const invoiceLogoRef = React.useRef(null);
  const changeLogo = (url) => {
    invoiceLogoRef.current.src = url;
  }

  return (
    <div ref={newInvoiceRef} className='component__createNewInvoice'>
      <div className='component__createNewInvoice__head'>
        <h1 id='head-item1'>New Invoice</h1>
        <p id='head-item2' onClick={props.onClose}>Cancel</p>
        <Button className='head-item3' onClick={() => saveInvoice(id)} accent='#67E564' value='Save' />
        <img onClick={() => mobileSettingsHandler()} className='head-item5' id='mobile-settings-icon' src='images/settings (4) 1 (1).png' />
      </div>
      <br></br>
      <hr></hr>
      <div ref={messageRef} className='msg'></div>
      <br></br>
      <div className='component__createNewInvoice__template'>
        <div className='component__createNewInvoice__template__file'>
          <br></br>
          <div className='component__createNewInvoice__template__file__head'>
            <div>
              {
                businesses.data?.map((item, index) => {
                  if (id == item.ID) {
                    return <>
                      <h4>{item.BusinessName}</h4>
                      <p>{item.Address}, {item.City}, {item.ZIP}</p>
                      <p>{item.Country}</p>
                      <p>{language.IDbroj} {item.IDnumber}</p>
                      <p>{language.PDVbroj} {item.PDVnumber}</p>
                      <p>{language.TranRacun} {item.TrnAccount} {item.BenBank}</p>
                    </>
                  }
                })
              }
            </div>
            {
              logos ?
                logos.length > 0 ?
                  <img ref={invoiceLogoRef} src={logos[0].URL} onClick={() => { setSelectedImage(null) }} />
                  : ''
                : ''
            }
          </div>

          <div className='component__createNewInvoice__template__file__client'>
            <div className='component__createNewInvoice__template__file__client__left'>
              {(() => {
                if (addClientModal === true) {
                  return <div className='component__createNewInvoice__template__file__client__left__addClientModal'>
                    <div className='component__createNewInvoice__template__file__client__left__addClientModal__nav'>
                      <input id='create-inv-search-clients' onChange={() => searchTerm('#create-inv-search-clients', '#create-inv-client-name')} type='text' placeholder='Search client' />
                      <img onClick={() => addClientHandler()} src='images/closeIcon.png' alt='' />
                    </div>
                    <div className='component__createNewInvoice__template__file__client__left__addClientModal__list'>
                      <br></br>
                      <Spinner style={{ display: noClientsData || serverError.includes('CLIENTS') ? 'block' : 'none', width: "32px", height: "32px" }} color="#3F73FF" align="center" />
                      <p style={{ display: noClientsData || serverError.includes('CLIENTS') ? 'block' : 'none', color: 'red', fontFamily: 'Roboto', textAlign: 'center' }}>{noClientsData ? 'No data to display' : ''}{serverError.includes('CLIENTS') ? 'Data fetch failed' : ''}</p>
                      {
                        !serverError.includes('CLIENTS') ?
                          customersData?.data.map((item, index) => {
                            return <p onClick={() => addClientInNewInvoice(index)} ref={(element) => { clientRef.current[index] = element; }} cid={item.ID} id='create-inv-client-name'>{item.Name}</p>
                          })
                          : ''
                      }
                    </div>
                  </div>
                }
              })()}
              <Button className='addClient-btn' accent='#F1F1F1' value='Add a client' onClick={() => addClientHandler()} />
              <br></br>
              <p id='invoice-customer'>{language.Kupac}</p>
              {
                clientInBox?.map((item) => {
                  return <>
                    <p>{item.name}</p>
                    <p>{item.address}</p>
                    <p>{item.phone}</p>
                    <p>{item.city}</p>
                    <p>{item.country}</p>
                    <p>{item.pdvNumber}</p>
                  </>
                })
              }
            </div>
            <div className='component__createNewInvoice__template__file__client__right'>
              <span><p style={{ color: '#00A3FF', fontWeight: '700' }}>{language.Broj} {invoiceType.type === 'OTP.' ? language.BrojOtpremnice : invoiceType.type === 'PRED.' ? language.BrojPredracuna : invoiceType.type === 'BiH.' ? language.BrojFakture : language.BrojFakture}</p><p style={{ fontWeight: '700' }}>{invoiceNumber}</p></span>
              <span><p style={{ color: '#00A3FF' }}>{language.DatumIzdavanja}</p><p>{date}</p></span>
              <span style={{ cursor: 'pointer' }} onClick={() => dueDateModalHandler()}><p style={{ color: '#00A3FF' }}>{language.ZadnjiRokUplate}</p><p>{dueDate.value ? dueDate.value : defaultDate}</p></span>
              <div style={{ display: dueDateModal ? 'block' : 'none' }} className='component__createNewInvoice__template__file__client__right__dueDateModal'>
                <div className='component__createNewInvoice__template__file__client__right__dueDateModal__head'>
                  <h5>{language.ZadnjiRokUplate}</h5>
                  <img onClick={() => dueDateModalHandler()} src='images/closeIcon.png' alt='' />
                </div>
                <div className='component__createNewInvoice__template__file__client__right__dueDateModal__body'>
                  <span><input onChange={() => getDateOfIssue()} ref={dateOfIssueRef} type='checkbox' /> <p>{language.NaDatumIzdavanja}</p></span>
                  <span><input onChange={handleCheckbox} ref={openDaysUntilDueRef} type='checkbox' /> <p>{language.NakonBrojaDana}</p></span>
                  <div style={{ display: showDaysInput ? 'block' : 'none' }} className='component__createNewInvoice__template__file__client__right__dueDateModal__body__daysInput'>
                    <p>{language.DanaDoRoka}</p>
                    <input onKeyPress={() => setDaysUntilDue()} ref={daysUntilDueRef} type='text' />
                  </div>
                  <span><input onChange={() => specificDateHandler()} ref={specificDateRef} type='checkbox' /> <p>{language.NaOdredeniDatum}</p></span>
                  <div style={{ display: showSpecificDateInput ? 'block' : 'none' }}>
                    <input onChange={() => setSpecificDate()} ref={specificDateInputRef} type='date' />
                  </div>
                </div>
              </div>
              <br></br>
              <hr></hr>
              <br></br>
              <span><p style={{ color: '#00A3FF' }}>{language.Dug} ({currency.value})</p><p>{dueAmount}</p></span>
              <br></br>
              <hr></hr>
              <p id='person-responsible'>{language.RacunIzdao} {props.loggedUser}</p>
            </div>
          </div>
          <br></br>
          <hr></hr>
          <br></br>

          <div className='component__createNewInvoice__template__file__description'>
            <div className='component__createNewInvoice__template__file__description__headers'>
              <div ref={descRef} id='service-description'>
                <p>{language.Opis}</p>
                {
                  description?.map((item, index) => {
                    return <strong id={index}> <img onClick={() => deleteService(index)} style={{ width: '8px' }} src='images/closeIcon.png' alt='' /> {item.name}</strong>
                  })
                }
                <input onChange={() => searchTerm('#search-services', '#service-block')} id='search-services' style={{ display: addNewLineModal ? 'block' : 'none' }} type='text' placeholder='Search services' />
              </div>
              <div ref={mpcFieldRef} id='service-mpc'>
                <p>{language.MPC}</p>
                {
                  description?.map((item) => {
                    return <strong>{item.mpc}</strong>
                  })
                }
              </div>
              <div ref={priceFieldRef} id='service-price'>
                <p>{language.Cijena}</p>
                {
                  description?.map((item) => {
                    return <strong id='q-price'>{parseFloat(item.price).toFixed(2)} {currency.value}</strong>
                  })
                }
              </div>
              <div ref={quantityRef} id='service-quantity'>
                <p>{language.Kolicina}</p>
                {description?.map((item) => {
                  return <strong id='quantity'>{item.quantity}</strong>
                })}
              </div>
              <div ref={priceFieldRef} id='service-price'>
                <p>{language.Ukupno}</p>
                {
                  description?.map((item) => {
                    return <strong id='s-price'>{parseFloat(item.price).toFixed(2)}</strong>
                  })
                }
              </div>
              <div id='service-pdv'>
                <p>{language.PDV}</p>
                {
                  description?.map((item) => {
                    return <strong>{pdv} %</strong>
                  })
                }
              </div>
              <div id='service-totalWithoutPDV'>
                <p>{language.UkupnoBezPDV}</p>
                {
                  description?.map((item) => {
                    return <strong id='s-totwopdv' >{parseFloat(item.price).toFixed(2)}</strong>
                  })
                }
              </div>
            </div>
            <br></br>
            <Button style={{ display: addNewLineModal ? 'none' : 'block' }} className='addNewLine-btn' accent='#F1F1F1' value='Add new line' onClick={() => addNewLineHandler()} />
            <div ref={servicesModalRef} style={{ display: addNewLineModal ? 'block' : 'none' }} className='component__createNewInvoice__template__file__description__servicesModal'>
              <div style={{display: customArticleModal ? 'flex' : 'none'}} className='component__createNewInvoice__template__file__description__servicesModal__addCustomServiceModal'>
                <img src='images/closeIcon.png' alt='' onClick={() => addCustomArticle()} />
                <p>Dodaj proizvoljan artikal</p>
                <input type='text' placeholder='Name of article' ref={customServiceNameRef} />
                <input type='number' placeholder='Price in selected currency' ref={customServicePriceRef} />
                <input type='number' placeholder='Quantity' ref={customServiceQuantityRef} />
                <input type='text' placeholder='Unit of measure' ref={customServiceMPCref} />
                <Button value = 'Add' accent = 'rgb(103, 229, 100)' className='customServiceBtn' onClick={() => addCustomDescription()} />
              </div>
              <div id='services-header'>
                <img onClick={() => addNewLineHandler()} src='images/closeIcon.png' alt='' />
                <img src='images/plus.png' alt='' onClick={() => addCustomArticle()} />
              </div>
              <div id='services-scrollable'>
                <Spinner style={{ display: noData || serverError.includes('ARTICLES') ? 'block' : 'none', width: "32px", height: "32px" }} color="#3F73FF" align="center" />
                <p style={{ display: noData || serverError.includes('ARTICLES') ? 'block' : 'none', color: 'red', fontFamily: 'Roboto', textAlign: 'center' }}>{noData ? 'No data to display' : ''}{serverError.includes('ARTICLES') ? 'Data fetch failed' : ''}</p>
                {
                  !serverError.includes('ARTICLES') ?
                    servicesArray.map((item, index) => {
                      if (addNewLineModal === true) {
                        return (<div onClick={() => addDescription(index)} key={index} id='service-block'><p style={{ display: 'none' }} ref={(element) => { curServiceIDref.current[index] = element; }}>{item.ID}</p><p id='service-name' ref={(element) => { serviceRef.current[index] = element; }}>{item.Name}</p> <p ref={(element) => { mpcRef.current[index] = element; }}>{item.MPC}</p> <p ref={(element) => { serPriceRef.current[index] = element }}>{parseFloat(item.Price * currency.conversion).toFixed(2)} {currency.value}</p></div>)
                      }
                    })
                    : ''
                }
                <div ref={scrollTriggerRef}></div>
                <Spinner style={{ display: loadingData ? 'block' : 'none', width: "32px", height: "32px" }} color="#3F73FF" align="center" />
              </div>
            </div>
            <div style={{ display: quantityModal ? 'flex' : 'none' }} className='component__createNewInvoice__template__file__description__quantityModal'>
              <div style={{ display: warning ? 'flex' : 'none' }} className='component__createNewInvoice__template__file__description__quantityModal__warning'>
                <p ref={warningMsgRef}></p>
                <strong onClick={() => { addQuantity('yes') }}>CONTINUE</strong>
                <strong onClick={() => { addQuantity('no') }}>NO</strong>
              </div>
              <div style={{ display: warning ? 'none' : 'flex' }} className='component__createNewInvoice__template__file__description__quantityModal__enterValue'>
                <h1>{language.UnesiteKolicinu}</h1>
                <br></br>
                <input ref={quantityInputRef} />
              </div>
              <Button style={{ display: warning ? 'none' : 'block' }} className='component__createNewInvoice__template__file__description__quantityModal__button' accent='rgb(103, 229, 100)' value='Done' onClick={() => addQuantity('check')} />
            </div>
          </div>
          <br></br>
          <hr></hr>
          <br></br>

          <div className='component__createNewInvoice__template__file__total'>
            <div>

            </div>
            <div className='component__createNewInvoice__template__file__total__table'>
              <div style={{ display: avansModal ? 'block' : 'none' }} className='component__createNewInvoice__template__file__total__table__avansModal'>
                <h5>{language.DodajAvans}</h5>
                <div className='component__createNewInvoice__template__file__total__table__avansModal__body'>
                  <div><input ref={avansInputRef} type='text' /></div>
                  <div><Button onClick={() => avansModalHandler()} className='close-btn' accent='transparent' value='Cancel' /> <Button onClick={() => addAvans()} className='add-avans-btn' accent='#67E564' value='Done' /></div>
                </div>
              </div>
              <div style={{ display: paidModal ? 'block' : 'none' }} className='component__createNewInvoice__template__file__total__table__paidModal'>
                <h5>{language.UnesiteKolikoJePlaćeno}</h5>
                <div className='component__createNewInvoice__template__file__total__table__paidModal__body'>
                  <div><input ref={paidInputRef} type='text' /></div>
                  <div><Button onClick={() => paidModalHandler()} className='close-btn' accent='transparent' value='Cancel' /> <Button onClick={() => addPaid()} className='add-paid-btn' accent='#67E564' value='Done' /></div>
                </div>
              </div>
              <div style={{ display: rabatModal ? 'block' : 'none' }} className='component__createNewInvoice__template__file__total__table__rabatModal'>
                <h5>{language.UnesiteRabat}</h5>
                <div className='component__createNewInvoice__template__file__total__table__rabatModal__body'>
                  <div><input ref={rabatInputRef} type='text' /><p ref={rabatOptRef}></p><p>{language.Odaberite}</p><p style={{ fontWeight: '900', color: 'green', cursor: 'pointer', fontSize: '16px' }} onClick={() => rabatOptionHandler('%')}>%</p><p style={{ fontWeight: '900', color: 'green', cursor: 'pointer' }} onClick={() => rabatOptionHandler(currency.value)}>{currency.value}</p></div>
                  <div><Button onClick={() => rabatModalHandler()} className='close-btn' accent='transparent' value='Cancel' /> <Button onClick={() => addRabat()} className='add-rabat-btn' accent='#67E564' value='Done' /></div>
                </div>
              </div>
              <table>
                <tr>
                  <td>{language.UkupnaCijenaFakture}</td>
                  <td id='subtotal' ref={totalWithoutPDVref}></td>
                </tr>
                <tr>
                  <td style={{ cursor: 'pointer' }} onClick={() => rabatModalHandler()}>Rabat({Number(rabatPercent).toFixed(2)}%):</td>
                  <td ref={rabatRef}></td>
                </tr>
                <tr>
                  <td>{language.UkupnoSaRabatom}</td>
                  <td ref={totalWithRabatRef}></td>
                </tr>
                <tr>
                  <td>{language.PDV} ({pdv}%):</td>
                  <td>{PDVvalue.toFixed(2)} {currency.value}</td>
                </tr>
                <tr>
                  <td>{language.UkupnoSaPDV}</td>
                  <td ref={totalWithPDVref}></td>
                </tr>
                <tr>
                  <td style={{ cursor: 'pointer' }} onClick={() => avansModalHandler()}>{language.Avans} ({avans}%)</td>
                  <td ref={avansRef}></td>
                </tr>
                <tr>
                  <td>{language.Ukupno}</td>
                  <td ref={totalRef}></td>
                </tr>
                <tr>
                  <td style={{ cursor: 'pointer' }} onClick={() => paidModalHandler()}>{language.Placeno}</td>
                  <td ref={paidRef}></td>
                </tr>
              </table>
            </div>
          </div>
          <br></br>
          <hr></hr>
          <br></br>

          <div className='component__createNewInvoice__template__file__amountDue'>
            <div>

            </div>
            <div>
              <p>{language.Dug} ({currency.value}):</p>
            </div>
            <div>
              <h3 ref={dueRef}>{dueAmount}</h3>
            </div>
          </div>

          <div className='component__createNewInvoice__template__file__remark'>
            {(() => {
              if (newRemark) {
                return <RemarksModal handler={addNewRemarkHandler} />
              }
            })()}
            <Button className='addRemarkButton' value={remarkModal ? 'Close' : 'Add remark'} onClick={() => addRemarkHandler()} />
            <div style={{ display: remarkModal ? 'block' : 'none' }} className='component__createNewInvoice__template__file__remark__remarkModal'>
              <div className='component__createNewInvoice__template__file__remark__remarkModal__head'>
                <input placeholder='Search...' id='remark-search' onChange={() => searchTerm('#remark-search', '#remark-item')} />
                <img onClick={() => addNewRemarkHandler()} src='images/plus.png' alt='' />
              </div>
              <div className='component__createNewInvoice__template__file__remark__remarkModal__list'>
                {
                  remarksData.data ?
                    remarksData.data?.map((item, index) => {
                      return <span id='remark-item'><img onClick={() => deleteRemarkFromDB(item.ID, id)} src='images/closeIcon.png' alt='' /><p onClick={() => addRemark({ ID: item.ID, Text: item.Text })}>{item.Text}</p></span>
                    })
                    : ''
                }
              </div>
            </div>
            {
              remark.length > 0 ?
                remark.map((item, index) => {
                  return <p id='remark-in'><img src='images/closeIcon.png' alt='' onClick={() => deleteRemark(index)} />{item.Text}</p>
                })
                : ''
            }
          </div>

          {
            businesses.data?.map((item, index) => {
              if (id == item.ID) {
                return <div className='component__createNewInvoice__template__file__notes'>
                  <div>
                    <h1>{language.NaseAdrese}</h1>
                    <p>{item.Address}, {item.City}, {item.ZIP}</p>
                    <p>{item.WebsiteURL}</p>
                  </div>
                  <div>
                    <h1>{language.PravniPodaci}</h1>
                    <p>ID Broj:{item.IDnumber}</p>
                    <p>PDV broj: {item.PDVnumber}</p>
                    <p>IBAN: {item.IBAN}</p>
                    <p>SWIFT: {item.SWIFT}</p>
                  </div>
                  <div>
                    <h1>{language.Kontakti}</h1>
                    <p>{item.PhoneNumber} - {item.FirstPerson}</p>
                    <p>{item.SecPhoneNumber} {item.SecPhoneNumber ? '-' : ''} {item.SecPersonName}</p>
                    <p>{item.Email}</p>
                    <p>{item.WebsiteURL}</p>
                  </div>
                </div>
              }
            })
          }
        </div>
        <div ref={mobileSettingsRef} className='component__createNewInvoice__template__options'>
          <div className='component__createNewInvoice__template__options__settings'>
            <div style={{ display: logosModal ? 'flex' : 'none' }} className='component__createNewInvoice__template__options__settings__logosModal'>
              <img src='images/closeIcon.png' alt='' onClick={() => logoModalHandler()} />
              <input
                type='file'
                name='logoImage'
                onChange={
                  (event) => {
                    setSelectedImage(event.target.files[0]);
                  }
                }
              />
              <div id='logos-title'><p>Here you can choose logo for this invoice</p></div>
              <div className='component__createNewInvoice__template__options__settings__logosModal__list'>
                {
                  logos?.map((item) => {
                    return <span><img id='trash' src='images/trash-can.png' onClick={() => deleteImage(item.ID)} /><img id='logo' src={item.URL} alt='logo' onClick={() => changeLogo(item.URL)} /></span>
                  })
                }
              </div>
            </div>
            <div style={{ display: lang ? 'flex' : 'none' }} className='component__createNewInvoice__template__options__settings__languageModal'>
              <p onClick={() => { setLanguage(bosnian); setLangCode('ba'); changeLanguage(); }}>Bosanian</p>
              <p onClick={() => { setLanguage(english); setLangCode('en'); changeLanguage(); }}>English</p>
            </div>
            <strong>Settings for this invoice</strong>
            <br></br>
            <br></br>
            <hr></hr>
            <br></br>

            <div id='customize-style'>
              <div>
                <strong>Logos</strong>
                <p>Choose logo</p>
              </div>
              <span onClick={() => logoModalHandler()}><img src='images/rightArrow.png' /></span>
            </div>

            <div id='make-recurring' onClick={() => changeLanguage()}>
              <div>
                <strong>Language</strong>
                <p>Choose language</p>
              </div>
              <span><img src='images/rightArrow.png' /></span>
            </div>
          </div>
          <div className='component__createNewInvoice__template__options__userSettings'>
            <div style={{ display: currencyModal ? 'flex' : 'none' }} className='component__createNewInvoice__template__options__userSettings__currencyModal'>
              <input id='create-inv-search-currency' onChange={() => searchTerm('#create-inv-search-currency', '#create-inv-currency-code')} type='text' placeholder='Search currency' />
              <div>
                {
                  currencyData ?
                    currencyData.data?.map((item) => {
                      return <p id='create-inv-currency-code' onClick={() => changeCurrency(item.Code)}>{item.Code}</p>
                    })
                    : ''
                }
              </div>
            </div>
            <div style={{ display: typeModal ? 'flex' : 'none' }} className='component__createNewInvoice__template__options__userSettings__typeModal'>
              <p onClick={() => changeType('OTP.', 'OTP.')}>OTPREMNICA</p>
              <p onClick={() => changeType('PRED.', 'PRED.')}>PREDRAČUN</p>
              <p onClick={() => changeType('BiH', 'FAKT.')}>VP BiH</p>
              <p onClick={() => changeType('INO.', 'FAKT.')}>VP INO</p>
              <p onClick={() => changeType('AVS.', 'FAKT.')}>VP AVANS</p>
            </div>
            <div style={{ display: pdvModal ? 'flex' : 'none' }} className='component__createNewInvoice__template__options__userSettings__pdvModal'>
              <input id='pdv-input' ref={pdvInputRef} onChange={() => changePDV(pdvInputRef.current.value)} type='text' />
              <Button value='Done' onClick={() => choosePDVmodalHandler()} className='setPDVbutton' accent='rgb(103, 229, 100)' />
            </div>
            <div id='profile'>
              <div>
                <strong id='profile-name'>{props.loggedUser}</strong>
                <p id='profile-role'>{
                  businesses.data?.map((item, index) => {
                    if (id == index + 1) {
                      return <p>{item.BusinessName}</p>
                    }
                  })}
                </p>
              </div>
            </div>
            <br></br>
            <hr></hr>
            <br></br>
            <div id='send-reminder'>
              <div>
                <strong>Paid</strong>
                <p>Is invoice paid?</p>
              </div>
              <span onClick={() => setPaid(p => !p)}><strong>{paid ? 'Yes' : 'No'}</strong><img src='images/rightArrow.png' /></span>
            </div>

            <div id='change-last-fees'>
              <div>
                <strong>PDV</strong>
                <p>Choose PDV</p>
              </div>
              <span onClick={() => choosePDVmodalHandler()}><strong>{pdv} %</strong><img src='images/rightArrow.png' /></span>
            </div>

            <div id='currency'>
              <div>
                <strong>Currency</strong>
                <p>Choose currency</p>
              </div>
              <span onClick={() => currencyModalHandler()}><strong>{currency.value}</strong><img src='images/rightArrow.png' /></span>
            </div>

            <div id='invoice-attachment'>
              <div>
                <strong>Type</strong>
                <p>Choose type</p>
              </div>
              <span onClick={() => typeModalHandler()}><strong>{invoiceType.label}</strong><img src='images/rightArrow.png' /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
