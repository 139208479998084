import React from 'react';
import "./index.scss";
import Button from '../customComponents/Button';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import { useParams } from 'react-router-dom';
import moment from 'moment';

export default function QuestionModal(props) {

    const { id } = useParams();
    const modalRef = React.useRef(null);
    const [filters, setFilters] = React.useState([]);
    const [curInvoice, setCurInvoice] = React.useState([]);
    const [chooseModal, setChooseModal] = React.useState(false);
    const [invoiceNumber, setInvoiceNumber] = React.useState();
    const inum_date = new Date();
    const cur_year = inum_date.getFullYear();
    const cur_month = String(inum_date.getMonth() + 1).padStart(2, '0');
    const [curBusinessCountry, setCurBusinessCountry] = React.useState(null)

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    }
    React.useEffect(() => {
        scrollToRef(modalRef);
    }, []);


    const getCurInvoice = (id, type) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/invoices/getAllInvoices`,
            data: {
                filters: [
                    ...filters,
                    { name: 'ID', op: 'eq', value: id },
                    { name: 'Type', op: 'eq', value: type }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setCurInvoice(res.data.data[0]);
            }
            if (res.data.status === 'error') {

            }
        }).catch(() => {
        });
    }

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/invoices/getAllInvoices`,
            data: {
                filters: [
                    ...filters,
                    { name: 'BusinessID', op: 'eq', value: id },
                    { name: 'Type', op: 'eq', value: 'FAKT.' }
                ],
            },
            ...backendModule.axiosConfig
        }).then(response => {
            if (response.data.data.length <= 1) {
                setInvoiceNumber(`${cur_year}/${cur_month}/undefined/${response.data.data.length + 1}`);
            }
            else {
                let date = response.data.data[response.data.data.length - 1].InvoiceNumber;
                date = date.slice(13);
                date = Number(date);
                setInvoiceNumber(`${cur_year}/${cur_month}/undefined/${date + 1}`);
            }
        }).catch(() => {

        });
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/business/getAllBusinesses`,
            data: {
                filters: [
                    ...filters,
                    { name: 'ID', op: 'eq', value: id },
                ],
            },
            ...backendModule.axiosConfig
        }).then(response => {
            switch(response.data.data[0].Country){
                case 'Bosnia and Herzegovina':
                    setCurBusinessCountry('VP BiH');
                break;
                case 'Republic of Serbia':
                    setCurBusinessCountry('VP SRB');
                break;
                case 'Croatia':
                    setCurBusinessCountry('VP HRV');
                break;
                default: setCurBusinessCountry('WS DOMESTIC');
            }
        }).catch(() => {

        });
    }, []);

    const makeInvoice = (id, type) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/invoices/addInvoice`,
            data: {
                ClientID: curInvoice.ClientID,
                DateOfIssue: curInvoice.DateOfIssue,
                DueDate: curInvoice.DueDate,
                Articles: JSON.parse(curInvoice.Articles),
                Calculation: JSON.parse(curInvoice.Calculation),
                PDV: curInvoice.PDV,
                Currency: curInvoice.Currency,
                Type: 'FAKT.',
                BusinessID: id,
                Remark: JSON.parse(curInvoice.Remark),
                InvoiceIssuedBy: curInvoice.InvoiceIssuedBy,
                LogoURL: curInvoice.LogoURL,
                RabatPercent: curInvoice.RabatPercent,
                EstimateNumber: `${moment(curInvoice.DateOfIssue).format('YY/MM')}/${curInvoice.CustomNumber}/${curInvoice.InvoiceNumber}`,
                isPaid: true,
                AdditionalData: {
                    ...JSON.parse(curInvoice.AdditionalData),
                    ProformaID: curInvoice.ID
                },
                AvansPercent: curInvoice.AvansPercent,
                CustomNumber: type === 'VP BiH' ? 3000 : type === 'VP INO' ? 3600 : type === 'VP AVANS' ? 4000 : 0,
                Language: language
            },
            ...backendModule.axiosConfig
        }).then(res => {
            start();
            props.loadData(id, cur_year);
            props.handler();
        }).catch(() => {

        });
    }

    const start = () => {
        setChooseModal(m => !m);
    }

    React.useEffect(() => {
        getCurInvoice(props.id, props.type)
    }, []);

    const [langSelected, setLangSelected] = React.useState(false);
    const [language, setLanguage] = React.useState('ba');

    const selectLanguage = (language) => {
        setLangSelected(true);
        setLanguage(language);
    }

    return (
        <div ref={modalRef} className='component__questionModal'>
            <div style={{ display: chooseModal ? 'flex' : 'none' }} className='component__questionModal__chooseTypeModal'>
                <h1>Select language</h1>
                <p onClick={() => selectLanguage('en')}>English</p>
                <p onClick={() => selectLanguage('ba')}>Bosnian</p>
                <div style={{display: langSelected ? 'flex' : 'none'}}>
                    <h1>Select type:</h1>
                    <p onClick={() => makeInvoice(id, 'VP BiH')}>{curBusinessCountry}</p>
                    <p onClick={() => makeInvoice(id, 'VP INO')}>VP INO</p>
                    <p onClick={() => makeInvoice(id, 'VP AVANS')}>VP AVANS</p>
                </div>
            </div>
            <h1>Are you sure ?</h1>
            <div>
                <Button value='YES' accent='rgb(0, 163, 255)' onClick={() => start()} />
                <Button value='NO' accent='rgb(0, 163, 255)' onClick={() => props.handler()} />
            </div>
        </div>
    )
}
