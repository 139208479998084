import React from 'react';
import "./index.scss";
import Button from '../customComponents/Button';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";

export default function EditVehicle(props) {

    const editVehicleRef = React.useRef(null);
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }

    React.useEffect(() => {
        scrollToRef(editVehicleRef);
    }, []);

    const manuFieldRef = React.useRef(null);
    const manuRef = React.useRef(null);
    const modelFieldRef = React.useRef(null);
    const modelRef = React.useRef(null);
    const yearFieldRef = React.useRef(null);
    const yearRef = React.useRef(null);
    const colorFieldRef = React.useRef(null);
    const colorRef = React.useRef(null);
    const regRef = React.useRef(null);
    const regFieldRef = React.useRef(null);

    const [editInfo, setEditInfo] = React.useState(null);
    const editHandler = (item) => {
        setEditInfo(item);
    }

    const changeInformations = (fieldRef, inputRef) => {
        inputRef.current.addEventListener('change', () => {
            fieldRef.current.innerText = inputRef.current.value;
        });

        inputRef.current.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                if (inputRef.current.value === '') {
                    setEditInfo(null);
                }
                else {
                    fieldRef.current.innerText = inputRef.current.value;
                    setEditInfo(null);
                }
            }
            else {
                fieldRef.current.innerText = inputRef.current.value;
            }
        });
    }

    const [saveMsg, setSaveMsg] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(false);

    const saveEditedVehicle = (id, handler) => {
        const body = {
            ID: id,
            Manufacturer: manuRef.current.value,
            Model: modelRef.current.value,
            RegistrationMarks: regRef.current.value,
            Year: yearRef.current.value,
            Color: colorRef.current.value
        };
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/vehicles/editVehicle`,
            data: body,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setSaveMsg(true);
                props.data[0]?.data.map((item, index) => {
                    if (item.ID === id) {
                        item.Manufacturer = manuRef.current.value;
                        item.Model = modelRef.current.value;
                        item.Year = yearRef.current.value;
                        item.Color = colorRef.current.value;
                    }
                });
                props.getAll(props.bid);
            }
        }).catch(() => {

        });

        setTimeout(() => {
            handler();
        }, 1500);
    }

    return (
        <div ref={editVehicleRef}>
            <p id='edit-vehicle-msg' style={{ display: saveMsg ? 'block' : 'none' }}>Vehicle saved successfully</p>
            <div className='component__editVehicle'>
                <strong>Add new vehicle</strong>
                <hr></hr>
                <p>Manufacturer</p>
                <span><strong style={{ display: editInfo === 'manu' ? 'none' : 'flex' }} id='vehcile-manu' ref={manuFieldRef}>{props.manufacturer}</strong><input ref={manuRef} defaultValue={props.manufacturer} id='manu-input' onChange={() => changeInformations(manuFieldRef, manuRef)} style={{ display: editInfo === 'manu' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('manu')} src='images/editPen.png' alt='' /></span>
                <p>Registration Marks</p>
                <span><strong style={{ display: editInfo === 'reg' ? 'none' : 'flex' }} id='vehicle-req' ref={regFieldRef}>{props.regMarks}</strong><input ref={regRef} defaultValue={props.regMarks} id='reg-input' onChange={() => changeInformations(regFieldRef, regRef)} style={{ display: editInfo === 'reg' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('reg')} src='images/editPen.png' alt='' /></span>
                <p>Model</p>
                <span><strong style={{ display: editInfo === 'model' ? 'none' : 'flex' }} id='vehicle-model' ref={modelFieldRef}>{props.model}</strong><input ref={modelRef} defaultValue={props.model} id='model-input' onChange={() => changeInformations(modelFieldRef, modelRef)} style={{ display: editInfo === 'model' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('model')} src='images/editPen.png' alt='' /></span>
                <p>Year</p>
                <span><strong style={{ display: editInfo === 'year' ? 'none' : 'flex' }} id='vehicle-year' ref={yearFieldRef}>{props.year}</strong><input ref={yearRef} defaultValue={props.year} id='year-input' onChange={() => changeInformations(yearFieldRef, yearRef)} style={{ display: editInfo === 'year' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('year')} src='images/editPen.png' alt='' /></span>
                <p>Color</p>
                <span><strong style={{ display: editInfo === 'color' ? 'none' : 'flex' }} id='vehicle-color' ref={colorFieldRef}>{props.color}</strong><input ref={colorRef} defaultValue={props.color} id='color-input' onChange={() => changeInformations(colorFieldRef, colorRef)} style={{ display: editInfo === 'color' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('color')} src='images/editPen.png' alt='' /></span>
                <br></br>
                <div className='component__editVehicle__controles'>
                    <Button onClick={() => saveEditedVehicle(props.id, props.handler)} accent='rgb(103, 229, 100)' value='Save' />
                    <Button onClick={props.handler} accent='rgb(103, 229, 100)' value='Cancel' />
                </div>
            </div>
        </div>
    )
}