const english = {
    IDbroj: 'ID Number:',
    PDVbroj: 'VAT Number:',
    TranRacun: 'Tran. acc:',
    Kupac: 'Customer:',
    BrojFakture: 'of invoice:',
    BrojPredracuna: 'of proforma invoice:',
    BrojOtpremnice: 'of dispatch:',
    Broj: 'Number',
    DatumIzdavanja: 'Date of issue:',
    ZadnjiRokUplate: 'Payment deadline:',
    Dug: 'Due',
    RacunIzdao: 'Invoice issued:',
    Opis: 'Description',
    MPC: 'UoM',
    Cijena: 'Price',
    Kolicina: 'Quantity',
    Ukupno: 'Total',
    PDV: 'VAT',
    UkupnoBezPDV: 'Total without VAT',
    UkupnaCijenaFakture: 'The total price of the invoice:',
    Rabat: 'Rebate',
    UkupnoSaRabatom: 'Total with rebate:',
    UkupnoSaPDV: 'Total including VAT:',
    Avans: 'Advance payment:',
    Ukupno: 'Total:',
    Plaćeno: 'Paid:',
    NaseAdrese: 'Our addresses:',
    PravniPodaci: 'Legal information:',
    Kontakti: 'Contacts:',
    IBAN: 'IBAN:',
    SWIFT: 'SWIFT:',
    NakonBrojaDana: 'After number of days',
    NaDatumIzdavanja: 'On the date of issue',
    NaOdredeniDatum: 'On a certain date',
    DanaDoRoka: 'Days to the deadline',
    UnesiteKolicinu: 'Enter the quantity',
    DodajAvans: 'Enter the amount',
    UnesiteKolikoJePlaćeno: 'Enter how much was paid',
    UnesiteRabat: 'Enter a rebate',
    Odaberite: 'Choose:',
    UkupnaCijenaFakture: 'The total price of the invoice:',
    UkupnoSaRabatom: 'Total with rebate:',
    Placeno: 'Paid:',
    PozivNaBroj: 'Reference number:',
    Potpis: 'Signature',
    UnesitePDV: 'Enter VAT',
    Stornirano: 'THE INVOICE HAS BEEN CANCELED',
};

export { english };