import React from 'react';
import "./index.scss";
import Button from '../customComponents/Button';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";

export default function EditClient(props) {

    const editClientRef = React.useRef(null);
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }
    React.useEffect(() => {
        scrollToRef(editClientRef);
    }, []);


    const nameRef = React.useRef(null);
    const addressRef = React.useRef(null);
    const cityRef = React.useRef(null);
    const pdvRef = React.useRef(null);
    const idnumRef = React.useRef(null);
    const mailRef = React.useRef(null);
    const phoneRef = React.useRef(null);
    const countryRef = React.useRef(null);
    const nameFieldRef = React.useRef(null);
    const addressFieldRef = React.useRef(null);
    const cityFieldRef = React.useRef(null);
    const pdvFieldRef = React.useRef(null);
    const idnumFieldRef = React.useRef(null);
    const mailFieldRef = React.useRef(null);
    const phoneFieldRef = React.useRef(null);
    const countryFieldRef = React.useRef(null);
    const editClientMsgRef = React.useRef(null);
    const [editInfo, setEditInfo] = React.useState(null);

    const editHandler = (item) => {
        setEditInfo(item);
    }

    const changeInformations = (fieldRef, inputRef) => {
        inputRef.current.addEventListener('change', () => {
            fieldRef.current.innerText = inputRef.current.value;
        });

        inputRef.current.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                if (inputRef.current.value === '') {
                    setEditInfo(null);
                }
                else {
                    fieldRef.current.innerText = inputRef.current.value;
                    setEditInfo(null);
                }
            }
            else {
                fieldRef.current.innerText = inputRef.current.value;
            }
        });
    }

    const [saveMsg, setSaveMsg] = React.useState(false);
    const errorMsgRef = React.useRef(null);

    const saveEditedClient = (handler, id) => {
        if (isNaN(phoneRef.current.value)) {
            errorMsgRef.current.style.display = 'block';
            errorMsgRef.current.innerText = 'Phone number must be a number';
            return setTimeout(() => {
                errorMsgRef.current.style.display = 'none';
            }, 1500);
        }

        const body = {
            ID: id,
            Name: nameRef.current.value,
            Address: addressRef.current.value,
            City: cityRef.current.value,
            Country: countryRef.current.value,
            IDNumber: idnumRef.current.value,
            PDVNumber: pdvRef.current.value,
            Email: mailRef.current.value,
            PhoneNumber: phoneRef.current.value,
        };
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/customers/editCustomer`,
            data: body,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setSaveMsg(true);
                props.data[0]?.data.map((item, index) => {
                    if (item.ID === id) {
                        item.Name = nameRef.current.value;
                        item.Address = addressRef.current.value;
                        item.City = cityRef.current.value;
                        item.Country = countryRef.current.value;
                        item.PDVNumber = pdvRef.current.value;
                        item.Email = mailRef.current.value;
                        item.PhoneNumber = phoneRef.current.value;
                        item.IDNumber = idnumRef.current.value;
                    }
                });
            }
            if (res.data.status === "error") {
                errorMsgRef.current.style.display = 'block';
                errorMsgRef.current.innerText = 'Info missing';
                return setTimeout(() => {
                  errorMsgRef.current.style.display = 'none';
                }, 1500);
              }
        }).catch(() => {

        });

        setTimeout(() => {
            handler();
        }, 1500);
    }

    return (
        <div ref={editClientRef} className='component__editClient'>
            <p id='edit-article-msg' style={{ display: saveMsg ? 'block' : 'none' }}>Client saved successfully</p>
            <p id='error-article-msg' ref={errorMsgRef}></p>
            <div className='component__editClient__box'>
                <div className='component__editClient__box__contactInformations'>
                    <h1>Contact Informations</h1>
                    <br></br>
                    <hr></hr>
                    <br></br>
                    <p>Name</p>
                    <span><strong style={{ display: editInfo === 'name' ? 'none' : 'flex' }} id='client-name' ref={nameFieldRef}>{props.name}</strong><input ref={nameRef} defaultValue={props.name} id='name-input' onKeyPress={() => changeInformations(nameFieldRef, nameRef)} style={{ display: editInfo === 'name' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('name')} src='images/editPen.png' alt='' /></span>
                    <p>Email</p>
                    <span><strong style={{ display: editInfo === 'mail' ? 'none' : 'flex' }} id='client-mail' ref={mailFieldRef}>{props.email}</strong><input ref={mailRef} defaultValue={props.email} id='mail-input' onKeyPress={() => changeInformations(mailFieldRef, mailRef)} style={{ display: editInfo === 'mail' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('mail')} src='images/editPen.png' alt='' /></span>
                    <p>Phone</p>
                    <span><strong style={{ display: editInfo === 'phone' ? 'none' : 'flex' }} id='client-phone' ref={phoneFieldRef}>{props.phone}</strong><input ref={phoneRef} defaultValue={props.phone} id='phone-input' onKeyPress={() => changeInformations(phoneFieldRef, phoneRef)} style={{ display: editInfo === 'phone' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('phone')} src='images/editPen.png' alt='' /></span>
                    <p>Address</p>
                    <span><strong style={{ display: editInfo === 'address' ? 'none' : 'flex' }} id='client-address' ref={addressFieldRef}>{props.address}</strong><input ref={addressRef} defaultValue={props.address} id='address-input' onKeyPress={() => changeInformations(addressFieldRef, addressRef)} style={{ display: editInfo === 'address' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('address')} src='images/editPen.png' alt='' /></span>
                    <p>City</p>
                    <span><strong style={{ display: editInfo === 'city' ? 'none' : 'flex' }} id='client-city' ref={cityFieldRef}>{props.city}</strong><input ref={cityRef} defaultValue={props.city} id='city-input' onKeyPress={() => changeInformations(cityFieldRef, cityRef)} style={{ display: editInfo === 'city' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('city')} src='images/editPen.png' alt='' /></span>
                    <p>PDV Number</p>
                    <span><strong style={{ display: editInfo === 'pdv' ? 'none' : 'flex' }} id='client-pdv' ref={pdvFieldRef}>{props.pdv}</strong><input ref={pdvRef} defaultValue={props.pdv} id='pdv-input' onKeyPress={() => changeInformations(pdvFieldRef, pdvRef)} style={{ display: editInfo === 'pdv' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('pdv')} src='images/editPen.png' alt='' /></span>
                    <p>ID Number</p>
                    <span><strong style={{ display: editInfo === 'idnum' ? 'none' : 'flex' }} id='client-idnum' ref={idnumFieldRef}>{props.idNumber}</strong><input ref={idnumRef} defaultValue={props.idNumber} id='idnum-input' onKeyPress={() => changeInformations(idnumFieldRef, idnumRef)} style={{ display: editInfo === 'idnum' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('idnum')} src='images/editPen.png' alt='' /></span>
                    <p>Country</p>
                    <span><strong style={{ display: editInfo === 'country' ? 'none' : 'flex' }} id='client-country' ref={countryFieldRef}>{props.country}</strong><input ref={countryRef} defaultValue={props.country} id='country-input' onKeyPress={() => changeInformations(countryFieldRef, countryRef)} style={{ display: editInfo === 'country' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('country')} src='images/editPen.png' alt='' /></span>
                    <br></br>
                    <div className='component__editClient__box__contactInformations__controles'>
                        <Button onClick={() => saveEditedClient(props.handler, props.id)} accent='rgb(103, 229, 100)' value='Save' />
                        <Button onClick={props.handler} accent='rgb(103, 229, 100)' value='Cancel' />
                    </div>
                </div>
            </div>
        </div>
    )
}
