import React from 'react';
import "./index.scss";
import Button from '../customComponents/Button';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";

export default function EditWorker(props) {

    const editWorkerRef = React.useRef(null);
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }

    React.useEffect(()=>{
        scrollToRef(editWorkerRef);
    }, []);

    const nameRef = React.useRef(null);
    const departmentRef = React.useRef(null);
    const phoneRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const nameFieldRef = React.useRef(null);
    const departmentFieldRef = React.useRef(null);
    const phoneFieldRef = React.useRef(null);
    const emailFieldRef = React.useRef(null);

    const [editInfo, setEditInfo] = React.useState(null);
    const editHandler = (item) => {
        setEditInfo(item);
    }

    const changeInformations = (fieldRef, inputRef) => {
        inputRef.current.addEventListener('change', () => {
          fieldRef.current.innerText = inputRef.current.value;
        });
    
        inputRef.current.addEventListener('keypress', (e) => {
          if (e.key === 'Enter') {
            if (inputRef.current.value === '') {
              setEditInfo(null);
            }
            else {
              fieldRef.current.innerText = inputRef.current.value;
              setEditInfo(null);
            }
          }
          else {
            fieldRef.current.innerText = inputRef.current.value;
          }
        });
      }

    const [saveMsg, setSaveMsg] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(false);

    const saveEditedWorker = (id, handler) => {
        if (isNaN(phoneRef.current.value)) {
            setErrorMsg(true);
            return setTimeout(()=>{
              setErrorMsg(false);
            }, 1500);
        }

        const body = {
            ID: id,
            Name: nameRef.current.value,
            Department: departmentRef.current.value,
            PhoneNumber: phoneRef.current.value,
            Email: emailRef.current.value
        };
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/workers/editWorker`,
            data: body,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setSaveMsg(true);
                props.data[0]?.data.map((item, index) => {
                    if (item.ID === id) {
                        item.Name = nameRef.current.value;
                        item.Department = departmentRef.current.value;
                        item.phoneRef = phoneRef.current.value;
                        item.Email = emailRef.current.value;
                    }
                });
                props.getAll(props.bid);
            }
        }).catch(() => {

        });

        setTimeout(() => {
            handler();
        }, 1500);
    }

    return (
        <div ref={editWorkerRef}>
            <p id='edit-currency-msg' style={{ display: saveMsg ? 'block' : 'none' }}>Worker saved successfully</p>
            <p id='error-currency-msg' style={{ display: errorMsg ? 'block' : 'none' }}>Phone number must be a number</p>
            <div className='component__editWorker'>
                <p>Name</p>
                <span><strong style={{ display: editInfo === 'name' ? 'none' : 'flex' }} id='workers-name' ref={nameFieldRef}>{props.name}</strong><input ref={nameRef} defaultValue={props.name} id='name-input' onKeyPress={() => changeInformations(nameFieldRef, nameRef)} style={{ display: editInfo === 'name' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('name')} src='images/editPen.png' alt='' /></span>
                <p>Department</p>
                <span><strong style={{ display: editInfo === 'department' ? 'none' : 'flex' }} id='workers-department' ref={departmentFieldRef}>{props.department}</strong><input ref={departmentRef} defaultValue={props.department} id='department-input' onKeyPress={() => changeInformations(departmentFieldRef, departmentRef)} style={{ display: editInfo === 'department' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('department')} src='images/editPen.png' alt='' /></span>
                <p>Phone Number</p>
                <span><strong style={{ display: editInfo === 'phone' ? 'none' : 'flex' }} id='workers-phone' ref={phoneFieldRef}>{props.phone}</strong><input ref={phoneRef}  defaultValue={props.phone} id='phone-input' onKeyPress={() => changeInformations(phoneFieldRef, phoneRef)} style={{ display: editInfo === 'phone' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('phone')} src='images/editPen.png' alt='' /></span>
                <p>Email</p>
                <span><strong style={{ display: editInfo === 'email' ? 'none' : 'flex' }} id='workers-email' ref={emailFieldRef}>{props.email}</strong><input ref={emailRef}  defaultValue={props.email} id='email-input' onKeyPress={() => changeInformations(emailFieldRef, emailRef)} style={{ display: editInfo === 'email' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('email')} src='images/editPen.png' alt='' /></span>
                <br></br>
                <div className='component__editWorker__controles'>
                    <Button onClick={() => saveEditedWorker(props.id, props.handler)} accent='rgb(103, 229, 100)' value='Save' />
                    <Button onClick={props.handler} accent='rgb(103, 229, 100)' value='Cancel' />
                </div>
            </div>
        </div>
    )
}