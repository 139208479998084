import React from 'react';
import "./index.scss";
import "../../components/customComponents/Button";
import Button from '../../components/customComponents/Button';
import * as backendModule from "../../modules/backendModule";
import axios from "axios";

import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampAction";

export default function Login(props) {
  const curDispatch = useDispatch();
  const [spinner, setSpinner] = React.useState(false);
  const usernameRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const msgRef = React.useRef(null);
  const loginHandler = () => {
    let data = {
      username: usernameRef.current.value,
      password: passwordRef.current.value
    };
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/login`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        curDispatch(timestampActions.setTimestamp());
      }
      else{
        msgRef.current.innerText = 'Incorrect username or password';
      }
    }).catch(() => {
      msgRef.current.value = "Server is not responding"
    }).finally(() => {
      setSpinner(false);
    });
  }

  return (
    <div className='route__login'>
      <div style={{ display: props.display }} className='route__login__box'>
        <h1>Please log in</h1>
        <br></br>
        <input ref={usernameRef} type='text' placeholder='Username' />
        <input ref={passwordRef} type='password' placeholder='Password' />
        <div ref={msgRef} className='route__login__box__msg'></div>
        <Button onClick = {()=>loginHandler()} accent='#67E564' value='Login' />
      </div>
    </div>
  )
}
