import "./index.scss";
import React from "react";

import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

import * as backendModule from "../../modules/backendModule";
import axios from "axios";

import Spinner from "../../components/customComponents/Spinner";

import Button from "../../components/customComponents/Button";

const ChooseBusiness = (props) => {
    const [businesses, setBusinesses] = React.useState([]);
    const [noData, setNoData] = React.useState(false);
    const businessNameRef = React.useRef(null);
    const addressRef = React.useRef(null);
    const zipRef = React.useRef(null);
    const cityRef = React.useRef(null);
    const countryRef = React.useRef(null);
    const idNumberRef = React.useRef(null);
    const pdvNumberRef = React.useRef(null);
    const trnAccountRef = React.useRef(null);
    const benBankRef = React.useRef(null);
    const ibanRef = React.useRef(null);
    const swiftRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const phoneRef = React.useRef(null);
    const secPhoneRef = React.useRef(null);
    const secPersonRef = React.useRef(null);
    const websiteRef = React.useRef(null);
    const firstPersonRef = React.useRef(null);
    const [countries, setCountries] = React.useState([]);
    const [dropdown, setDropdown] = React.useState(false);
    const [loadData, setLoadData] = React.useState(true);
    const [filters, setFilters] = React.useState([]);

    const getAllBusinesses = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/business/getAllBusinesses`,
            data: {
                
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                res.data.data.length == 0 ? setNoData(true) : setNoData(false);
                res.data.data.map((item, index) => {
                    if(JSON.parse(item.AllowedUsers).includes(props.loggedUserID)){
                        setBusinesses(prev => [
                            ...prev,
                            res.data.data[index]
                        ]);
                    }
                });
            }
        }).catch(() => {
            setNoData(true);
        });
    }

    React.useEffect(() => {
        getAllBusinesses();
    }, []);

    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const [newBusiness, setNewBusiness] = React.useState(false);
    const addBusinessHandler = () => {
        setNewBusiness(b => !b);
    }

    const addBusiness = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/business/addBusiness`,
            data: {
                BusinessName: businessNameRef.current.value,
                Address: addressRef.current.value,
                ZIP: zipRef.current.value,
                City: cityRef.current.value,
                Country: countryRef.current.value,
                IDnumber: idNumberRef.current.value,
                PDVnumber: pdvNumberRef.current.value,
                TrnAccount: trnAccountRef.current.value,
                BenBank: benBankRef.current.value,
                IBAN: ibanRef.current.value,
                SWIFT: swiftRef.current.value,
                Email: emailRef.current.value,
                PhoneNumber: phoneRef.current.value,
                SecPhoneNumber: secPhoneRef.current.value,
                SecPersonName: secPersonRef.current.value,
                WebsiteURL: websiteRef.current.value,
                FirstPerson: firstPersonRef.current.value,
                AllowedUsers: [props.loggedUserID]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getAllBusinesses();
            addBusinessHandler();
        }).catch(() => {

        });
    }

    const logout = () => {
        let data;
        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                window.location.reload();
            }
            else {

            }
        }).catch(() => {

        }).finally(() => {

        });
    }

    const searchTerm = (searchbar, values_list) => {
        const search = document.querySelector(searchbar);
        const values = document.querySelectorAll(values_list);
        values.forEach(value => {
            value.style.display = 'block';
            if (!value.textContent.toLowerCase().includes(search.value.toLowerCase())) {
                value.style.display = 'none';
            }
        });
    }

    const countriesDropdown = () => {
        setDropdown(true);
        if (loadData) {
            fetch('/world-countries.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function (response) {
                    return response.json();
                })
                .then(function (json) {
                    json.features.forEach(item => {
                        setCountries(
                            prev => {
                                return [
                                    ...prev,
                                    { Name: item.properties.name, Code: item.id }
                                ]
                            }
                        )
                        setLoadData(false);
                    });
                });
        }
    }

    return <div className="route__chooseBusiness">

        <div className="route__chooseBusiness__menu">
            <div className="route__chooseBusiness__menu__head">
                <img id="scale-logo" src="images/logo.png" alt="" />
                <img id="settings-icon" src="images/settings.png" alt="" />
            </div>
            <p id="logged-user">{props.loggedUser}</p>
            <br></br>
            <hr></hr>
            <br></br>
            <Button value='Logout' accent='white' className='logoutButton' onClick={() => logout()} />
        </div>
        <div className="route__chooseBusiness__body">

            <div style={{ display: newBusiness ? 'flex' : 'none' }} className="route__chooseBusiness__body__addNewBusinessModal">
                <h1>Add new business</h1>
                <input ref={businessNameRef} placeholder="Business name" />
                <input ref={addressRef} placeholder="Address" />
                <input ref={zipRef} placeholder="ZIP" />
                <input ref={cityRef} placeholder="City" />
                <input id="search-countries" ref={countryRef} placeholder="Country" onChange={() => searchTerm('#search-countries', '#country')} onClick={() => countriesDropdown()} />
                <div style={{ display: dropdown ? 'flex' : 'none' }} className="route__chooseBusiness__body__addNewBusinessModal__countriesDropdown">
                    {
                        countries?.map((item, index) => {
                            return <strong id="country" onClick={() => { countryRef.current.value = document.querySelectorAll('#country')[index].textContent; setDropdown(false) }}>{item.Name}</strong>
                        })
                    }
                </div>
                <input ref={idNumberRef} placeholder="ID Number" />
                <input ref={pdvNumberRef} placeholder="PDV Number" />
                <input ref={trnAccountRef} placeholder="Transaction account " />
                <input ref={benBankRef} placeholder="Beneficiary bank " />
                <input ref={ibanRef} placeholder="IBAN" />
                <input ref={swiftRef} placeholder="SWIFT" />
                <input ref={emailRef} placeholder="Email" />
                <input ref={firstPersonRef} placeholder="Name" />
                <input ref={phoneRef} placeholder="Phone number" />
                <input ref={secPersonRef} placeholder="Second person name (optional)" />
                <input ref={secPhoneRef} placeholder="Second phone number (optional)" />
                <input ref={websiteRef} placeholder="Website url (optional)" />
                <div id="controles">
                    <Button className='closeBusinessButton' value='Close' accent='rgb(103, 229, 100)' onClick={() => addBusinessHandler()} />
                    <Button className='saveBusinessButton' value='Add' accent='rgb(103, 229, 100)' onClick={() => addBusiness()} />
                </div>
            </div>

            <div style={{ display: newBusiness ? 'none' : 'flex' }} className="route__chooseBusiness__chooseModal">
                <p>Choose a Business</p>
                {
                    !noData ?
                        businesses?.map((item, index) => {
                            return <div className="route__chooseBusiness__chooseModal__item" onClick={() => animateNavigate(`/business/${businesses[index].ID}`)}>
                                <div>
                                    <p>{item.BusinessName}</p>
                                </div>
                            </div>
                        })
                        : ''
                }
                <Button className='addNewBusinessButton' value='Add new business' accent='rgb(103, 229, 100)' onClick={() => addBusinessHandler()} />
            </div>
        </div>
    </div>
};

export default ChooseBusiness;