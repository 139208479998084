import React from 'react';
import "./index.scss";
import Button from "../../components/customComponents/Button";
import Spinner from "../../components/customComponents/Spinner";
import { useParams } from 'react-router-dom';
import { FilteredCustomTable } from "../customComponents/Table";
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";

import EditWorker from "../EditWorker";

export default function Workers() {

  const [noData, setNoData] = React.useState(false);
  const [serverError, setServerError] = React.useState(false);

  const [addNew, setAddNew] = React.useState(false);

  const addNewWorkerHandler = () => {
    setAddNew(n => !n);
  }

  const nameRef = React.useRef(null);
  const departmentRef = React.useRef(null);
  const phoneRef = React.useRef(null);
  const emailRef = React.useRef(null);
  const nameFieldRef = React.useRef(null);
  const departmentFieldRef = React.useRef(null);
  const phoneFieldRef = React.useRef(null);
  const emailFieldRef = React.useRef(null);

  const [editInfo, setEditInfo] = React.useState(null);
  const editHandler = (item) => {
    setEditInfo(item);
  }

  const changeInformations = (fieldRef, inputRef) => {
    inputRef.current.addEventListener('change', () => {
      fieldRef.current.innerText = inputRef.current.value;
    });

    inputRef.current.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        if (inputRef.current.value === '') {
          setEditInfo(null);
        }
        else {
          fieldRef.current.innerText = inputRef.current.value;
          setEditInfo(null);
        }
      }
      else {
        fieldRef.current.innerText = inputRef.current.value;
      }
    });
  }

  const msgRef = React.useRef(null);
  const { id } = useParams();

  const addWorker = (id) => {

    if (isNaN(phoneRef.current.value)) {
      msgRef.current.style.background = 'red';
      msgRef.current.style.display = 'block';
      msgRef.current.innerText = 'Phone number must be a number';
      return setTimeout(() => {
        msgRef.current.style.display = 'none';
      }, 1500);
    }

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/workers/addWorker`,
      data: {
        Name: nameRef.current.value,
        Department: departmentRef.current.value,
        PhoneNumber: phoneRef.current.value,
        Email: emailRef.current.value,
        BusinessID: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        msgRef.current.style.background = 'rgb(103, 229, 100)';
        msgRef.current.style.display = 'block';
        msgRef.current.innerText = 'New worker successfully added!';
        getAllWorkers(id);
        setTimeout(() => {
          msgRef.current.style.display = 'none';
          setAddNew(false);
        }, 1500);
      }
      if (res.data.status === 'error') {
        msgRef.current.style.background = 'red';
        msgRef.current.style.display = 'block';
        msgRef.current.innerText = 'Info missing';
        setTimeout(() => {
          msgRef.current.style.display = 'none';
        }, 1500);
      }
    }).catch(() => {

    });
  }

  const [filters, setFilters] = React.useState([]);
  const [workers, setWorkers] = React.useState([]);
  const getAllWorkers = (id) => {
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/workers/getAllWorkers`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id }
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        //res.data.data.length = 0;
        res.data.data.length > 0 ? setNoData(false) : setNoData(true);
        setWorkers(res.data);
      };
    }).catch(() => {
      setServerError({ type: 'SERVER_ERROR', data: 'Failed to fetch data' });
    });
  }

  React.useEffect(() => {
    getAllWorkers(id);
  }, [filters]);

  const addWorkersToTable = () => {
    if (workers.data) {
      return workers?.data.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.Name}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.Department}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.PhoneNumber}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.Email}</h3></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "button", text: "Edit", onClick: e => { editWorkerHandler(item.ID) } },
              {
                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                  return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: 'black' }}>Are you sure?</p>
                    <br></br>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button style={{ marginRight: '10px' }} accent='rgb(157, 195, 241)' value='YES' onClick={() => deleteWorker(item.ID)} />
                      <Button accent='rgb(157, 195, 241)' value='NO' onClick={c => { e() }} />
                    </div>
                  </div>);
                }
              },
            ]
          }
        ];
      });
    }
  }

  const [edit, setEdit] = React.useState(false);
  const [workerForEdit, setWorkerForEdit] = React.useState([]);

  const editWorkerHandler = (id) => {
    setEdit(e => !e);
    workers?.data.map((item, index) => {
      if (item.ID === id) {
        setWorkerForEdit([item.Name, item.Department, item.ID, item.PhoneNumber, item.Email]);
      }
    });
  }

  const deleteWorker = (item_id) => {
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/workers/removeWorker`,
      data: {
        ID: item_id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      getAllWorkers(id);
    }).catch(() => {
    });
  }

  return (
    <div className='component__workers'>
      <div className='component__workers__head'>
        <h1>Workers</h1>
        <Button className='addNewWorker' accent='rgb(103, 229, 100)' value={addNew ? 'Cancel' : 'Add new worker'} onClick={() => addNewWorkerHandler()} />
      </div>
      <br></br>
      <hr></hr>
      <br></br>
      <div id='msg' ref={msgRef}></div>
      <div style={{ display: addNew ? 'block' : 'none' }} className='component__workers__addNewWorkerModal'>
        <strong>Add new worker</strong>
        <hr></hr>
        <p>Name</p>
        <span><strong style={{ display: editInfo === 'name' ? 'none' : 'flex' }} id='workers-name' ref={nameFieldRef}>Some name</strong><input ref={nameRef} id='name-input' onChange={() => changeInformations(nameFieldRef, nameRef)} style={{ display: editInfo === 'name' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('name')} src='images/editPen.png' alt='' /></span>
        <p>Department</p>
        <span><strong style={{ display: editInfo === 'department' ? 'none' : 'flex' }} id='workers-department' ref={departmentFieldRef}>Some job position</strong><input ref={departmentRef} id='department-input' onChange={() => changeInformations(departmentFieldRef, departmentRef)} style={{ display: editInfo === 'department' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('department')} src='images/editPen.png' alt='' /></span>
        <p>Phone Number</p>
        <span><strong style={{ display: editInfo === 'phone' ? 'none' : 'flex' }} id='workers-phone' ref={phoneFieldRef}>+38760XXXXX</strong><input ref={phoneRef} id='phone-input' onChange={() => changeInformations(phoneFieldRef, phoneRef)} style={{ display: editInfo === 'phone' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('phone')} src='images/editPen.png' alt='' /></span>
        <p>Email</p>
        <span><strong style={{ display: editInfo === 'email' ? 'none' : 'flex' }} id='workers-email' ref={emailFieldRef}>some@email.com</strong><input ref={emailRef} id='email-input' onChange={() => changeInformations(emailFieldRef, emailRef)} style={{ display: editInfo === 'email' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('email')} src='images/editPen.png' alt='' /></span>
        <br></br>
        <Button onClick={() => addWorker(id)} accent='rgb(103, 229, 100)' value='Add' />
      </div>
      {
        (() => {
          if (edit) return <EditWorker bid={id} getAll={getAllWorkers} data={[workers, setWorkers]} handler={editWorkerHandler} name={workerForEdit[0]} department={workerForEdit[1]} id={workerForEdit[2]} phone={workerForEdit[3]} email={workerForEdit[4]} />
        })()
      }
      <Spinner style={{ display: serverError === false ? 'none' : 'block', width: '64px', height: '64px' }} align='center' color="rgb(157, 195, 241)" />
      <p style={{ display: serverError === false && !noData ? 'none' : 'block', fontFamily: 'Roboto', textAlign: 'center', color: 'red' }}>{serverError != false ? serverError.data : 'No data to display'}</p>
      <div style={{ display: serverError === false ? 'block' : 'none' }}>
        <FilteredCustomTable
          id='table'
          accent='rgb(157 195 241)'
          theme='light'
          headers={['Name', 'Department', 'Phone', 'Email']}
          filters={[
            { name: "Name", friendlyName: "Name", type: "string" },
            { name: "Department", friendlyName: "Department", type: "string" }
          ]}
          /*filterCB={(arg)=>{
            customersData?.data.map((item)=>{
              if(item.ID != arg[0].value){
                
              }
            })
          }}*/
          filterCB={f => setFilters(f)}

          data={!noData ? addWorkersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
        />
      </div>
    </div>
  )
}
