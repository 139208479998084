import React from 'react';
import "./index.scss";
import Button from '../customComponents/Button';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import { FilteredCustomTable } from "../customComponents/Table";

export default function Postbacks(props) {
    const [filters, setFilters] = React.useState(false);
    const [noData, setNoData] = React.useState(false);
    const [addNew, setAddNew] = React.useState(false);
    const [editInfo, setEditInfo] = React.useState(false);
    const msgRef = React.useRef(null);

    const addNewPostbackHandler = () => {
        setAddNew(a => !a);
    }

    const deletePostback = (id) => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/postbacks/removePostback`,
            data: {
              ID: id
            },
            ...backendModule.axiosConfig
          }).then(res => {
            if (res.data.status === 'ok') {
              getAllPostbacks();
            };
          }).catch(() => {
      
          });
    }

    const addPostbacksToTable = () => {
        if (postbacks.data) {
            return postbacks?.data.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div id='table-token-info' ><h3>{item.ID}</h3></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-token-info'><h3>{item.Name}</h3></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-token-info'><h3>{item.UserID}</h3></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-token-info'><h3 style={{wordBreak: 'break-all'}}>{item.URL}</h3></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            { keyID: String(item.ID), type: "custom", data: <div id='table-token-fhead'><p>Roles: </p></div> },
                            {
                                keyID: String(item.ID), type: "custom", data: <div id='table-flags'><div id='table-flags-content'>{item.Roles?.map((f) => { return <p>{f} </p> })}</div></div>
                            },
                        ]
                    },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Show secret", triggerDropdown: true, triggerData: e => {return (<p>{item.Secret}</p>)},
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>Are you sure?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='rgb(157, 195, 241)' value='YES' onClick={() => deletePostback(item.ID)} />
                                            <Button accent='rgb(157, 195, 241)' value='NO' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const editHandler = (item) => {
        setEditInfo(item);
    }

    const changeInformations = (fieldRef, inputRef) => {
        inputRef.current.addEventListener('change', () => {
            fieldRef.current.innerText = inputRef.current.value;
        });

        inputRef.current.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                if (inputRef.current.value === '') {
                    setEditInfo(null);
                }
                else {
                    fieldRef.current.innerText = inputRef.current.value;
                    setEditInfo(null);
                }
            }
            else {
                fieldRef.current.innerText = inputRef.current.value;
            }
        });
    }

    const [postbacks, setPostbacks] = React.useState([]);

    const getAllPostbacks = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/postbacks/getAllPostbacks`,
            data: {
                pagination: 0,
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                res.data.data.length < 1 ? setNoData(true) : setNoData(false);
                setPostbacks(res.data);
            };
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllPostbacks();
    }, [filters]);

    const nameFieldRef = React.useRef(null);
    const nameRef = React.useRef(null);
    const urlFieldRef = React.useRef(null);
    const urlRef = React.useRef(null);

    let codesGroup = [];

    const addPostback = () => {
        const codes_checkboxes = document.querySelectorAll('#checkbox');
        codes_checkboxes.forEach((c) => {
            if (c.checked) {
                codesGroup.push(c.getAttribute('val'));
            }
        });

        if(!String(urlFieldRef.current.value).startsWith('http')){
            msgRef.current.style.display = 'block';
            msgRef.current.innerText = 'URL must start with http';
            msgRef.current.style.background = 'red';
            setTimeout(() => {
                msgRef.current.style.display = 'none';
            }, 1500);
        }

        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/postbacks/addPostback`,
            data: {
                Name: nameFieldRef.current.textContent,
                Roles: codesGroup,
                Url: urlFieldRef.current.textContent,
                UserID: props.loggedUserID,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                msgRef.current.style.display = 'block';
                msgRef.current.innerText = 'New postback successfully added';
                msgRef.current.style.background = 'rgb(103, 229, 100)';
                setTimeout(() => {
                    getAllPostbacks();
                    msgRef.current.style.display = 'none';
                    addNewPostbackHandler();
                }, 1500);
            };
        }).catch(() => {

        });
    }

    const [postbackCodes, setPostbackCodes] = React.useState([]);
    const [serverError, setServerError] = React.useState();
    const getPostbackCodes = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/postbacks/getPostbackCodes`,
            data: {
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                //res.data.data.length = 0;
                res.data.data.length > 0 ? setNoData(false) : setNoData(true);
                setPostbackCodes(res.data);
            };
        }).catch(() => {
            setServerError({ type: 'SERVER_ERROR', data: 'Failed to fetch data' });
        });
    }

    React.useEffect(() => {
        getPostbackCodes();
    }, []);

    return (
        <div className='component__postbacks'>
            <div className='component__postbacks__head'>
                <h1>Postbacks</h1>
                <Button className='addNewPostback' accent='rgb(103, 229, 100)' value={addNew ? 'Cancel' : 'Add new postback'} onClick={() => addNewPostbackHandler()} />
            </div>
            <br></br>
            <hr></hr>
            <br></br>
            <div id='msg' ref={msgRef}></div>
            <div style={{ display: addNew ? 'block' : 'none' }} className='component__postbacks__addNewPostbackModal'>
                <p>Name</p>
                <span><strong style={{ display: editInfo === 'name' ? 'none' : 'flex' }} id='postback-name' ref={nameFieldRef}>Postback name</strong><input ref={nameRef} id='name-input' onChange={() => changeInformations(nameFieldRef, nameRef)} style={{ display: editInfo === 'name' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('name')} src='images/editPen.png' alt='' /></span>
                <p>URL</p>
                <span><strong style={{ display: editInfo === 'url' ? 'none' : 'flex' }} id='postback-url' ref={urlFieldRef}>Postback URL</strong><input ref={urlRef} id='name-input' onChange={() => changeInformations(urlFieldRef, urlRef)} style={{ display: editInfo === 'url' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('url')} src='images/editPen.png' alt='' /></span>
                <p>Select roles</p>
                <div id='flags-grid'>
                    {
                        postbackCodes.data?.map((item, index) => {
                            return <div><input type='checkbox' id='checkbox' val={item} /><p>{item}</p></div>
                        })
                    }
                </div>
                <Button onClick={() => addPostback()} accent='rgb(103, 229, 100)' value='Add' />
            </div>
            <div className='component__postbacks__body'>
                <FilteredCustomTable
                    id='table'
                    accent='rgb(157 195 241)'
                    theme='light'
                    headers={['ID', 'Name', 'User ID', 'URL']}
                    filters={[
                        { name: "Name", friendlyName: "Name", type: "string" }
                    ]}
                    filterCB={f => setFilters(f)}

                    data={!noData ? addPostbacksToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                />
            </div>
        </div>
    )
}
