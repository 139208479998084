import React from 'react';
import "./index.scss";
import Button from '../customComponents/Button';
import NewInvoiceBlock from "../NewInvoiceBlock";
import { FilteredCustomTable } from "../customComponents/Table";
import Invoice from "../Invoice";
import CreateNewInvoice from "../CreateNewInvoice";
import QuestionModal from '../QuestionModal';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import Spinner from '../customComponents/Spinner';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import DueListModal from "../DueListModal";
import FilterPanel from "../customComponents/FilterPanel";

export default function Dashboard(props) {

  const [invoiceShow, setInvoiceShow] = React.useState(false);
  const [invoiceID, setInvoiceID] = React.useState(null);
  const invoiceHandler = (id) => {
    setInvoiceID(id);
    setInvoiceShow(s => !s);
  }

  const [createNewInvoice, setCreateNewInvoice] = React.useState(false);

  const createNewInvoiceHandler = () => {
    setCreateNewInvoice(c => !c);
  }

  const [invoicesData, setInvoicesData] = React.useState([]);
  const [noData, setNoData] = React.useState(false);
  const [clientsData, setClientsData] = React.useState([]);
  const [serverError, setServerError] = React.useState('');
  const [filters, setFilters] = React.useState([]);

  const getAllClients = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/customers/getAllCustomers`,
      data: {
        filters: filters
      },
      ...backendModule.axiosConfig
    }).then(res => {
      //res.data.data.length = 0; 
      !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
      return setClientsData(res.data);
    }).catch(() => {
      setClientsData({ status: 'error', data: 'Data fetch failed' });
      setServerError(prevError => {
        return prevError + '_ALL_CLIENTS_ERROR_';
      });
    });
  }

  const date = new Date();
  const cur_year = date.getFullYear();

  const yearsList = [
    { id: 1, label: "2022" },
    { id: 2, label: cur_year - 1 },
    { id: 3, label: cur_year - 2 },
    { id: 4, label: cur_year - 3 },
    { id: 5, label: cur_year - 4 },
    { id: 6, label: cur_year - 5 }
  ];

  const [isOpen, setOpen] = React.useState(false);
  const [items, setItem] = React.useState(yearsList);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [year, setYear] = React.useState(2022);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (id) => {
    selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
    yearsList.map((item) => {
      if (item.id == id) {
        setYear(item.label);
      }
    });
  }

  const { id } = useParams();
  const getAllInvoices = (id, year) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/invoices/getAllInvoices`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id },
          { name: 'dateOfIssue', op: 'like', value: year }
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      //res.data.data.length = 0; 
      if (res.data.status === 'ok') {
        !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
        return setInvoicesData(res.data);
      }
      if (res.data.status === 'error') {
        setNoData(true);
      }
    }).catch(() => {
      setInvoicesData({ status: 'error', data: 'Data fetch failed' });
      setServerError(prevError => {
        return prevError + '_ALL_INVOICES_ERROR_';
      });
    });
  }

  React.useEffect(() => {
    getAllInvoices(id, year);
  }, [filters, year]);

  React.useEffect(() => {
    getAllClients();
  }, []);

  const [recentlyAdded, setRecentlyAdded] = React.useState([]);
  const getRecentlyAddedInvoices = (id, year) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/invoices/getAllInvoices`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id },
          { name: 'DateOfIssue', op: 'like', value: year }
        ],
      },
      ...backendModule.axiosConfig
    }).then(response => {
      if (!response.data.data.length > 0) {
        return setNoData(true);
      }
      else {
        setNoData(false);
        if (response.data.data.length >= 4) {
          return setRecentlyAdded([response.data.data[response.data.data.length - 1], response.data.data[response.data.data.length - 2], response.data.data[response.data.data.length - 3], response.data.data[response.data.data.length - 4]]);
        }
        else {
          return setRecentlyAdded(['']);
        }
      }
    }).catch(() => {
      setRecentlyAdded({ status: 'error', data: 'Failed to retrieve data about recently added invoices' });
      setServerError(prevError => {
        return prevError + '_RECENTLY_ADDED_ERROR_';
      });
    });
  }

  React.useEffect(() => {
    getRecentlyAddedInvoices(id, year);
  }, [year]);

  const deleteInvoice = (item_id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/invoices/removeInvoice`,
      data: {
        ID: item_id
      },
      ...backendModule.axiosConfig
    }).then(response => {
      if (response.data.status === "ok") {
        getAllInvoices(id);
      };
    }).catch(() => {

    });
  }

  const [questionModal, setQuestionModal] = React.useState(false);
  const [invoiceType, setInvoiceType] = React.useState(null);
  const makeThisIntoAnInvoice = (id, type) => {
    setQuestionModal(q => !q);
    setInvoiceID(id);
    setInvoiceType(type);
  }

  const cancelInvoice = (id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/invoices/cancelInvoice`,
      data: {
        ID: id
      },
      ...backendModule.axiosConfig
  }).then(res => {
      if(res.data.status == 'ok'){
        getAllInvoices();
      }
  }).catch(() => {

  });
  }

  const addInvoicesToTable = () => {
    if (invoicesData.data) {
      return invoicesData?.data.map((item) => {
        if (clientsData.data) {
          for (let i = 0; i < clientsData.data.length; i++) {
            if (item.ClientID == clientsData.data[i].ID) {
              return [
                { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{clientsData.data[i].Name}</h3><p>{item.ClientID}</p></div> },
                { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{moment(item.DueDate).toDate().toLocaleDateString()}</h3></div> },
                { keyID: String(item.ID), type: "custom", data: <div style={{ marginTop: '5px' }} id='table-company-due'>{JSON.parse(item.Calculation).map((d) => { return <h3>{Number(d.Due.replace(item.Currency, '')).toFixed(2)} {item.Currency}</h3> })}<p style={{ padding: '3px', background: 'rgba(254, 105, 105, 0.27)', color: 'black' }}>{item.Type}</p><Button style={{ display: item.Type === 'PRED.' ? 'block' : 'none', background: 'rgba(254, 105, 105, 0.27)' }} className='createInvButton' value='INVOICING' accent='rgba(254, 105, 105, 0.27)' onClick={() => makeThisIntoAnInvoice(item.ID, item.Type)} /></div> },
                {
                  keyID: String(item.ID), type: "groupNewline", group: [
                    { keyID: String(item.ID), type: "button", text: "View", onClick: e => { invoiceHandler(item.ID) } },
                    {
                      keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                        return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                          <p style={{ color: 'black' }}>Are you sure?</p>
                          <br></br>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Button style={{ marginRight: '10px' }} accent='rgba(254, 105, 105, 0.27)' value='YES' onClick={() => deleteInvoice(item.ID)} />
                            <Button accent='rgba(254, 105, 105, 0.27)' value='NO' onClick={c => { e() }} />
                          </div>
                        </div>);
                      }
                    },
                    {
                      keyID: String(item.ID), type: "button", text: item.Cancelled === true ? "Storned" : "Storn", triggerDropdown: true, triggerData: e => {
                        return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                          <p style={{ color: 'black' }}>Are you sure?</p>
                          <br></br>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Button style={{ marginRight: '10px' }} accent='rgba(254, 105, 105, 0.27)' value='YES' onClick={(c) => {cancelInvoice(item.ID); e()}} />
                            <Button accent='rgba(254, 105, 105, 0.27)' value='NO' onClick={c => { e() }} />
                          </div>
                        </div>);
                      }
                    },
                  ]
                }
              ];
            }
          }
        }
      });
    }
  }

  const [currencyData, setCurrencyData] = React.useState([]);

  const getAllCurrencies = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/currencies/getAllCurrencies`,
      data: {

      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        //!res.data.data.length > 0 ? setNoData(true) : setNoData(false);
        setCurrencyData(res.data);
      };
    }).catch(() => {
      setCurrencyData({ status: 'error', data: 'Currency data fetch failed' });
      return setServerError('_CURRENCY_ERROR_');
    });
  }

  React.useEffect(() => {
    getAllCurrencies();
  }, []);

  const totalDueRef = React.useRef(null);
  const sumTotalDue = () => {
    let sum = 0;
    if (invoicesData.data) {
      invoicesData.data?.map((invoice, key) => {
        if (currencyData.data) {
          currencyData.data?.map((currency, key) => {
            if (invoice.Currency === currency.Code) {
              if(invoice.isPaid === false && invoice.Cancelled === false){
                JSON.parse(invoice.Calculation).map((c) => {
                  sum = sum + (Number(c.Due.replace(currency.Code, '')) * Number(currency.ConversionToEUR));
                });
              }
            }
          });
        }
      });
      totalDueRef.current.innerText = sum.toFixed(2) + ' EUR';
    }
  }

  const totalPaidRef = React.useRef(null);
  const sumTotalPaid = () => {
    let sum = 0;
    if (invoicesData.data) {
      invoicesData?.data.map((invoice, key) => {
        if (currencyData.data) {
          currencyData.data?.map((currency, key) => {
            if (invoice.Currency === currency.Code) {
              if(invoice.isPaid === true && invoice.Cancelled === false){
                JSON.parse(invoice.Calculation).map((c) => {
                  sum = sum + (Number(c.Total.replace(currency.Code, '')) * Number(currency.ConversionToEUR));
                });
              }
            }
          });
        }
      });
      totalPaidRef.current.innerText = sum.toFixed(2) + ' EUR';
    }
  }

  const totalWithPDVref = React.useRef(null);
  const sumTotalWithPDV = () => {
    let sum = 0;
    if (invoicesData.data) {
      invoicesData?.data.map((invoice, key) => {
        if (currencyData.data) {
          currencyData.data?.map((currency, key) => {
            if (invoice.Currency === currency.Code) {
              sum = sum + (parseFloat(JSON.parse(invoice.Calculation)[0].TotalWithPDV.replace(currency.Code, ''))) * Number(currency.ConversionToEUR);
            }
          });
        }
      });
      totalWithPDVref.current.innerText = sum.toFixed(2) + ' EUR';
    }
  }

  React.useEffect(() => {
    if (!serverError != '') {
      sumTotalDue();
      sumTotalPaid();
      sumTotalWithPDV();
    }
  }, [invoicesData, currencyData]);

  const [dueListModal, setDueListModal] = React.useState(false);
  const dueListModalHandler = () => {
    setDueListModal(m => !m);
  }

  const [notification, setNotification] = React.useState(false);
  const notificationRef = React.useRef(null);
  const checkForNotifications = () => {
    if (!serverError.includes('INVOICES')) {
      if (invoicesData.data) {
        invoicesData.data?.map((item) => {
          if (moment(item.DueDate).format("D.MM.YYYY") == moment(date).format("D.MM.YYYY")) {
            setNotification(true);
          }
        })
      }
    }
  }

  React.useEffect(() => {
    checkForNotifications();
  }, [invoicesData]);

  return (
    <div className='component__dashboard'>

      {(() => {
        if (invoiceShow === true) {
          return <Invoice pdfDownloadSpinner={props.pdfDownloadSpinner} currencies={currencyData} id={invoiceID} getAll={getAllInvoices} year={year} onClose={invoiceHandler} loggedUser={props.loggedUser} />
        }
      })()}

      {(() => {
        if (createNewInvoice === true) {
          return <CreateNewInvoice loadData={getAllInvoices} data={invoicesData} loggedUser={props.loggedUser} onClose={createNewInvoiceHandler} />
        }
      })()}

      {(() => {
        if (dueListModal === true) {
          return <DueListModal year={year} handler={dueListModalHandler} clients={clientsData} />
        }
      })()}

      <p style={{ display: serverError.includes('CURRENCY') ? 'block' : 'none', color: 'red', fontFamily: 'Roboto', textAlign: 'center' }}>{noData ? 'No data to display' : ''}{serverError.includes('CURRENCY') ? currencyData.data : ''}</p>
      <Spinner style={{ display: serverError.includes('CURRENCY') ? 'block' : 'none', width: "64px", height: "64px" }} color="rgba(254, 105, 105, 0.27)" align="center" />

      <div style={{ display: serverError.includes('CURRENCY') ? 'none' : 'block' }} className='component__dashboard__container'>
        <div className='component__dashboard__container__head'>
          <h1>Invoices</h1>
          <div id='invoice-item' className='component__dashboard__container__head__dropdown'>
            <div className='component__dashboard__container__head__dropdown-header' onClick={toggleDropdown}>
              {selectedItem ? items.find(item => item.id == selectedItem).label : cur_year}
              <img src='images/arrowDown.png' />
            </div>
            <div className={`component__dashboard__container__head__dropdown-body ${isOpen && 'open'}`}>
              {items.map(item => (
                <div className="component__dashboard__container__head__dropdown-item" onClick={e => handleItemClick(e.target.id)} id={item.id}>
                  <span className={`component__dashboard__container__head__dropdown-item-dot ${item.id == selectedItem && 'selected'}`}>• </span>
                  {item.label}
                </div>
              ))}
            </div>
          </div>
          <Button className='newInvoice' accent='#67E564' value='New Invoice' onClick={() => createNewInvoiceHandler()} />
        </div>
        <br></br>
        <hr></hr>

        <div className='component__dashboard__container__stats'>
          <div className='component__dashboard__container__stats__item' onClick={() => dueListModalHandler()}>
            <p id='notification' style={{ display: notification ? 'block' : 'none', color: 'red', fontSize: '30px' }} ref={notificationRef}>&#8226;</p>
            <h1 ref={totalDueRef} id='owerdue'></h1>
            <p>Due</p>
          </div>
          <div className='component__dashboard__container__stats__item'>
            <h1 ref={totalPaidRef} id='owerdue'></h1>
            <p>Paid</p>
          </div>
          <div className='component__dashboard__container__stats__item'>
            <h1 ref={totalWithPDVref} id='owerdue'></h1>
            <p>Total with PDV</p>
          </div>
        </div>

        <div style={{ display: noData || serverError.includes('RECENTLY') || serverError.includes('CLIENTS') ? 'block' : 'grid' }} className='component__dashboard__container__invoicesList'>
          {
            invoicesData.data ?
              !noData ?
                recentlyAdded.length ?
                  recentlyAdded?.map((item, key) => {
                    if (clientsData.data) {
                      for (let i = 0; i < clientsData.data.length; i++) {
                        if (item.ClientID == clientsData.data[i].ID) {
                          return < NewInvoiceBlock
                            type={item.Type} id={item.ID}
                            companyName={clientsData.data[i].Name}
                            date={moment(item.DateOfIssue).toDate().toLocaleDateString()}
                            amount={JSON.parse(item.Calculation).map((d) => {
                                      return Number(d.Due.replace(item.Currency, '')).toFixed(2) + ' ' + item.Currency
                                    })
                                   }
                            onClick={(e) => invoiceHandler(item.ID)}
                          />
                        }
                      }
                    }
                  })
                  : ''
                : ''
              : ''
          }
          <p style={{ display: noData || serverError != '' ? 'block' : 'none', color: 'red', fontFamily: 'Roboto', textAlign: 'center' }}>{noData ? 'No data to display' : ''}{serverError.includes('CLIENTS') ? clientsData.data : ''}{serverError.includes('RECENTLY') ? recentlyAdded.data : ''}</p>
          <Spinner style={{ display: serverError.includes('RECENTLY') || serverError.includes('CLIENTS') ? 'block' : 'none', width: "64px", height: "64px" }} color="rgba(254, 105, 105, 0.27)" align="center" />
        </div>

        <div className='component__dashboard__container__allInvoicesList'>
          <div className='component__dashboard__container__allInvoicesList__head'>
            <h1>All invoices</h1>
          </div>

          <div className='component__dashborad__container__allInvoicesList__body'>
            <p style={{ display: noData || serverError.includes('INVOICES') ? 'block' : 'none', color: 'red', fontFamily: 'Roboto', textAlign: 'center' }}>{noData ? 'No data to display' : ''} {serverError.includes('INVOICES') ? invoicesData.data : ''}</p>
            <Spinner style={{ display: serverError.includes('INVOICES') ? 'block' : 'none', width: "64px", height: "64px" }} color="rgba(254, 105, 105, 0.27)" align="center" />
            {(() => {
              if (questionModal === true) {
                return <QuestionModal loadData={getAllInvoices} id={invoiceID} handler={makeThisIntoAnInvoice} type={invoiceType} />
              }
            })()}
            {
              !serverError.includes('INVOICES') && !serverError.includes('CLIENTS') ?
                <FilteredCustomTable
                  id='table'
                  accent='rgba(254, 105, 105, 0.27)'
                  theme='light'
                  headers={['Company name', 'Due date', 'Due amount']}
                  filters={[
                    { name: "ClientID", friendlyName: "Company name", type: "string" },
                    { name: "InvoiceNumber", friendlyName: "Number of invoice", type: "number" },
                    { name: "DueDate", friendlyName: "Due date", type: "date" },
                    { name: "Cancelled", friendlyName: "Storn", type: "boolean" }
                  ]}
                  filterCB={f => {
                    clientsData.data?.map((item) => {
                      if ((String(item.Name).toUpperCase()).includes(String(f[0]?.value).toUpperCase())) {
                        f[0].value = item.ID;
                        setFilters(f);
                      }
                      else {
                        setFilters(f);
                      }
                    });
                  }}
                  data={!serverError.includes('INVOICES') && !noData ? addInvoicesToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                />
                : ''
            }
          </div>
        </div>
      </div>
    </div>
  )
}
