import React from 'react';
import "./index.scss";
import Button from "../customComponents/Button";
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import Spinner from '../customComponents/Spinner';
import { useParams } from 'react-router-dom';
import { FilteredCustomTable } from "../customComponents/Table";
import moment from 'moment';

export default function Team(props) {
  const { id } = useParams();
  const [users, setUsers] = React.useState([]);
  const [business, setBusiness] = React.useState([]);
  const [noData, setNoData] = React.useState(false);
  const [serverError, setServerError] = React.useState('');
  const [filters, setFilters] = React.useState([]);

  const getAllusers = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getAllusers`,
      data: {
        filters: []
      },
      ...backendModule.axiosConfig
    }).then(res => {
      //res.data.data.length = 0; 
      !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
      return setUsers(res.data);
    }).catch(() => {
      setUsers({ status: 'error', data: 'Data fetch failed' });
      setServerError(prevError => {
        return prevError + '_ALL_USERS_ERROR_';
      });
    });
  }

  React.useEffect(() => {
    getAllusers();
  }, []);

  const [isOwner, setIsOwner] = React.useState(false);
  React.useEffect(() => {
    if (business[0]) {
      users.data?.map((user) => {
        if (JSON.parse(business[0]?.AllowedUsers).includes(user.ID)) {
          if (user.ID === props.loggedUserID) {
            setIsOwner(user.Owner);
          }
        }
      });
    }
  }, [users, business]);

  const getAllBusinesses = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/business/getAllBusinesses`,
      data: {

      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        res.data.data.length == 0 ? setNoData(true) : setNoData(false);
        res.data.data.map((item, index) => {
          if (JSON.parse(item.AllowedUsers).includes(props.loggedUserID)) {
            setBusiness([res.data.data[index]]);
          }
        });
      }
    }).catch(() => {
      setNoData(true);
    });
  }

  React.useEffect(() => {
    getAllBusinesses();
  }, []);


  const [addUserModal, setAddUserModal] = React.useState(false);
  const addUserModalHandler = () => {
    setAddUserModal(m => !m);
  }

  const nameRef = React.useRef(null);
  const usernameRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const emailRef = React.useRef(null);
  const msgRef = React.useRef(null);

  const addUser = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/createNewUser`,
      data: {
        name: nameRef.current.value,
        username: usernameRef.current.value,
        password: passwordRef.current.value,
        email: emailRef.current.value,
        flags: { isAdmin: true },
        owner: false,
      },
      ...backendModule.axiosConfig
    }).then(usr => {
      if (usr.data.status === "ok") {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/business/updateAllowedUsers`,
          data: {
            ID: id,
            AllowedUsers: JSON.parse(business[0].AllowedUsers).concat([usr.data.data])
          },
          ...backendModule.axiosConfig
        }).then(res => {
          if (res.data.status === "ok") {
            msgRef.current.innerText = 'User created successfully!';
            msgRef.current.style.color = '#67E564';
            setTimeout(() => {
              addUserModalHandler();
              getAllusers();
              getAllBusinesses();
            }, 1500);
          }
        }).catch(() => {

        });
      }
      else {
        msgRef.current.innerText = usr.data.data;
        msgRef.current.style.color = 'red';
        setTimeout(() => {
          msgRef.current.innerText = '';
        }, 3000);
      }
    }).catch(() => {

    });
  }

  const deleteUser = (uuid) => {
    let aus = JSON.parse(business[0].AllowedUsers)
    aus.map((item, index) => {
      if (item === uuid) {
        aus.splice(index, 1);
      }
    });

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/business/updateAllowedUsers`,
      data: {
        ID: id,
        AllowedUsers: aus
      },
      ...backendModule.axiosConfig
    }).then(res => {
      getAllusers();
      getAllBusinesses();
    }).catch(() => {

    });
  }

  const addUsersToTable = () => {
    if (users.data && business[0]) {
      return users?.data.map((item) => {
        if (JSON.parse(business[0]?.AllowedUsers).includes(item.ID)) {
          return [
            { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.ID}</h3></div> },
            { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.Username}</h3></div> },
            { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.Email}</h3></div> },
            {
              keyID: String(item.ID), type: "groupNewline", group: [
                !item.Owner && isOwner ?
                  {
                    keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                      return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                        <p style={{ color: 'black' }}>Are you sure?</p>
                        <br></br>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Button style={{ marginRight: '10px' }} accent='rgb(157, 195, 241)' value='YES' onClick={() => deleteUser(item.ID)} />
                          <Button accent='rgb(157, 195, 241)' value='NO' onClick={c => { e() }} />
                        </div>
                      </div>);
                    }
                  }
                  : ''
              ]
            }
          ];
        }
        else {
          return null;
        }
      }).filter(t => t);
    }
    else {
      return null;
    }
  }

  return (
    <div className='component__team'>
      <div style={{ display: addUserModal ? 'flex' : 'none' }} className='component__team__addUserModal'>
        <div className='component__team__addUserModal__box'>
          <h1>Add new user to your team</h1>
          <input ref={nameRef} type='text' placeholder='Name' />
          <input ref={usernameRef} type='text' placeholder='Username' />
          <input ref={passwordRef} type='password' placeholder='Password' />
          <input ref={emailRef} type='email' placeholder='Email' />
          <div>
            <Button accent='#67E564' value='Cancel' className='invite-btn' onClick={() => addUserModalHandler()} />
            <Button accent='#67E564' value='Add' className='invite-btn' onClick={() => addUser()} />
          </div>
          <br></br>
          <p id='msg' ref={msgRef}></p>
        </div>
      </div>
      <div className='component__team__container'>
        <div className='component__team__container__head'>
          <h1>My Team</h1>
          <div style={{ display: isOwner ? 'block' : 'none' }}>
            <Button accent='#67E564' value='Add User' className='invite-btn' onClick={() => addUserModalHandler()} />
          </div>
        </div>
        <br></br>
        <hr></hr>
        <br></br>
        <div className='component__team__container__allMembers'>
          <div className='component__team__container__allMembers__head'>
            <h2>All Team members</h2>
          </div>
          <br></br>
          <FilteredCustomTable
            id='table'
            accent='rgb(157 195 241)'
            theme='light'
            headers={['ID', 'Username', 'Email']}
            filters={[
              { name: "Name", friendlyName: "Name", type: "string" },
            ]}
            filterCB={f => setFilters(f)}

            data={!noData ? addUsersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
          />
          <p style={{ display: noData ? 'block' : 'none', fontFamily: 'Roboto', color: 'red' }}>No data to display</p>
          {
            serverError.includes('USERS') ?
              <Spinner width='64px' height='64px' align='center' color='#67E564' />
              : ''
          }
        </div>
      </div>
    </div>
  )
}
