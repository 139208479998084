import {HashRouter as Router, Routes, Route} from "react-router-dom";
import { useSelector } from "react-redux";

import Header from "./components/Header";
import Login from "./routes/Login";
import ContentWrapper from "./components/ContentWrapper";
import Spinner from "./components/customComponents/Spinner";

import ChooseBusiness from "./routes/ChooseBusiness";
import LandingPage from "./routes/LandingPage";

import * as backendModule from "../src/modules/backendModule";
import axios from "axios";
import React from "react";

const App = () => {

  const timestampSelector = useSelector(state => state.timestamp);
  const [logged, setLogged] = React.useState(null);
  const [loggedUser, setLoggedUser] = React.useState();
  const [loggedUserID, setLoggedUserID] = React.useState();

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setLogged(true);
        setLoggedUser(res.data.data.UserInfo.Name);
        setLoggedUserID(res.data.data.UserInfo.ID);
      }
      else{
        setLogged(false);
      }
    }).catch(() => {
      setLogged(false);
    });
  };

  React.useEffect(() => {
    checkLogin();
  }, [timestampSelector]);

  if (logged === null) return <Spinner style={{width: "64px", height: "64px"}} color="#3F73FF" align="center" />
  return <Router>
    <ContentWrapper>
      <Routes>

        {logged === true ? <>
          <Route path="/" element={<ChooseBusiness loggedUser={loggedUser} loggedUserID = {loggedUserID} />} />
          <Route path="/business/:id" element={<LandingPage loggedUser={loggedUser} loggedUserID = {loggedUserID} />} />
        </> : <>
          <Route path="*" element={<Login />} />
        </>}
        
      </Routes>
    </ContentWrapper>
  </Router>
};

export default App;
