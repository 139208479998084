import React from 'react';
import "./index.scss";
import Button from "../customComponents/Button";
import Dropdown from "../customComponents/Dropdown";
import ClientProfile from "../ClientProfile";
import { FilteredCustomTable } from "../customComponents/Table";
import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import Spinner from '../customComponents/Spinner';
import EditClient from '../EditClient';
import TableDropdown from '../TableDropdown';
import FilterPanel from '../customComponents/FilterPanel';

export default function Clients() {

  const newClientRef = React.useRef(null);
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" })
  }

  const [filters, setFilters] = React.useState([]);

  const [newClient, setNewClient] = React.useState('Add new client');

  const newClientHandler = () => {
    if (newClient === 'Add new client') {
      setNewClient('Cancel');
      scrollToRef(newClientRef);
    }
    else {
      setNewClient('Add new client');
    }
  }
  const [editInfo, setEditInfo] = React.useState(null);
  const editHandler = (item) => {
    setEditInfo(item);
  }

  const changeInformations = (fieldRef, inputRef) => {
    inputRef.current.addEventListener('change', () => {
      fieldRef.current.innerText = inputRef.current.value;
    });

    inputRef.current.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        if (inputRef.current.value === '') {
          setEditInfo(null);
        }
        else {
          fieldRef.current.innerText = inputRef.current.value;
          setEditInfo(null);
        }
      }
      else {
        fieldRef.current.innerText = inputRef.current.value;
      }
    });
  }

  const nameRef = React.useRef(null);
  const addressRef = React.useRef(null);
  const cityRef = React.useRef(null);
  const pdvRef = React.useRef(null);
  const idnumRef = React.useRef(null);
  const mailRef = React.useRef(null);
  const phoneRef = React.useRef(null);
  const countryRef = React.useRef(null);
  const nameFieldRef = React.useRef(null);
  const addressFieldRef = React.useRef(null);
  const cityFieldRef = React.useRef(null);
  const pdvFieldRef = React.useRef(null);
  const idnumFieldRef = React.useRef(null);
  const mailFieldRef = React.useRef(null);
  const phoneFieldRef = React.useRef(null);
  const countryFieldRef = React.useRef(null);
  const createClientMsgRef = React.useRef(null);
  const errorMsgRef = React.useRef(null);

  const addClient = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/customers/addCustomer`,
      data: {
        Name: nameRef.current.value,
        Address: addressRef.current.value,
        City: cityRef.current.value,
        Country: countryRef.current.value,
        IDNumber: idnumRef.current.value,
        PDVNumber: pdvRef.current.value,
        Email: mailRef.current.value,
        PhoneNumber: phoneRef.current.value,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        getAllClients();
      }
      if (res.data.status === "error") {
        errorMsgRef.current.style.display = 'block';
        errorMsgRef.current.innerText = 'Info missing';
        return setTimeout(() => {
          errorMsgRef.current.style.display = 'none';
        }, 1500);
      }
    }).catch(() => {

    });
    if (nameRef.current.value && addressRef.current.value && cityRef.current.value && countryRef.current.value && pdvRef.current.value && mailRef.current.value) {
      createClientMsgRef.current.innerText = 'New client successfully created';
      setTimeout(() => {
        newClientHandler();
      }, 2500)
    }
  }

  const [customersData, setCustomersData] = React.useState();
  const [noData, setNoData] = React.useState(false);
  const [serverError, setServerError] = React.useState('');

  const getAllClients = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/customers/getAllCustomers`,
      data: {
        filters: filters
      },
      ...backendModule.axiosConfig
    }).then(res => {
      !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
      return setCustomersData(res.data);
    }).catch(() => {
      setCustomersData({ status: 'error', data: 'Data fetch failed' });
      setServerError(prevError => {
        return prevError + 'ALL_CLIENTS_ERROR';
      });
    });
  }

  const [recentlyAdded, setRecentlyAdded] = React.useState([]);

  const getRecentlyAddedCustomers = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/customers/getAllCustomers`,
      data: {
        filters: filters
      },
      ...backendModule.axiosConfig
    }).then(response => {
      if (!response.data.data.length > 0) {
        return setNoData(true);
      }
      else {
        setNoData(false);
        return setRecentlyAdded([response.data.data[response.data.data.length - 1], response.data.data[response.data.data.length - 2], response.data.data[response.data.data.length - 3], response.data.data[response.data.data.length - 4]]);
      }
    }).catch(() => {
      setRecentlyAdded({ status: 'error', data: 'Failed to retrieve data about recently added clients' });
      setServerError(prevError => {
        return prevError + 'RECENTLY_ADDED_ERROR';
      });
    });
  }

  React.useEffect(() => {
    getRecentlyAddedCustomers();
  }, []);

  React.useEffect(() => {
    getAllClients();
  }, [filters]);

  const addClientToTable = () => {
    if (customersData) {
      return customersData?.data.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.Name}</h3><p>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.Email}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.PhoneNumber}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.City}</h3><p>{item.Country}</p></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "button", text: "Edit", onClick: e => { editClientHandler(item.ID) } },
              {
                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {

                  return <TableDropdown customersData={customersData} id={item.ID} e={e} getAllClients={getAllClients} />
                }
              },
            ]
          },
        ];
      });
    }
  }


  const [editClient, setEditClient] = React.useState(false);
  const [customerDataForEdit, setCustomerDataForEdit] = React.useState([]);
  const editClientHandler = (id) => {
    setEditClient(e => !e);
    customersData?.data.map((item, index) => {
      if (item.ID === id) {
        setCustomerDataForEdit([item.Name, item.Email, item.PhoneNumber, item.Address, item.City, item.PDVNumber, item.IDNumber, item.Country, item.ID, index]);
      }
    });
  }

  return (
    <div className='component__clients'>
      <div className='component__clients__container'>
        <div className='component__clients__container__head'>
          <h1>Clients</h1>
          <Button onClick={() => newClientHandler()} className='new-client-btn' accent='#67E564' value={newClient} />
        </div>
        <br></br>
        <hr></hr>
        <br></br>
        {(() => {
          if (newClient === 'Cancel') {
            return <div ref={newClientRef} className='component__clients__container__createNewClient'>
              <div ref={createClientMsgRef} className='createNewClient__msg'>
                <div ref={errorMsgRef} className='createNewClient__errormsg' />
              </div>
              <div className='component__clients__container__createNewClient__box'>
                <div className='component__clients__container__createNewClient__box__contactInformations'>
                  <h1>Contact Informations</h1>
                  <br></br>
                  <hr></hr>
                  <br></br>
                  <p>Name</p>
                  <span><strong style={{ display: editInfo === 'name' ? 'none' : 'flex' }} id='client-name' ref={nameFieldRef}>Some Name</strong><input ref={nameRef} id='name-input' onChange={() => changeInformations(nameFieldRef, nameRef)} style={{ display: editInfo === 'name' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('name')} src='images/editPen.png' alt='' /></span>
                  <p>Email</p>
                  <span><strong style={{ display: editInfo === 'mail' ? 'none' : 'flex' }} id='client-mail' ref={mailFieldRef}>some@gmail.com</strong><input ref={mailRef} id='mail-input' onChange={() => changeInformations(mailFieldRef, mailRef)} style={{ display: editInfo === 'mail' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('mail')} src='images/editPen.png' alt='' /></span>
                  <p>Phone</p>
                  <span><strong style={{ display: editInfo === 'phone' ? 'none' : 'flex' }} id='client-phone' ref={phoneFieldRef}>+3876214XXXX</strong><input ref={phoneRef} id='phone-input' onChange={() => changeInformations(phoneFieldRef, phoneRef)} style={{ display: editInfo === 'phone' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('phone')} src='images/editPen.png' alt='' /></span>
                  <p>Address</p>
                  <span><strong style={{ display: editInfo === 'address' ? 'none' : 'flex' }} id='client-address' ref={addressFieldRef}>Some address</strong><input ref={addressRef} id='address-input' onChange={() => changeInformations(addressFieldRef, addressRef)} style={{ display: editInfo === 'address' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('address')} src='images/editPen.png' alt='' /></span>
                  <p>City</p>
                  <span><strong style={{ display: editInfo === 'city' ? 'none' : 'flex' }} id='client-city' ref={cityFieldRef}>Some city</strong><input ref={cityRef} id='city-input' onChange={() => changeInformations(cityFieldRef, cityRef)} style={{ display: editInfo === 'city' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('city')} src='images/editPen.png' alt='' /></span>
                  <p>PDV Number</p>
                  <span><strong style={{ display: editInfo === 'pdv' ? 'none' : 'flex' }} id='client-pdv' ref={pdvFieldRef}>78XXXXXXX</strong><input ref={pdvRef} id='pdv-input' onChange={() => changeInformations(pdvFieldRef, pdvRef)} style={{ display: editInfo === 'pdv' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('pdv')} src='images/editPen.png' alt='' /></span>
                  <p>ID Number</p>
                  <span><strong style={{ display: editInfo === 'idnum' ? 'none' : 'flex' }} id='client-idnum' ref={idnumFieldRef}>24XXXXXXX</strong><input ref={idnumRef} id='idnum-input' onChange={() => changeInformations(idnumFieldRef, idnumRef)} style={{ display: editInfo === 'idnum' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('idnum')} src='images/editPen.png' alt='' /></span>
                  <p>Country</p>
                  <span><strong style={{ display: editInfo === 'country' ? 'none' : 'flex' }} id='client-country' ref={countryFieldRef}>Some country</strong><input ref={countryRef} id='country-input' onChange={() => changeInformations(countryFieldRef, countryRef)} style={{ display: editInfo === 'country' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('country')} src='images/editPen.png' alt='' /></span>
                  <br></br>
                  <Button onClick={() => addClient()} accent='rgb(103, 229, 100)' value='Add' />
                </div>
              </div>
            </div>
          }
          if (editClient === true) {
            return <EditClient data={[customersData, setCustomersData]} handler={editClientHandler} name={customerDataForEdit[0]} email={customerDataForEdit[1]} phone={customerDataForEdit[2]} address={customerDataForEdit[3]} city={customerDataForEdit[4]} pdv={customerDataForEdit[5]} idNumber={customerDataForEdit[6]} country={customerDataForEdit[7]} id={customerDataForEdit[8]} index={customersData[8]} />
          }
        })()}

        <br></br>
        <div className='component__clients__container__recentlyActive'>
          <h1>Recently Added</h1>
          <div className='component__clients__container__recentlyActive__clientBox'>
            {
              !serverError.includes('RECENTLY_ADDED_ERROR') ?
                customersData?.data.length > 4 ?
                  recentlyAdded?.map((item, index) => {
                    if (index < 4) {
                      return <ClientProfile background='#D9E3FF' letters={item.Name.slice(0, 2)} name={item.Name} company={item.Name} phone={item.PhoneNumber} mail={item.Email} />
                    }
                    else {
                      return null;
                    }
                  })
                  : ''
                : ''
            }

          </div>
        </div>
        <br></br>
        <p style={{ display: noData || serverError ? 'block' : 'none', fontFamily: 'Roboto', fontSize: '18px', color: 'red' }}>{noData ? 'No data to display' : ''}{serverError.includes('ALL_CLIENTS_ERROR') ? customersData.data : recentlyAdded.data}</p>
        <Spinner style={{ display: serverError ? 'block' : 'none', width: "64px", height: "64px" }} color="rgb(157, 195, 241)" align="center" />
        <br></br>
        <div style={{ display: serverError.includes('CLIENTS') ? 'none' : 'block' }} className='component__clients__container__allClients'>
          <FilteredCustomTable
            id='table'
            accent='rgb(157 195 241)'
            theme='light'
            headers={['Company name', 'Email', 'Phone', 'City']}
            filters={[
              { name: "Name", friendlyName: "Name", type: "string" },
              { name: "Country", friendlyName: "Country", type: "string" }
            ]}
            /*filterCB={(arg)=>{
              customersData?.data.map((item)=>{
                if(item.ID != arg[0].value){
                  
                }
              })
            }}*/
            filterCB={f => setFilters(f)}

            data={!serverError.includes('ALL_CLIENTS_ERROR') && !noData ? addClientToTable() : [[{keyID: 'noData', type:'custom', data: <p>No data to display</p>}]]}
          />
        </div>
      </div>
    </div>
  )
}
