import React from 'react';
import "./index.scss";

export default function ClientProfile(props) {

    const clientNameRef = React.useRef(null);
    const companyNameRef = React.useRef(null);
    const checkLength = () => {
        if(props.name.length > 13){
            clientNameRef.current.style.fontSize = '11px';
        }
        if(props.company.length > 13){
            companyNameRef.current.style.fontSize = '11px';
        }
    }

    React.useEffect(()=>{
        checkLength();
    }, []);

    return (
        <div onClick={props.onClick} className='component__clientProfile'>
            <div style={{background: props.background}} id='blank'></div>
            <div className='component__clientProfile__head'>
                <p id='pimg'>{props.letters}</p>
                <div>
                    <strong ref={clientNameRef} id='client-name'>{props.name}</strong>
                    <p ref={companyNameRef} id='client-company-name'>{props.company}</p>
                </div>
            </div>
            <div className='component__clientProfile__body'>
                <span><img src='images/smartphone 1.png' alt='' /><p>{props.phone}</p></span>
                <span><img src='images/envelope-alt.png' alt='' /><p>{props.mail}</p></span>
            </div>
        </div>
    )
}
