import React from 'react';
import "./index.scss";
import Button from '../customComponents/Button';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";

export default function EditArticle(props) {

    const editArticleRef = React.useRef(null);
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }
    React.useEffect(()=>{
        scrollToRef(editArticleRef);
    }, []);


    const nameRef = React.useRef(null);
    const priceRef = React.useRef(null);
    const mpcRef = React.useRef(null);
    const nameFieldRef = React.useRef(null);
    const priceFieldRef = React.useRef(null);
    const mpcFieldRef = React.useRef(null);
    const quantityFieldRef = React.useRef(null);
    const quantityRef = React.useRef(null);
  
    const [editInfo, setEditInfo] = React.useState(null);
    const editHandler = (item) => {
      setEditInfo(item);
    }
  
    const changeInformations = (fieldRef, inputRef) => {
      inputRef.current.addEventListener('change', () => {
        fieldRef.current.innerText = inputRef.current.value;
      });
  
      inputRef.current.addEventListener('keypress', (e) => {
        if (e.key === 'Enter') {
          if (inputRef.current.value === '') {
            setEditInfo(null);
          }
          else {
            fieldRef.current.innerText = inputRef.current.value;
            setEditInfo(null);
          }
        }
        else {
          fieldRef.current.innerText = inputRef.current.value;
        }
      });
    }

    const [saveMsg, setSaveMsg] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(false);

    const saveEditedArticle = (id, handler) => {
        if (isNaN(priceRef.current.value)) {
            return setErrorMsg(true);
        }

        const body = {
            ID: id,
            Name: nameRef.current.value,
            Price: priceRef.current.value,
            MPC: mpcRef.current.value,
            Quantity: quantityRef.current.value
        };
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/editArticle`,
            data: body,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setSaveMsg(true);
                props.data[0]?.data.map((item, index) => {
                    if (item.ID === id) {
                        item.Name = nameRef.current.value;
                        item.Price = priceRef.current.value;
                        item.MPC = mpcRef.current.value;
                        item.Quantity = quantityRef.current.value;
                    }
                });
            }
        }).catch(() => {

        });

        setTimeout(() => {
            handler();
        }, 1500);
    }

    return (
        <div ref={editArticleRef}>
            <p id='edit-article-msg' style={{ display: saveMsg ? 'block' : 'none' }}>Article saved successfully</p>
            <p id='error-article-msg' style={{ display: errorMsg ? 'block' : 'none' }}>Price must be a number</p>
            <div className='component__editArticle'>
                <h1>Edit article</h1>
                <br></br>
                <hr></hr>
                <br></br>
                <p>Name</p>
                <span><strong style={{ display: editInfo === 'name' ? 'none' : 'flex' }} id='article-name' ref={nameFieldRef}>{props.Name}</strong><input ref={nameRef} defaultValue={props.Name} id='name-input' onChange={() => changeInformations(nameFieldRef, nameRef)} style={{ display: editInfo === 'name' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('name')} src='images/editPen.png' alt='' /></span>
                <p>Price</p>
                <span><strong style={{ display: editInfo === 'price' ? 'none' : 'flex' }} id='article-price' ref={priceFieldRef}>{props.Price}</strong><input ref={priceRef} defaultValue={props.Price} id='price-input' onChange={() => changeInformations(priceFieldRef, priceRef)} style={{ display: editInfo === 'price' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('price')} src='images/editPen.png' alt='' /></span>
                <p>Unit of measure</p>
                <span><strong style={{ display: editInfo === 'mpc' ? 'none' : 'flex' }} id='article-mpc' ref={mpcFieldRef}>{props.MPC}</strong><input ref={mpcRef} defaultValue={props.MPC} id='mpc-input' onChange={() => changeInformations(mpcFieldRef, mpcRef)} style={{ display: editInfo === 'mpc' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('mpc')} src='images/editPen.png' alt='' /></span>
                <p>Quantity</p>
                <span><strong style={{ display: editInfo === 'quantity' ? 'none' : 'flex' }} id='article-quantity' ref={quantityFieldRef}>{props.Quantity != null ? props.Quantity : 'NaN'}</strong><input ref={quantityRef} defaultValue={props.Quantity} id='quantity-input' onChange={() => changeInformations(quantityFieldRef, quantityRef)} style={{ display: editInfo === 'quantity' ? 'flex' : 'none' }} type='text' /><img onClick={() => editHandler('quantity')} src='images/editPen.png' alt='' /></span>
                <br></br>
                <div className='component__editArticle__controles'>
                    <Button onClick={() => saveEditedArticle(props.id, props.handler)} accent='rgb(103, 229, 100)' value='Save' />
                    <Button onClick={props.handler} accent='rgb(103, 229, 100)' value='Cancel' />
                </div>
            </div>
        </div>
    )
}
