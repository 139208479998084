import React, { memo } from 'react';
import "./index.scss";
import Button from "../../components/customComponents/Button";
import { FilteredCustomTable } from '../customComponents/Table';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import { useParams } from 'react-router-dom';
import Spinner from '../customComponents/Spinner';

export default function Memorandum(props) {

  const { id } = useParams();
  const [filters, setFilters] = React.useState([]);
  const [noData, setNoData] = React.useState(false);
  const [serverError, setServerError] = React.useState('');


  const pdfDownloadHandler = (id) => {
    props.pdfDownloadSpinner();
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/memorandums/createPDF`,
      data: {
        MemorandumID: id
      },
      //responseType: 'arraybuffer',
      responseType: "blob",
      ...backendModule.axiosConfig
    }).then(res => {
      const href = URL.createObjectURL(res.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);

    }).catch((e) => {
      console.warn(e);
    }).finally(() => {
      props.pdfDownloadSpinner();
    });
  }

  const [newMemorandum, setNewMemorandum] = React.useState(false);
  const memorandumModalHandler = () => {
    setNewMemorandum(m => !m);
  }

  const textareaRef = React.useRef(null);
  const [memorandums, setMemorandums] = React.useState([]);
  const msgRef = React.useRef(null);

  const saveMemorandum = (id) => {
    msgRef.current.innerText = 'Succesfully saved memorandum!';
    msgRef.current.style.background = '#67E564';

    if (!textareaRef.current.value || !selectedLogo) {
      msgRef.current.style.background = 'red';
      msgRef.current.innerText = 'Info missing';
      return setTimeout(() => {
        msgRef.current.style.display = 'none';
      }, 1500);
    }

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/memorandums/addMemorandum`,
      data: {
        Text: textareaRef.current.value,
        BusinessID: id,
        LogoURL: selectedLogo
      },
      ...backendModule.axiosConfig
    }).then(res => {
      getAllMemorandums(id);
      msgRef.current.style.display = 'block';
      setTimeout(() => {
        msgRef.current.style.display = 'none';
        memorandumModalHandler();
      }, 1500);
    }).catch(() => {
      /*setInvoicesData({ status: 'error', data: 'Data fetch failed' });
      setServerError(prevError => {
        return prevError + '_ALL_INVOICES_ERROR_';
      });*/
    });
  }

  const getAllMemorandums = (id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/memorandums/getAllMemorandums`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id }
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      //res.data.data.length = 0; 
      if (res.data.status === 'error') {

      }
      !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
      return setMemorandums(res.data);
    }).catch(() => {
      setMemorandums({ status: 'error', data: 'Data fetch failed' });
      setServerError(prevError => {
        return prevError + '_ALL_MEMORANDUMS_ERROR_';
      });
    });
  }

  React.useEffect(() => {
    getAllMemorandums(id);
  }, [filters]);

  const deleteMemorandum = (item_id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/memorandums/removeMemorandum`,
      data: {
        ID: item_id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        getAllMemorandums(id);
      };
    }).catch(() => {
    });
  }

  const [edit, setEdit] = React.useState(false);
  const [memorandumForEdit, setMemorandumForEdit] = React.useState([]);
  const editMemorandumHandler = (id) => {
    setEdit(e => !e);
    memorandums.data?.map((item) => {
      if (item.ID === id) {
        setMemorandumForEdit(item);
      }
    });
  }

  React.useEffect(() => {
    if (edit) {
      document.querySelector('.component__memorandum__head').classList.add('active');
    }
    else {
      document.querySelector('.component__memorandum__head').classList.remove('active');
    }
  }, [edit]);

  const [eTxt, setEtxt] = React.useState(false);
  const eImgRef = React.useRef(null);
  const textRef = React.useRef(null);
  const editTextareaRef = React.useRef(null);
  const editMemorandum = () => {
    setEtxt(t => !t);
    if (eTxt == false) {
      eImgRef.current.src = 'images/tick.png';
      eImgRef.current.style.background = 'rgb(103, 229, 100)';
      editTextareaRef.current.value = textRef.current.textContent;
    }
    else {
      eImgRef.current.src = 'images/editPen.png';
      eImgRef.current.style.background = 'transparent';
      textRef.current.innerText = editTextareaRef.current.value;
    }
  }

  const saveEditedMemorandum = (id) => {

    msgRef.current.innerText = 'Succesfully edited memorandum!';
    msgRef.current.style.background = '#67E564';

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/memorandums/editMemorandum`,
      data: {
        ID: id,
        Text: textRef.current.textContent
      },
      ...backendModule.axiosConfig
    }).then(res => {
      msgRef.current.style.display = 'block';
      setTimeout(() => {
        msgRef.current.style.display = 'none';
        editMemorandumHandler();
      }, 1500);
    }).catch(() => {
    });
  }

  const closeView = () => {
    setEdit(e => !e);
    setEtxt(false);
    eImgRef.current.src = 'images/editPen.png';
    eImgRef.current.style.background = 'transparent';
  }

  const addMemorandumToTable = () => {
    if (memorandums.data) {
      return memorandums?.data.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div id='table-memorandums-info' ><h3>{item.ID}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-memorandums-info'><h3>{item.Text.slice(0, 30)}...</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-memorandums-info'><h3>{item.BusinessID}</h3></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "button", text: "View", onClick: e => { editMemorandumHandler(item.ID) } },
              {
                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                  return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: 'black' }}>Are you sure?</p>
                    <br></br>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button style={{ marginRight: '10px' }} accent='rgb(157, 195, 241)' value='YES' onClick={() => deleteMemorandum(item.ID)} />
                      <Button accent='rgb(157, 195, 241)' value='NO' onClick={c => { e() }} />
                    </div>
                  </div>);
                }
              },
            ]
          }
        ];
      });
    }
  }

  const memorandumRef = React.useRef(null);
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" })
  }
  React.useEffect(() => {
    scrollToRef(memorandumRef);
  }, [edit]);

  const [businesses, setBusinesses] = React.useState([]);
  const getAllBusinesses = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/business/getAllBusinesses`,
      data: {
        filters: []
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        return setBusinesses(res.data);
      }
    }).catch(() => {
      setBusinesses({ status: 'error', data: 'Data fetch failed' });
      setServerError(prevError => {
        return prevError + '_ALL_INVOICES_ERROR_';
      });
    });
  }

  React.useEffect(() => {
    getAllBusinesses();
  }, []);

  const [logos, setLogos] = React.useState([]);
  const getAllImages = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/images/getAllImages`,
      ...backendModule.axiosConfig
    }).then(
      res => {
        let tmp = [];
        res.data.data.map((item) => {
          if (item.Tag === id) {
            tmp.push(item);
            return setLogos(tmp);
          }
        });
        return setLogos(tmp);
      }
    ).catch();
  }

  const [logoModal, setLogoModal] = React.useState(false);
  const changeLogoModalHandler = () => {
    setLogoModal(l => !l);
  }

  React.useEffect(() => {
    getAllImages();
  }, []);

  React.useEffect(() => {
    setSelectedLogo(logos[0]?.URL);
  }, [logos]);

  const logoRef = React.useRef(null);
  const [selectedLogo, setSelectedLogo] = React.useState(null);
  const changeLogo = (url) => {
    logoRef.current.src = url;
    setSelectedLogo(url);
    changeLogoModalHandler();
  }

  return (
    <div className='component__memorandum'>
      <div className='component__memorandum__head'>
        <h1>Memorandums</h1>
        <div className='component__memorandum__head__controles'>
          <Button style={{ display: edit ? 'block' : 'none' }} className='downloadMemorandumButton' accent='#67E564' value='Download as PDF' onClick={() => pdfDownloadHandler(memorandumForEdit.ID)} />
          <Button style={{ display: newMemorandum ? 'block' : 'none' }} className='saveMemorandumButton' accent='#67E564' value='Save' onClick={() => saveMemorandum(id)} />
          <Button style={{ display: edit ? 'none' : 'block' }} className='newMemorandumButton' accent='#67E564' value={newMemorandum ? 'Cancel' : 'New'} onClick={() => memorandumModalHandler()} />
          <Button style={{ display: edit ? 'block' : 'none' }} className='saveEditButton' accent='#67E564' value='Save' onClick={() => saveEditedMemorandum(memorandumForEdit.ID)} />
          <img id='closeMemorandumIcon' style={{ display: edit ? 'block' : 'none' }} src='images/closeIcon.png' alt='' onClick={() => closeView()} />
        </div>
      </div>
      <br></br>
      <hr></hr>
      <br></br>

      <p id='msg' ref={msgRef}></p>

      <div style={{ display: newMemorandum ? 'block' : 'none' }} className='component__memorandum__createNewMemorandum'>
        <div style={{ display: logoModal ? 'flex' : 'none' }} className='component__memorandum__createNewMemorandum__logoModal'>
          <p>Select logo for this memorandum</p>
          <small>You can upload new logo when creating new invoice</small>
          {
            logos?.map((item) => {
              return <span><img id='logo' src={item.URL} alt='logo' onClick={() => changeLogo(item.URL)} /></span>
            })
          }
        </div>
        <div className='component__memorandum__createNewMemorandum__heading'>
          <div>
            {
              businesses.data?.map((item, index) => {
                if (id == index + 1) {
                  return <>
                    <h4>{item.BusinessName}</h4>
                    <p>{item.Address}, {item.City}, {item.ZIP}</p>
                    <p>{item.Country}</p>
                    <p>ID Broj: {item.IDnumber}</p>
                    <p>PDV broj: {item.PDVnumber}</p>
                    <p>Tran. račun: {item.TrnAccount} {item.BenBank}</p>
                  </>
                }
              })
            }
          </div>
          {
            logos ?
              logos.length > 0 ?
                <img ref={logoRef} src={logos[0].URL} />
                : ''
              : ''
          }
          <img id='newmemopen' src='images/editPen.png' alt='' onClick={() => changeLogoModalHandler()} />
        </div>
        <br></br>
        <hr></hr>
        <br></br>
        <div className='component__memorandum__createNewMemorandum__body'>
          <textarea placeholder='Write...' ref={textareaRef} />
        </div>
      </div>

      <div ref={memorandumRef} style={{ display: edit ? 'block' : 'none' }} className='component__memorandum__editMemorandum'>
        <div className='component__memorandum__editMemorandum__heading'>
          <div>
            {
              businesses.data?.map((item, index) => {
                if (id == index + 1) {
                  return <>
                    <h4>{item.BusinessName}</h4>
                    <p>{item.Address}, {item.City}, {item.ZIP}</p>
                    <p>{item.Country}</p>
                    <p>ID Broj: {item.IDnumber}</p>
                    <p>PDV Broj: {item.PDVnumber}</p>
                    <p>Tran. račun: {item.TrnAccount} {item.BenBank}</p>
                  </>
                }
              })
            }
          </div>
          <img src={memorandumForEdit.LogoURL} alt='' />
        </div>
        <br></br>
        <hr></hr>
        <br></br>
        <div className='component__memorandum__editMemorandum__body'>
          <img ref={eImgRef} onClick={() => editMemorandum()} id='editMemorandumPen' src='images/editPen.png' alt='' />
          <textarea ref={editTextareaRef} defaultValue={memorandumForEdit.Text} style={{ display: eTxt ? 'block' : 'none' }} />

          <p ref={textRef} style={{ display: eTxt ? 'none' : 'block', wordWrap: 'break-word' }}>{memorandumForEdit.Text}</p>

          <div style={{ display: eTxt ? 'none' : 'block' }} className='component__memorandum__editMemorandum__body__sign'>
            <hr></hr>
            <p>Potpis</p>
          </div>
        </div>
      </div>

      <Spinner style={{ display: serverError != '' ? 'block' : 'none', width: "64px", height: "64px" }} color="rgb(157, 195, 241)" align="center" />
      <p style={{ display: noData || serverError != '' ? 'block' : 'none', color: 'red', textAlign: 'center', fontFamily: 'Roboto' }}>{noData ? 'No data to display' : ''} {serverError != '' ? memorandums.data : ''}</p>
      <div style={{ display: serverError.includes('MEMORANDUMS') ? 'none' : 'block' }} className='component__memorandum__list'>
        <FilteredCustomTable
          id='table'
          accent='rgb(157 195 241)'
          theme='light'
          headers={['ID', 'Text', 'Business ID']}
          filters={[
            { name: "ID", friendlyName: "ID", type: "number" },
            { name: "Text", friendlyName: "Text", type: "string" }
          ]}
          filterCB={f => setFilters(f)}
          data={!serverError.includes('MEMORANDUMS') && !noData ? addMemorandumToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
        />
        <br></br>
      </div>
    </div>
  )
}
