import React from 'react';
import "./index.scss";
import Button from "../customComponents/Button";
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Spinner from "../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../customComponents/Table";
import TravelOrder from '../TravelOrder';
import ConfirmationTravel from "../ConfirmationTravel";
import ViewTravelConfirmation from '../ViewTravelConfirmation';

export default function TravelOrders(props) {

    const [noData, setNoData] = React.useState(false);
    const [serverError, setServerError] = React.useState(false);
    const [filters, setFilters] = React.useState([]);

    const [travelOrderModal, setTravelOrderModal] = React.useState(false);
    const travelOrderModalHandler = () => {
        setTravelOrderModal(m => !m);
    }

    const [travelOrders, setTravelOrders] = React.useState([]);
    const { id } = useParams();

    const getAllTravelOrders = (id) => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/travelorders/getAllTravelOrders`,
            data: {
                filters: [
                    ...filters,
                    { name: 'BusinessID', op: 'eq', value: id }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                //res.data.data.length = 0;
                res.data.data.length > 0 ? setNoData(false) : setNoData(true);
                setTravelOrders(res.data);
            };
        }).catch(() => {
            setServerError({ type: 'SERVER_ERROR', data: 'Failed to fetch data' });
        });
    }

    React.useEffect(() => {
        getAllTravelOrders(id);
    }, [filters]);

    const [view, setView] = React.useState(false);
    const [curTravelOrder, setCurTravelOrder] = React.useState();
    const [noInfo, setNoInfo] = React.useState(true);
    const viewTravelOrder = (t_id, id) => {
        setView(v => !v);
        if (!view) {
            axios({
                method: 'POST',
                url: `${backendModule.backendURL}/travelorders/getAllTravelOrders`,
                data: {
                    filters: [
                        ...filters,
                        { name: 'BusinessID', op: 'eq', value: id },
                        { name: 'ID', op: 'eq', value: t_id }
                    ],
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    setNoInfo(false);
                    setCurTravelOrder(res.data);
                };
            }).catch(() => {
                setServerError({ type: 'SERVER_ERROR', data: 'Failed to fetch data' });
            });
        }
    }

    const deleteTravelOrder = (item_id) => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/travelorders/removeTravelOrder`,
            data: {
                ID: item_id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getAllTravelOrders(id);
        }).catch(() => {
        });
    }

    const addOrdersToTable = () => {
        if (travelOrders.data) {
            return travelOrders?.data.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.ID}</h3></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.Travels}</h3></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.With}</h3></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.Where}</h3></div> },
                    { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{moment(item.DateEnd).format('DD.MM.YYYY.')}</h3></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            { keyID: String(item.ID), type: "button", text: "View", onClick: e => { !viewConfirmation ? viewTravelOrder(item.ID, id) : setView(false) } },
                            {
                                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>Are you sure?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='rgb(157, 195, 241)' value='YES' onClick={() => deleteTravelOrder(item.ID)} />
                                            <Button accent='rgb(157, 195, 241)' value='NO' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const [businesses, setBusinesses] = React.useState([]);
    const getAllBusinesses = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/business/getAllBusinesses`,
            data: {
                filters: [
                    ...filters,
                    { name: 'ID', op: 'eq', value: id }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return setBusinesses(res.data.data);
            }
        }).catch(() => {
            setBusinesses({ status: 'error', data: 'Data fetch failed' });
            setServerError(prevError => {
                return prevError + '_ALL_INVOICES_ERROR_';
            });
        });
    }

    React.useEffect(() => {
        getAllBusinesses(id);
    }, []);

    const pdfDownload = (t_id) => {
        props.pdfDownloadSpinner();
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/travelorders/createPDF`,
            data: {
                TravelOrderID: t_id
            },
            //responseType: 'arraybuffer',
            responseType: "blob",
            ...backendModule.axiosConfig
        }).then(res => {
            const href = URL.createObjectURL(res.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);

        }).catch((e) => {
            console.warn(e);
        }).finally(() => {
            props.pdfDownloadSpinner();
        });
    }

    const [confirmationModal, setConfirmationModal] = React.useState(false);
    const confirmationModalHandler = () => {
        setConfirmationModal(m => !m);
    }

    const [viewConfirmation, setViewConfirmation] = React.useState(false);
    const viewConfirmationHandler = () => {
        setViewConfirmation(v => !v);
        viewTravelOrder();
    }

    const pdfDownloadConfirmation = (id) => {
        props.pdfDownloadSpinner();
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/travelconfirmations/createPDF`,
            data: {
                OrderID: id
            },
            //responseType: 'arraybuffer',
            responseType: "blob",
            ...backendModule.axiosConfig
        }).then(res => {
            const href = URL.createObjectURL(res.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);

        }).catch((e) => {
            console.warn(e);
        }).finally(() => {
            props.pdfDownloadSpinner();
        });
    }

    return (
        <div className='component__travelOrders'>
            <div className='component__travelOrders__head'>
                <h1>Travel orders</h1>
                <div style={{ display: view || viewConfirmation ? 'none' : 'block' }}>
                    <Button className='addNewWarrant' accent='rgb(103, 229, 100)' value={travelOrderModal ? 'Close' : 'New travel order'} onClick={() => travelOrderModalHandler()} />
                </div>
                <div style={{ display: view && !confirmationModal ? 'block' : 'none' }}>
                    <Button className='addNewWarrant' accent='rgb(103, 229, 100)' value='Close' onClick={() => viewTravelOrder()} />
                    <Button className='addNewWarrant' accent='rgb(103, 229, 100)' value='Download as PDF' onClick={() => pdfDownload(curTravelOrder?.data[0]?.ID)} />
                </div>
                <div style={{ display: view && curTravelOrder?.data[0]?.Status === false ? 'flex' : 'none' }}>
                    <Button className='addNewWarrant' accent='rgb(103, 229, 100)' value={confirmationModal ? 'Cancel' : 'Make a confirmation'} onClick={() => confirmationModalHandler()} />
                </div>
                <div style={{ display: view && curTravelOrder?.data[0]?.Status === true ? 'block' : 'none' }}>
                    <Button className='addNewWarrant' accent='rgb(103, 229, 100)' value='View confirmation' onClick={() => viewConfirmationHandler()} />
                </div>
                <div style={{ display: viewConfirmation ? 'block' : 'none' }}>
                    <Button className='addNewWarrant' accent='rgb(103, 229, 100)' value='Close' onClick={() => viewConfirmationHandler()} />
                    <Button className='addNewWarrant' accent='rgb(103, 229, 100)' value='Download as PDF' onClick={() => pdfDownloadConfirmation(curTravelOrder?.data[0]?.ID)} />
                </div>
            </div>
            <br></br>
            <hr></hr>
            <br></br>
            {
                (() => {
                    if (travelOrderModal) return <TravelOrder handler={travelOrderModalHandler} getAll={getAllTravelOrders} />
                })()
            }
            {
                (() => {
                    if (confirmationModal) return <ConfirmationTravel
                        handler={confirmationModalHandler}
                        handlerSecond = {viewTravelOrder}
                        currency={curTravelOrder?.data[0]?.Currency}
                        orderID={curTravelOrder?.data[0]?.ID}
                        travels={curTravelOrder?.data[0]?.Travels}
                        dateStart={moment(curTravelOrder?.data[0]?.DateStart).format('YYYY-MM-DD')}
                        dateEnd={moment(curTravelOrder?.data[0]?.DateEnd).format('YYYY-MM-DD')}
                    />
                })()
            }
            {
                (() => {
                    if(viewConfirmation) return <ViewTravelConfirmation orderID={curTravelOrder?.data[0]?.ID} />
                })()
            }
            <div style={{ display: view ? 'grid' : 'none' }} className='component__travelOrders__view'>
                <div className='component__travelOrders__view__left'>
                    <h1>IZVJEŠTAJ</h1>
                    <h2>o izvršenom <br></br> putovanju</h2>
                    <p>(potpis podnosioca izvještaja)</p>
                </div>
                <div className='component__travelOrders__view__right'>
                    <span><p>Broj: {curTravelOrder?.data[0]?.NumberOfTravelOrder}</p></span>
                    <span><p>Datum: {moment(curTravelOrder?.data[0]?.Date).format('DD.MM.YYYY.')}</p></span>
                    <br></br>
                    <p>na osnovu člana 13. Uredbe o naknadama troškova za službena putovanja("Službene novine Federacije "BiH", br. {curTravelOrder?.data[0]?.Br}), {businesses[0]?.BusinessName} izdaje</p>
                    <h1>PUTNI NALOG ZA SLUŽBENO PUTOVANJE</h1>
                    <br></br>
                    <p>kojim se državni službenik <span>{curTravelOrder?.data[0]?.Travels}</span>, raspoređen na poziciju</p>
                    <p><span>{curTravelOrder?.data[0]?.WorkPlace}</span>, upućuje na službeno putovanje u <span>{curTravelOrder?.data[0]?.Where}, </span></p>
                    <p>u svrhu <span>{curTravelOrder?.data[0]?.Why}</span></p>
                    <br></br>
                    <p>Službeno putovanje će trajati od <span>{moment(curTravelOrder?.data[0]?.DateStart).format('DD.MM.YYYY.')}</span> do <span>{moment(curTravelOrder?.data[0]?.DateEnd).format('DD.MM.YYYY.')}</span> godine</p>
                    <p>Odobravaju se troškovi za noćenja u iznosu do <span>{curTravelOrder?.data[0]?.Expenses}</span> {curTravelOrder?.data[0]?.Currency}</p>
                    <p>Odbrava se upotreba <span>{curTravelOrder?.data[0]?.With}</span>, kao prijevoznog sredstva za službeni</p>
                    <p>put na relaciji <span>{curTravelOrder?.data[0]?.Relation}</span></p>
                    <p>Odobrava se isplata akontacija u iznosu od <span>{curTravelOrder?.data[0]?.Akontacija}</span> {curTravelOrder?.data[0]?.Currency}</p>
                    <p>Državni službenik je dužan u roku od 5 dana od dana završetka službenog putovanja sačiniti izvještaj o obavljenom službenom putu, te isti zajedno sa ovim putnim nalogom i računima vezanim uz izdatke tokom službenog puta dostaviti izdavaocu putnog naloga.</p>
                    <span id='foo'><p>M.P.</p><p>Ovlašteno lice</p></span>
                </div>
            </div>
            <Spinner style={{ display: serverError === false ? 'none' : 'block', width: '64px', height: '64px' }} align='center' color="rgb(157, 195, 241)" />
            <br></br>
            <p style={{ display: serverError === false && !noData ? 'none' : 'block', fontFamily: 'Roboto', textAlign: 'center', color: 'red' }}>{serverError != false ? serverError.data : 'No data to display'}</p>
            <div style={{ display: serverError === false ? 'block' : 'none' }}>
                <FilteredCustomTable
                    id='table'
                    accent='rgb(157 195 241)'
                    theme='light'
                    headers={['ID', 'Worker', 'Vehicle', 'To', 'Date end']}
                    filters={[
                        { name: "Travels", friendlyName: "Worker", type: "string" },
                        { name: "With", friendlyName: "Registration marks", type: "string" }
                    ]}
                    filterCB={f => setFilters(f)}

                    data={!noData ? addOrdersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                />
            </div>
        </div>
    )
}
