import React from 'react';
import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import Button from '../customComponents/Button';

export default function TableDropdown(props) {

    const [invoices, setInvoices] = React.useState([]);
    const getAllInvoices = () => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/invoices/getAllInvoices`,
        data: {
          filters: []
        },
        ...backendModule.axiosConfig
      }).then(res => {
        setInvoices(res.data);
      }).catch(() => {
  
      });
    }
  
    React.useEffect(() => {
      getAllInvoices();
    }, []);
  
    const [deleteClientError, setDeleteClientError] = React.useState('');
    let counter = 0;
    const deleteClient = (id) => {
      props.customersData?.data.map((item, index) => {
        if (item.ID === id) {
          invoices.data?.map((inv) => {
            if(inv.ClientID == item.ID){
              counter++;
            }
          });
        }
      });
      if(counter < 1){
        setDeleteClientError(false);
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/customers/removeCustomer`,
          data: {
            ID: id
          },
          ...backendModule.axiosConfig
        }).then(response => {
          if (response.data.status === "ok") {
            props.getAllClients();
          };
        }).catch(() => {
    
        });
      }
      else if (counter >= 1){
        setDeleteClientError('Ne možete obrisati klijenta ukoliko na njegovo ime postoji faktura!');
        setTimeout(()=>{
          setDeleteClientError('');
        }, 3000);
      }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'baseline', alignItems: 'baseline' }}>
            <p style={{ color: 'black' }}>{deleteClientError ? deleteClientError : 'Are you sure?'}</p>
            <br></br>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button style={{ marginRight: '10px' }} accent='rgb(157 195 241)' value='YES' onClick={() => deleteClient(props.id)} />
                <Button accent='rgb(157 195 241)' value='NO' onClick={c => { props.e() }} />
            </div>
        </div>
    )
}
