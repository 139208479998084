import React from 'react';
import "./index.scss";

export default function index(props) {
    return (
        <div onClick={props.onClick} id={props.id} className='component__newInvoiceBlock'>
            <div className='component__newInvoiceBlock__body'>
                <p id='id'>{props.id}</p>
                <h3 id='company-name'>{props.companyName}</h3>
                <p id='date'>{props.date}</p>
                <hr id='hline'></hr>
                <h3 id='amount'>{props.amount}</h3>
            </div>
            <div className='component__newInvoiceBlock__status'>
                <p>{props.type}</p>
            </div>
        </div>
    )
}
