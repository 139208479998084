import React from 'react';
import "./index.scss";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import Button from '../customComponents/Button';
import moment from 'moment';

export default function ViewTravelConfirmation(props) {

    const viewRef = React.useRef(null);
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }

    React.useEffect(() => {
        scrollToRef(viewRef);
    }, []);

    const [filters, setFilters] = React.useState([]);
    const [curConfirmation, setCurConfirmation] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);

    const getAllConfirmations = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/travelconfirmations/getAllConfirmations`,
            data: {
                filters: [
                    ...filters,
                    { name: 'OrderID', op: 'eq', value: props.orderID }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setCurConfirmation(res.data);
                setLoaded(true);
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllConfirmations();
    }, []);

    const { id } = useParams();

    const [businesses, setBusinesses] = React.useState([]);
    const getAllBusinesses = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/business/getAllBusinesses`,
            data: {
                filters: [
                    ...filters,
                    { name: 'ID', op: 'eq', value: id }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return setBusinesses(res.data.data);
            }
        }).catch(() => {
            setBusinesses({ status: 'error', data: 'Data fetch failed' });
        });
    }

    React.useEffect(() => {
        getAllBusinesses(id);
    }, []);

    return (
        <div ref={viewRef} className='component__viewTravelConfirmation'>
            <div className='component__viewTravelConfirmation__left'>
                {
                    loaded ?
                        JSON.parse(curConfirmation?.data[0]?.Fields).map((item, index) => {
                            if (item.who !== '') {
                                return <div className='component__viewTravelConfirmation__left__item'>
                                    <span><p>{index + 1}. Potvrđuje se da je </p> <span id='data'>{item.who}</span> </span>
                                    <p>boravio-la u vezi sa prednjim putnim nalogom o ovoj radnoj organizaciji - organu</p>
                                    <span><p>od</p> <span id='data'>{item.from !== '' ? moment(item.from).format('DD.MM.YYYY.') : ''}</span> do <span id='data'>{item.to !== '' ? moment(item.to).format('DD.MM.YYYY.') : ''}</span> <p>{curConfirmation?.data[0]?.Year} godine</p></span>
                                    <span id='item-foo'><p>M.P.</p><div><p>- - - - - - - - - - - - - - -</p><small>(potpis ovlaštenog lica)</small></div></span>
                                </div>
                            }
                        })
                        : ''
                }
            </div>
            <div className='component__viewTravelConfirmation__right'>
                <p>U {businesses[0]?.City}, dana <span id='dashed'>{loaded ? moment(curConfirmation?.data[0]?.Date).format('DD.MM.YYYY.') : ''}</span></p>
                <h1>OBRAČUN TROŠKOVA PUTOVANJA</h1>
                <br></br>
                <p>U vezi sa prednjim putnim nalogom podnosim sljedeći obračun:</p>
                <p>Na put sam krenu -la dana <span>{loaded ? moment(curConfirmation?.data[0]?.DateStart).format('DD.MM.YYYY.') : ''}</span> u <span>{loaded ? curConfirmation?.data[0]?.TimeStart : ''}</span></p>
                <p>Vratio -la sam se dana <span>{loaded ? moment(curConfirmation?.data[0]?.DateEnd).format('DD.MM.YYYY.') : ''}</span> u <span>{loaded ? curConfirmation?.data[0]?.TimeEnd : ''}</span></p>
                <p>Na putu sam proveo-la dana <span>{loaded ? curConfirmation?.data[0]?.Days : ''}</span> sati <span>{loaded ? curConfirmation?.data[0]?.Hours : ''}</span></p>
                <div className='component__viewTravelConfirmation__right__table'>
                    <table>
                        <span id='row'>1) Dnevnice</span>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[0].Diaries[0] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[0].Diaries[1] : ''}</div></div>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[0].Diaries[2] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[0].Diaries[3] : ''}</div></div>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[0].Diaries[4] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[0].Diaries[5] : ''}</div></div>
                    </table>
                    <div id='right'>
                        <p>1. UKUPNO dnevnice</p>
                        <p>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[0].DiariesTotal : ''}</p>
                    </div>
                </div>
                <div className='component__viewTravelConfirmation__right__table'>
                    <table>
                        <span id='row'>2) Troškovi prijevoza</span>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[1].Transport[0] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[1].Transport[1] : ''}</div></div>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[1].Transport[2] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[1].Transport[3] : ''}</div></div>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[1].Transport[4] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[1].Transport[5] : ''}</div></div>
                    </table>
                    <div id='right'>
                        <p>2. UKUPNO prijevoz</p>
                        <p>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[1].TransportTotal : ''}</p>
                    </div>
                </div>
                <div className='component__viewTravelConfirmation__right__table'>
                    <table>
                        <span id='row'>3) Izdaci za noćenja</span>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[2].Overnight[0] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[2].Overnight[1] : ''}</div></div>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[2].Overnight[2] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[2].Overnight[3] : ''}</div></div>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[2].Overnight[4] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[2].Overnight[5] : ''}</div></div>
                    </table>
                    <div id='right'>
                        <p>3. UKUPNO noćenja</p>
                        <p>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[2].OvernightTotal : ''}</p>
                    </div>
                </div>
                <div className='component__viewTravelConfirmation__right__table'>
                    <table>
                        <span id='row'>4) Ostali troškovi</span>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[3].Other[0] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[3].Other[1] : ''}</div></div>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[3].Other[2] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[3].Other[3] : ''}</div></div>
                        <div id='row'><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[3].Other[4] : ''}</div><div>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[3].Other[5] : ''}</div></div>
                    </table>
                    <div id='right'>
                        <p>4. UKUPNO troškovi</p>
                        <p>{loaded ? JSON.parse(curConfirmation?.data[0]?.Expenses)[3].OtherTotal : ''}</p>
                    </div>
                </div>
                <span id='sum'><strong>UKUPNI TROŠKOVI 1+2+3+4</strong><p id='p-with-border'>{loaded ? Number(curConfirmation?.data[0]?.Total).toFixed(2) : ''}</p></span>
                <span id='sum'><strong>Primljena akontacija:</strong><p id='p-with-border'>{loaded ? Number(curConfirmation?.data[0]?.Recieved).toFixed(2) : ''}</p></span>
                <span id='sum'><p>Ostaje za isplatu - Povrat:</p><p id='p-with-border'>{loaded ? Number(curConfirmation?.data[0]?.Remains).toFixed(2) : ''}</p></span>

                <div className='component__viewTravelConfirmation__right__footer'>
                    <div>
                        <div id='dashed'></div>
                        <small>Obračun podnio</small>
                    </div>
                    <div>
                        <div id='dashed'></div>
                        <small>Likvidirao</small>
                    </div>
                    <div>
                        <div id='dashed'></div>
                        <small>Kontrolirao</small>
                    </div>
                    <div>
                        <div id='dashed'></div>
                        <small>Saglasan</small>
                    </div>
                </div>
            </div>
        </div>
    )
}
