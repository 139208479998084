import React from 'react';
import "./index.scss";
import Button from "../customComponents/Button";
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Spinner from "../../components/customComponents/Spinner";
import { render } from "react-dom";
import { useReactToPrint } from "react-to-print";
import { english } from '../../languages/english';
import { bosnian } from '../../languages/bosnian';

export default function Invoice(props) {

    const [noData, setNoData] = React.useState(false);
    const [serverError, setServerError] = React.useState('');

    const invoiceRef = React.useRef(null);
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" })
    }
    React.useEffect(() => {
        scrollToRef(invoiceRef);
    }, []);

    const [invoiceClose, setInvoiceClose] = React.useState(false);

    const pdfDownloadHandler = async (id, inv_id) => {
        if (id === '5') {
            props.pdfDownloadSpinner();
            axios({
                method: 'POST',
                url: `${backendModule.backendURL}/invoices/createPDF`,
                data: {
                    InvoiceID: inv_id
                },
                //responseType: 'arraybuffer',
                responseType: "blob",
                ...backendModule.axiosConfig
            }).then(res => {
                const href = URL.createObjectURL(res.data);

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'file.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);

            }).catch(() => {

            }).finally(() => {
                props.pdfDownloadSpinner();
            });
        }
    }

    const [emailModal, setEmailModal] = React.useState(false);
    const openEmailModal = (id, inv_id) => {
        if (id === '1') {
            setEmailModal(m => !m);
        }
    }

    const emailRef = React.useRef(null);
    const emailMsgRef = React.useRef(null);
    const sendEmail = (id) => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/invoices/sendEmail`,
            data: {
                ID: id,
                Email: emailRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {

        }).catch(() => {
            return;
        });

        emailMsgRef.current.innerText = 'You have successfully sent your email!'
        emailMsgRef.current.style.color = 'green';
        emailMsgRef.current.style.fontFamily = 'Roboto';
        setTimeout(() => {
            emailMsgRef.current.innerText = '';
            setEmailModal(false);
        }, 2000);
    }

    const data = [
        { id: 1, label: "Send by email" },
        { id: 5, label: "Download PDF" },
    ];

    const [isOpen, setOpen] = React.useState(false);
    const [items, setItem] = React.useState(data);
    const [selectedItem, setSelectedItem] = React.useState(null);

    const toggleDropdown = () => setOpen(!isOpen);

    const handleItemClick = (id, inv_id) => {
        selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
        openEmailModal(id, inv_id);
        pdfDownloadHandler(id, inv_id);
    }

    const searchTerm = (searchbar, values_list) => {
        const search = document.querySelector(searchbar);
        const values = document.querySelectorAll(values_list);
        values.forEach(value => {
            value.style.display = 'grid';
            if (!value.textContent.toLowerCase().includes(search.value.toLowerCase())) {
                value.style.display = 'none';
            }
        });
    }

    const [editInvoice, setEditInvoice] = React.useState(false);
    const editInvoiceHandler = () => {
        setEditInvoice(e => !e);
        setEcm(false);
    }

    const [ecm, setEcm] = React.useState(false);
    const [ddm, setDdm] = React.useState(false);
    const [eam, setEam] = React.useState(false);
    const [editRemark, setEditRemark] = React.useState(false);

    const editThing = (thing, id) => {
        if (thing === 'client') {
            setEcm(c => !c);
        }
        if (thing === 'dueDate') {
            if (editInvoice) {
                setDdm(d => !d);
            }
        }
        if (thing === 'articles') {
            setEam(a => !a);
        }
        if (thing === 'remark') {
            setEditRemark(r => !r);
        }
    }

    const clientRef = React.useRef([]);
    const newDueDateRef = React.useRef(null);
    const changeThing = (thing, id) => {
        if (thing === 'client') {
            curInvoice.ClientID = id;
            setEcm(false);
        }
        if (thing === 'dueDate') {
            curInvoice.DueDate = newDueDateRef.current.value;
        }
    }

    const msgRef = React.useRef(null);
    const newRemarkRef = React.useRef(null);

    const saveEdited = (invID) => {

        let tmp = JSON.parse(curInvoice.Articles);

        tmp.map((item) => {
            item.price = String(item.price).replace(curInvoice.Currency, '');
        });

        let finalArticles = [
            ...tmp,
            ...newArticles
        ];

        if (editInvoice) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/invoices/editInvoice`,
                data: {
                    ClientID: curInvoice.ClientID,
                    ID: invID,
                    BusinessID: id,
                    DateOfIssue: curInvoice.DateOfIssue,
                    DueDate: curInvoice.DueDate,
                    Articles: finalArticles,
                    PDV: curInvoice.PDV,
                    RabatPercent: calcEdit ? Number(newRabatPercent).toFixed(2) : rabatPercent,
                    AvansPercent: calcEdit ? Number(newAvansPercent).toFixed(2) : avansPercent,
                    PaidAmount: calcEdit ? Number(newPaid).toFixed(2) : paid.replace(curInvoice.Currency, ''),
                    Currency: curInvoice.Currency,
                    Type: curInvoice.Type,
                    LogoURL: curInvoice.LogoURL,
                    AdditionalData: null,
                    isPaid: curInvoice.isPaid,
                    Language: curInvoice.Language,
                    isAbroad: false,
                    Remark: editRemark ? [{ ID: '000', Text: newRemarkRef.current.value }] : JSON.parse(curInvoice.Remark),
                    Cancelled: curInvoice.Cancelled,
                    InvoiceIssuedBy: props.loggedUser
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'error') {
                    msgRef.current.innerText = res.data.data;
                }
                else if (res.data.status === 'ok') {
                    props.getAll(id, props.year);
                    setTimeout(() => {
                        props.onClose();
                    }, 1000);
                }
            }).catch((e) => {
                msgRef.current.innerText = e;
            });
        }
        editInvoiceHandler();
    }

    const [allInvoices, setAllInvoices] = React.useState([]);
    const { id } = useParams();

    const getAllInvoices = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/invoices/getAllInvoices`,
            data: {
                filters: []
            },
            ...backendModule.axiosConfig
        }).then(res => {
            //res.data.data.length = 0; 
            !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
            return setAllInvoices(res.data);
        }).catch(() => {
            setAllInvoices({ status: 'error', data: 'Data fetch failed' });
            setServerError(prevError => {
                return prevError + '_ALL_INVOICES_ERROR_';
            });
        });
    }

    const [curInvoice, setCurInvoice] = React.useState([]);
    const [rabat, setRabat] = React.useState();
    const [rabatPercent, setRabatPercent] = React.useState();
    const [avansPercent, setAvansPercent] = React.useState();
    const [avans, setAvans] = React.useState();
    const [totalWithoutPDV, setTotalWithoutPDV] = React.useState();
    const [totalWithPDV, setTotalWithPDV] = React.useState();
    const [paid, setPaid] = React.useState();
    const [due, setDue] = React.useState();
    const [invNum, setInvNum] = React.useState();

    const getCurInvoice = () => {
        if (allInvoices) {
            if (allInvoices.data) {
                allInvoices.data?.map((item, key) => {
                    if (item.ID == props.id) {
                        setRabatPercent(item.RabatPercent);
                        setAvansPercent(item.AvansPercent)
                        JSON.parse(item.Calculation).map((c) => {
                            setRabat(c.Rabat);
                            setAvans(c.Avans);
                            setTotalWithoutPDV(c.totalWithoutPDV);
                            setTotalWithPDV(c.TotalWithPDV);
                            setPaid(c.Paid);
                            setDue(c.Due);
                        });
                        setInvNum(`${moment(item.DateOfIssue).format('YY/MM')}/${item.CustomNumber}/${item.InvoiceNumber}`);
                        return setCurInvoice(item);
                    }
                });
            }
        }
    }


    const [businesses, setBusinesses] = React.useState([]);
    const getAllBusinesses = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/business/getAllBusinesses`,
            data: {
                filters: []
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return setBusinesses(res.data);
            }
        }).catch(() => {
            setBusinesses({ status: 'error', data: 'Data fetch failed' });
            setServerError(prevError => {
                return prevError + '_ALL_INVOICES_ERROR_';
            });
        });
    }

    React.useEffect(() => {
        getAllBusinesses();
    }, []);

    React.useEffect(() => {
        getAllInvoices(id);
    }, []);

    React.useEffect(() => {
        if (!serverError.includes('INVOICES')) {
            getCurInvoice();
        }
    }, [allInvoices]);

    const [allClients, setAllClients] = React.useState([]);

    const getAllClients = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/customers/getAllCustomers`,
            data: {
                filters: []
            },
            ...backendModule.axiosConfig
        }).then(res => {
            //res.data.data.length = 0; 
            //!res.data.data.length > 0 ? setNoData(true) : setNoData(false);
            return setAllClients(res.data);
        }).catch(() => {
            setAllClients({ status: 'error', data: 'Data fetch failed' });
            setServerError(prevError => {
                return prevError + '_ALL_INVOICES_ERROR_';
            });
        });
    }

    React.useEffect(() => {
        getAllClients();
    }, []);

    const [servicesArray, setServicesArray] = React.useState([]);
    const getAllServices = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/getAllArticles`,
            data: {
                pagination: 0,

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return setServicesArray(res.data.data);
            };
        }).catch(() => {
            setServicesArray({ status: 'error', data: 'Data fetch failed' });
            return setServerError(prevError => {
                return prevError + 'ARTICLES_ERROR';
            });
        });
    }
    React.useEffect(() => {
        getAllServices();
    }, []);

    const curServiceIDref = React.useRef([]);
    const serviceRef = React.useRef([]);
    const mpcRef = React.useRef([]);
    const serPriceRef = React.useRef([]);
    const [currency, setCurrency] = React.useState();
    const scrollTriggerRef = React.useRef(null);
    const [loadingData, setLoadingData] = React.useState();
    const [filters, setFilters] = React.useState([]);

    const useIsInViewport = () => {
        const [isIntersecting, setIsIntersecting] = React.useState(false);

        const observer = React.useMemo(
            () =>
                new IntersectionObserver(([entry]) =>
                    setIsIntersecting(entry.isIntersecting),
                ),
            [],
        );

        React.useEffect(() => {
            observer.observe(scrollTriggerRef.current);

            return () => {
                observer.disconnect();
            };
        }, [scrollTriggerRef, observer]);

        if (isIntersecting) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/articles/getAllArticles`,
                data: {
                    pagination: servicesArray.length,
                    filters: [
                        ...filters,
                        { name: 'BusinessID', op: 'eq', value: id }
                    ],
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    if (res.data.data.length > 0) {
                        setLoadingData(true);
                        setTimeout(() => setLoadingData(false), 100);
                        res.data.data?.map((item) => {
                            servicesArray.push(item);
                        });
                    }
                };
            }).catch(() => {
            });
        }
    }
    useIsInViewport();

    const getCurrencies = () => {
        props.currencies.data?.map((item) => {
            if (item.Code == curInvoice.Currency) {
                setCurrency(item);
            }
        });
    }

    React.useEffect(() => {
        getCurrencies();
    });

    const [newArticles, setNewArticles] = React.useState([]);
    const addDescription = (index) => {
        let tmp = newArticles;
        tmp.push({
            name: serviceRef.current[index].textContent,
            price: String(serPriceRef.current[index].textContent).replace(curInvoice.Currency, ''),
            mpc: mpcRef.current[index].textContent,
            id: curServiceIDref.current[index].textContent,
            pdv: curInvoice.PDV + ' %'
        });
        setNewArticles(tmp);
        setEam(false);
        quantityModalHandler();
    }

    const [quantityModal, setQuantityModal] = React.useState(false);
    const quantityModalHandler = () => {
        setQuantityModal(q => !q);
    }

    const quantityInputRef = React.useRef(null);
    const addQuantity = () => {
        if (!isNaN(quantityInputRef.current.value)) {
            if (quantityInputRef.current.value) {
                let tmp = newArticles;
                tmp[tmp.length - 1] = {
                    ...tmp[tmp.length - 1],
                    quantity: quantityInputRef.current.value
                };
                setNewArticles(tmp);
                quantityModalHandler();
                sumAgain();
                setCalcEdit(true);
            }
        }
    }

    const [language, setLanguage] = React.useState([]);
    const changeLanguage = () => {
        if (curInvoice.InvoiceNumber) {
            if (curInvoice.Language === 'ba') {
                setLanguage(bosnian);
            }
            else if (curInvoice.Language === 'en') {
                setLanguage(english);
            }
        }
    }

    React.useEffect(() => {
        if (!curInvoice?.InvoiceNumber) return;
        changeLanguage();
    }, [curInvoice?.InvoiceNumber]);

    const [calcData, setCalcData] = React.useState([]);
    const newTotalRef = React.useRef(null);
    const [rabatOption, setRabatOption] = React.useState(null);

    const [newRabat, setNewRabat] = React.useState(null);
    const [newRabatPercent, setNewRabatPercent] = React.useState(null);
    const [newTotalWrabat, setNewTotalWrabat] = React.useState(null);
    const [newTotalWpdv, setNewTotalWpdv] = React.useState(null);
    const [newAvans, setNewAvans] = React.useState(null);
    const [newAvansPercent, setNewAvansPercent] = React.useState(null);
    const [newTotal, setNewTotal] = React.useState(null);
    const [newPaid, setNewPaid] = React.useState(null);
    const [newPDVvalue, setNewPDVvalue] = React.useState(null);
    const [newDue, setNewDue] = React.useState(null);

    const [calcEdit, setCalcEdit] = React.useState(false);
    const editCalculation = () => {
        setCalcEdit(e => !e);
    }

    const sumAgain = () => {
        let new_total = 0;
        JSON.parse(curInvoice.Articles).map((item, index) => {
            new_total = new_total + (Number((item.price).replace(curInvoice.Currency, '')) * (Number(item.quantity)));
        });
        newArticles.map((item, index) => {
            new_total = new_total + (Number((item.price).replace(curInvoice.Currency, '')) * (Number(item.quantity)));
        });
        setTotalWithoutPDV(new_total + curInvoice.Currency);

        newTotalRef.current.innerText = curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(new_total).replace('€', curInvoice.Currency);
    }

    const calculateAgain = () => {
        if (rabatOption === '%') {
            setNewRabat((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2));
            setNewTotalWrabat(Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2)));
            setNewPDVvalue((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2))) * curInvoice.PDV / 100);
            setNewTotalWpdv((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2))) + ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2))) * curInvoice.PDV / 100))
            setNewAvansPercent(avansInputRef.current.value / ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2))) + ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2))) * curInvoice.PDV / 100)) * 100);
            setNewTotal(((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2))) + ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2))) * curInvoice.PDV / 100)) - avansInputRef.current.value);
            if (paidInputRef.current.value < 1) {
                setNewDue(((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2))) + ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2))) * curInvoice.PDV / 100)) - avansInputRef.current.value);
            }
            else {
                setNewDue((((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2))) + ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) * rabatInputRef.current.value / 100).toFixed(2))) * curInvoice.PDV / 100)) - avansInputRef.current.value) - paidInputRef.current.value);
            }
        }
        else {
            setNewRabat(Number(rabatInputRef.current.value).toFixed(2));
            setNewTotalWrabat(Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value);
            setNewPDVvalue((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) * curInvoice.PDV / 100);
            setNewTotalWpdv((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) + ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) * curInvoice.PDV / 100));
            setNewAvansPercent(avansInputRef.current.value / ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) + ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) * curInvoice.PDV / 100)) * 100);
            setNewTotal(((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) + ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) * curInvoice.PDV / 100)) - avansInputRef.current.value);
            if (paidInputRef.current.value < 1) {
                setNewDue(((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) + ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) * curInvoice.PDV / 100)) - avansInputRef.current.value);
            }
            else {
                setNewDue((((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) + ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) * curInvoice.PDV / 100)) - avansInputRef.current.value) - paidInputRef.current.value);
            }
        }
    }

    const deleteArticle = (id) => {
        setNewArticles(d => d.filter((_, idx) => idx !== id));
        newArticles.map((item, index) => {
            if (index === id) {
                newArticles.splice(index, index + 1);
            }
        });
        sumAgain();
        setNewRabat(null);
        setNewRabatPercent(null);
        setNewTotalWrabat(null);
        setNewPDVvalue(null);
        setNewAvans(null);
        setNewAvansPercent(null);
        setNewTotal(null);
        setNewTotalWpdv(null);
        setNewPaid(null);
        setNewDue(null);
    }

    const [oldArticles, setOldArticles] = React.useState([]);
    const deleteOldArticle = (id) => {
        setCalcEdit(true);
        JSON.parse(curInvoice.Articles).map((item) => {
            oldArticles.push(item);
        });
        setOldArticles(d => d.filter((_, idx) => idx !== id));
        oldArticles.map((item, index) => {
            if (index === id) {
                oldArticles.splice(index, index + 1);
            }
        });
        curInvoice.Articles = JSON.stringify(oldArticles);
        sumAgain();
        setNewRabat(null);
        setNewRabatPercent(null);
        setNewTotalWrabat(null);
        setNewPDVvalue(null);
        setNewAvans(null);
        setNewAvansPercent(null);
        setNewTotal(null);
        setNewTotalWpdv(null);
        setNewPaid(null);
        setNewDue(null);
    }

    const [paidModal, setPaidModal] = React.useState(false);
    const paidModalHandler = () => {
        setPaidModal(p => !p);
    }

    const paidInputRef = React.useRef(null);
    const addPaid = () => {
        if (!isNaN(paidInputRef.current.value)) {
            setNewPaid(paidInputRef.current.value);
            calculateAgain();
            paidModalHandler();
        }
    }

    const [avansModal, setAvansModal] = React.useState(false);
    const avansModalHandler = () => {
        setAvansModal(a => !a);
    }

    const avansInputRef = React.useRef(null);
    const addAvans = () => {
        if (!isNaN(avansInputRef.current.value)) {
            setNewAvans(avansInputRef.current.value);
            setNewAvansPercent((avansInputRef.current.value / newTotalWpdv * 100).toFixed(2));
            setNewTotal(((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) + ((Number(totalWithoutPDV.replace(curInvoice?.Currency, '')) - rabatInputRef.current.value) * curInvoice.PDV / 100)) - avansInputRef.current.value);
            calculateAgain();
            avansModalHandler();
        }
    }

    const [rabatModal, setRabatModal] = React.useState(false);
    const rabatModalHandler = () => {
        setRabatModal(r => !r);
    }

    const rabatInputRef = React.useRef(null);
    const addRabat = () => {
        if (!isNaN(rabatInputRef.current.value)) {
            setRabat(rabatInputRef.current.value);
            if (rabatOption === '%') {
                setNewRabatPercent(rabatInputRef.current.value);
            }
            else {
                setNewRabatPercent((rabatInputRef.current.value / (totalWithoutPDV.replace(curInvoice?.Currency, '')) * 100).toFixed(2))
            }
            calculateAgain();
            rabatModalHandler();
        }
    }

    const rabatOptRef = React.useRef(null);
    const rabatOptionHandler = (option) => {
        setRabatOption(option);
        rabatOptRef.current.textContent = option;
    }

    return (
        <div ref={invoiceRef} style={{ display: invoiceClose == true ? "none" : "block" }} className='component__invoice'>
            <div style={{ display: emailModal ? 'block' : 'none' }} className='component__invoice__emailModal'>
                <div className='component__invoice__emailModal__box'>
                    <h2>Enter email:</h2>
                    <input type='text' placeholder='Email' ref={emailRef} />
                    <div>
                        <Button className='cancelEmailBtn' accent='transparent' value='Cancel' onClick={() => setEmailModal(false)} />
                        <Button className='sendEmailBtn' accent='rgb(103, 229, 100)' value='Send' onClick={() => sendEmail(curInvoice.ID)} />
                    </div>
                    <p ref={emailMsgRef}></p>
                </div>
            </div>
            <div className='component__invoice__head'>
                <h1 id='invoice-id'>{invNum}</h1>
                <div id='invoice-item' className='component__invoice__head__dropdown'>
                    <div className='component__invoice__head__dropdown-header' onClick={toggleDropdown}>
                        {selectedItem ? items.find(item => item.id == selectedItem).label : "More actions"}
                        <img src='images/arrowDown.png' />
                    </div>
                    <div className={`component__invoice__head__dropdown-body ${isOpen && 'open'}`}>
                        {items.map(item => (
                            <div className="component__invoice__head__dropdown-item" onClick={e => handleItemClick(e.target.id, curInvoice.ID)} id={item.id}>
                                <span className={`component__invoice__head__dropdown-item-dot ${item.id == selectedItem && 'selected'}`}>• </span>
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
                <Button id='inovoice-item' onClick={() => saveEdited(curInvoice.ID, 'client')} accent='#67E564' value={editInvoice === false ? 'Edit' : 'Save'} />
                <img onClick={props.onClose} id='close-btn' src='images/closeIcon.png' />
            </div>
            <p id='msg' ref={msgRef}></p>
            <br></br>
            <hr></hr>
            <Spinner style={{ display: noData || serverError.includes('INVOICES') ? 'block' : 'none', width: "64px", height: "64px" }} color="#3F73FF" align="center" />
            <p style={{ display: noData || serverError.includes('INVOICES') ? 'block' : 'none', textAlign: 'center', color: 'red', fontFamily: 'Roboto' }}>{noData ? 'No data to display' : serverError != '' ? allInvoices.data : 'Unknown error'}</p>
            <div style={{ display: noData || serverError.includes('INVOICES') ? 'none' : 'block' }} className='invoice__pdf' >
                <div className='component__invoice__file'>
                    <div id='cancelled' style={{ display: curInvoice.Cancelled === true ? 'block' : 'none' }}><p>{language.Stornirano}</p></div>
                    <br></br>
                    <div className='component__invoice__file__head' fixed>
                        <div>
                            {
                                businesses.data?.map((item, index) => {
                                    if (id == item.ID) {
                                        return <>
                                            <h4>{item.BusinessName}</h4>
                                            <p>{item.Address}, {item.City}, {item.ZIP}</p>
                                            <p>{item.Country}</p>
                                            <p>{language.IDbroj} {item.IDnumber}</p>
                                            <p>{language.PDVbroj} {item.PDVnumber}</p>
                                            <p>{language.TranRacun} {item.TrnAccount} {item.BenBank}</p>
                                        </>
                                    }
                                })
                            }
                        </div>
                        <img src={curInvoice.LogoURL} alt='' />
                    </div>

                    <div className='component__invoice__file__client'>
                        <div className='component__invoice__file__client__left'>
                            <div>
                                <p id='invoice-customer'>{language.Kupac}</p>
                                {
                                    allClients.data ?
                                        allClients.data?.map((item) => {
                                            if (curInvoice.ClientID == item.ID) {
                                                return <>
                                                    <p>{item.Name}</p>
                                                    <p>{item.Address}</p>
                                                    <p>{item.PhoneNumber}</p>
                                                    <p>{item.City}</p>
                                                    <p>{item.Country}</p>
                                                    <p>{item.PDVNumber}</p>
                                                    <img style={{ display: editInvoice ? 'block' : 'none' }} onClick={() => editThing('client', item.ID)} id='editInvoicePen' src='images/editPen.png' alt='' />
                                                </>
                                            }
                                        })
                                        : ''
                                }
                                <div style={{ display: ecm ? 'block' : 'none' }} className='component__invoice__file__client__left__editClient'>
                                    <div className='component__invoice__file__client__left__editClient__nav'>
                                        <input id='edit-inv-search-clients' onChange={() => searchTerm('#edit-inv-search-clients', '#edit-inv-client-name')} type='text' placeholder='Search client' />
                                        <img onClick={() => setEcm(false)} src='images/closeIcon.png' alt='' />
                                    </div>
                                    <div className='component__invoice__file__client__left__editClient__list'>
                                        <br></br>
                                        {
                                            !serverError.includes('CLIENTS') ?
                                                allClients.data?.map((item, index) => {
                                                    return <p onClick={() => changeThing('client', item.ID)} ref={(element) => { clientRef.current[index] = element; }} cid={item.ID} id='edit-inv-client-name'>{item.Name}</p>
                                                })
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='component__invoice__file__client__right'>
                            <div style={{ display: ddm ? 'block' : 'none' }} className='component__invoice__file__client__right__editDueDate'>
                                <div className='component__invoice__file__client__right__editDueDate__head'>
                                    <h5>{language.ZadnjiRokUplate}</h5>
                                    <img onClick={() => setDdm(false)} src='images/closeIcon.png' alt='' />
                                </div>
                                <div className='component__invoice__file__client__right__editDueDate__body'>
                                    <div>
                                        <input onChange={() => changeThing('dueDate')} type='date' ref={newDueDateRef} />
                                    </div>
                                </div>
                            </div>
                            <span><p style={{ color: '#00A3FF', fontWeight: '700' }}>{language.Broj} {curInvoice.Type === 'FAKT.' ? language.BrojFakture : curInvoice.Type === 'PRED.' ? language.BrojPredracuna : curInvoice.Type === 'PON.' ? 'ponude' : language.BrojOtpremnice}</p><p style={{ fontWeight: '700' }}>{invNum}</p></span>
                            <span><p style={{ color: '#00A3FF' }}>{language.DatumIzdavanja}</p><p>{moment(curInvoice.DateOfIssue).format("D.MM.YYYY")}</p></span>
                            <span><p style={{ color: '#00A3FF' }} onClick={() => editThing('dueDate', curInvoice.ID)}>{language.ZadnjiRokUplate}</p><p>{moment(curInvoice.DueDate).format("D.MM.YYYY")}</p></span>
                            <br></br>
                            <hr></hr>
                            <br></br>
                            <span style={{ display: !calcEdit ? 'flex' : 'none' }}><p style={{ color: '#00A3FF' }}>{language.Dug} ({curInvoice.Currency})</p><p>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(due.replace(curInvoice.Currency, '')).replace('€', curInvoice.Currency)}</p></span>
                            <span style={{ display: calcEdit ? 'flex' : 'none' }}><p style={{ color: '#00A3FF' }}>{language.Dug} ({curInvoice.Currency})</p><p>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(newDue).replace('€', curInvoice.Currency)}</p></span>
                            <br></br>
                            <hr></hr>
                            <p id='person-responsible'>{language.RacunIzdao} {curInvoice.InvoiceIssuedBy}</p>
                        </div>
                    </div>
                    <br></br>
                    <hr></hr>
                    <br></br>

                    <div className='component__invoice__file__description'>
                        <img style={{ display: editInvoice ? 'block' : 'none' }} onClick={() => editThing('articles')} id='editInvoicePen' src='images/editPen.png' alt='' />
                        <input style={{ display: eam ? 'block' : 'none' }} onChange={() => searchTerm('#e-search-services', '#service-block')} id='e-search-services' type='text' placeholder='Search services...' />
                        <div style={{ display: eam ? 'block' : 'none' }} className='component__invoice__file__description__editArticles'>
                            <img onClick={() => setEam(false)} src='images/closeIcon.png' alt='' />
                            {
                                !serverError.includes('ARTICLES') ?
                                    servicesArray.map((item, index) => {
                                        if (eam === true) {
                                            return (<div onClick={() => addDescription(index)} key={index} id='service-block'><p style={{ display: 'none' }} ref={(element) => { curServiceIDref.current[index] = element; }}>{item.ID}</p><p id='service-name' ref={(element) => { serviceRef.current[index] = element; }}>{item.Name}</p> <p ref={(element) => { mpcRef.current[index] = element; }}>{item.MPC}</p> <p ref={(element) => { serPriceRef.current[index] = element }}>{parseFloat(item.Price * currency.Conversion).toFixed(4)} {curInvoice.Currency}</p></div>)
                                        }
                                    })
                                    : ''
                            }
                            <div ref={scrollTriggerRef}></div>
                            <Spinner style={{ display: loadingData ? 'block' : 'none', width: "32px", height: "32px" }} color="#3F73FF" align="center" />
                        </div>
                        <div style={{ display: quantityModal ? 'flex' : 'none' }} className='component__invoice__file__description__addQuantityModal'>
                            <div className='component__invoice__file__description__addQuantityModal__enterValue'>
                                <h1>{language.UnesiteKolicinu}</h1>
                                <br></br>
                                <input ref={quantityInputRef} type='number' />
                            </div>
                            <Button className='addQuantityButton' value='Done' accent='rgb(103, 229, 100)' onClick={() => addQuantity()} />
                        </div>
                        <div id='service-item'>
                            <p>{language.Opis}</p>
                            {
                                curInvoice.Articles ?
                                    JSON.parse(curInvoice.Articles)?.map((item, index) => {
                                        return <strong><img style={{ display: editInvoice ? 'flex' : 'none' }} src='images/closeIcon.png' alt='' onClick={() => deleteOldArticle(index)} />{item.name}</strong>
                                    })
                                    : ''
                            }
                            {
                                newArticles.length > 0 ?
                                    newArticles.map((item, index) => {
                                        return <strong><img style={{ display: editInvoice ? 'flex' : 'none' }} src='images/closeIcon.png' alt='' onClick={() => deleteArticle(index)} />{item.name}</strong>
                                    })
                                    : ''
                            }
                        </div>
                        <div id='service-item'>
                            <p>{language.MPC}</p>
                            {
                                curInvoice.Articles ?
                                    JSON.parse(curInvoice.Articles)?.map((item) => {
                                        return <strong>{item.mpc}</strong>
                                    })
                                    : ''
                            }
                            {
                                newArticles.length > 0 ?
                                    newArticles.map((item) => {
                                        return <strong>{item.mpc}</strong>
                                    })
                                    : ''
                            }
                        </div>
                        <div id='service-item'>
                            <p>{language.Cijena}</p>
                            {
                                curInvoice.Articles ?
                                    JSON.parse(curInvoice.Articles)?.map((item, index) => {
                                        return <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.price.replace(curInvoice.Currency, '')).replace('€', curInvoice.Currency)}</strong>
                                    })
                                    : ''
                            }
                            {
                                newArticles.length > 0 ?
                                    newArticles.map((item) => {
                                        return <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.price.replace(curInvoice.Currency, '')).replace('€', curInvoice.Currency)}</strong>
                                    })
                                    : ''
                            }
                        </div>
                        <div id='service-item'>
                            <p>{language.Kolicina}</p>
                            {
                                curInvoice.Articles ?
                                    JSON.parse(curInvoice.Articles)?.map((item) => {
                                        return <strong>{item.quantity}</strong>
                                    })
                                    : ''
                            }
                            {
                                newArticles.length > 0 ?
                                    newArticles.map((item) => {
                                        return <strong>{item.quantity}</strong>
                                    })
                                    : ''
                            }
                        </div>
                        <div id='service-item'>
                            <p>{language.Ukupno}</p>
                            {
                                curInvoice.Articles ?
                                    JSON.parse(curInvoice.Articles)?.map((item, index) => {
                                        return <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((item.price.replace(curInvoice.Currency, '') * JSON.parse(item.quantity)).toFixed(2)).replace('€', curInvoice.Currency)}</strong>
                                    })
                                    : ''
                            }
                            {
                                newArticles.length > 0 ?
                                    newArticles.map((item, index) => {
                                        return <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((item.price.replace(curInvoice.Currency, '') * (Number(item.quantity))).toFixed(2)).replace('€', curInvoice.Currency)}</strong>
                                    })
                                    : ''
                            }
                        </div>
                        <div id='service-item'>
                            <p>{language.PDV}</p>
                            {
                                curInvoice.Articles ?
                                    JSON.parse(curInvoice.Articles)?.map((item, index) => {
                                        return <strong>{curInvoice.PDV} %</strong>
                                    })
                                    : ''
                            }
                            {
                                newArticles.length > 0 ?
                                    newArticles.map((item) => {
                                        return <strong>{item.pdv}</strong>
                                    })
                                    : ''
                            }

                        </div>
                        <div id='service-item'>
                            <p>{language.UkupnoBezPDV}</p>
                            {
                                curInvoice.Articles ?
                                    JSON.parse(curInvoice.Articles)?.map((item, index) => {
                                        return <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((item.price.replace(curInvoice.Currency, '') * JSON.parse(item.quantity)).toFixed(2)).replace('€', curInvoice.Currency)}</strong>
                                    })
                                    : ''
                            }
                            {
                                newArticles.length > 0 ?
                                    newArticles.map((item, index) => {
                                        return <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((item.price.replace(curInvoice.Currency, '') * (Number(item.quantity))).toFixed(2)).replace('€', curInvoice.Currency)}</strong>
                                    })
                                    : ''
                            }
                        </div>
                    </div>
                    <br></br>
                    <hr></hr>
                    <br></br>

                    <div className='component__invoice__file__total'>
                        <div>

                        </div>
                        <div className='component__invoice__file__total__table'>
                            <img style={{ display: editInvoice && !calcEdit ? 'block' : 'none' }} onClick={() => editCalculation()} id='editCalcPen' src='images/editPen.png' alt='' />
                            <div style={{ display: paidModal && editInvoice && calcEdit ? 'block' : 'none' }} className='component__invoice__file__total__table__paidModal'>
                                <h5>{language.UnesiteKolikoJePlaćeno}</h5>
                                <div className='component__invoice__file__total__table__paidModal__body'>
                                    <div><input ref={paidInputRef} onChange={() => { setPaid(paidInputRef.current.value) }} type='text' /></div>
                                    <div><Button onClick={() => paidModalHandler()} className='close-btn' accent='transparent' value='Cancel' /> <Button onClick={() => addPaid()} className='add-paid-btn' accent='#67E564' value='Done' /></div>
                                </div>
                            </div>
                            <div style={{ display: avansModal && editInvoice && calcEdit ? 'block' : 'none' }} className='component__invoice__file__total__table__avansModal'>
                                <h5>{language.DodajAvans}</h5>
                                <div className='component__invoice__file__total__table__paidModal__body'>
                                    <div><input ref={avansInputRef} onChange={() => { setAvans(avansInputRef.current.value) }} type='text' /></div>
                                    <div><Button onClick={() => avansModalHandler()} className='close-btn' accent='transparent' value='Cancel' /> <Button onClick={() => addAvans()} className='add-paid-btn' accent='#67E564' value='Done' /></div>
                                </div>
                            </div>
                            <div style={{ display: rabatModal && editInvoice && calcEdit ? 'block' : 'none' }} className='component__invoice__file__total__table__rabatModal'>
                                <h5>{language.UnesiteRabat}</h5>
                                <div className='component__createNewInvoice__template__file__total__table__rabatModal__body'>
                                    <div><input ref={rabatInputRef} onChange={() => setRabat(rabatInputRef.current.value)} type='text' /><p ref={rabatOptRef}></p><p>{language.Odaberite}</p><p style={{ fontWeight: '900', color: 'green', cursor: 'pointer', fontSize: '16px' }} onClick={() => rabatOptionHandler('%')}>%</p><p style={{ fontWeight: '900', color: 'green', cursor: 'pointer' }} onClick={() => rabatOptionHandler(curInvoice?.Currency)}>{curInvoice?.Currency}</p></div>
                                    <div><Button onClick={() => rabatModalHandler()} className='close-btn' accent='transparent' value='Cancel' /> <Button onClick={() => addRabat()} className='add-rabat-btn' accent='#67E564' value='Done' /></div>
                                </div>
                            </div>
                            <table>
                                <tr>
                                    <td>{language.UkupnaCijenaFakture}</td>
                                    <td style={{ display: calcData.length > 0 ? 'none' : 'block' }}>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalWithoutPDV.replace(curInvoice.Currency, '')).replace('€', curInvoice.Currency)}</td>
                                    <td ref={newTotalRef} style={{ display: calcData.length > 0 ? 'block' : 'none', textAlign: 'right' }}></td>
                                </tr>
                                <tr>
                                    <td onClick={() => rabatModalHandler()}>{language.Rabat}({calcEdit ? newRabatPercent : Number(String(rabatPercent).replace('%', '')).toFixed(2)} %):</td>
                                    <td style={{ display: !calcEdit ? 'block' : 'none' }}>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(rabat.replace(curInvoice.Currency, '')).replace('BAM', 'a').replace('€', curInvoice.Currency)}</td>
                                    <td style={{ display: calcEdit ? 'block' : 'none', textAlign: 'right' }}>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Number(newRabat).toFixed(2)).replace('€', '')}{curInvoice.Currency}</td>
                                </tr>
                                <tr>
                                    <td>{language.UkupnoSaRabatom}</td>
                                    <td style={{ display: !calcEdit ? 'block' : 'none' }}>
                                        {
                                            curInvoice.Calculation ?
                                                JSON.parse(curInvoice.Calculation)?.map((item) => {
                                                    return <p>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.totalWithRabat.replace(curInvoice.Currency, '')).replace('€', curInvoice.Currency)}</p>
                                                })
                                                : ''
                                        }
                                    </td>
                                    <td style={{ display: calcEdit ? 'block' : 'none', textAlign: 'right' }}>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Number(newTotalWrabat).toFixed(2)).replace('€', '')}{curInvoice.Currency}</td>
                                </tr>
                                <tr>
                                    <td>{language.PDV} ({curInvoice.PDV}%):</td>
                                    <td style={{ display: !calcEdit ? 'block' : 'none' }}>
                                        {
                                            curInvoice.Calculation ?
                                                JSON.parse(curInvoice.Calculation)?.map((item) => {
                                                    return <p>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.PDVvalue).replace('€', curInvoice.Currency)}</p>
                                                })
                                                : ''
                                        }
                                    </td>
                                    <td style={{ display: calcEdit ? 'block' : 'none', textAlign: 'right' }}>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Number(newPDVvalue).toFixed(2)).replace('€', '')}{curInvoice.Currency}</td>
                                </tr>
                                <tr>
                                    <td>{language.UkupnoSaPDV}</td>
                                    <td style={{ display: !calcEdit ? 'block' : 'none' }}>
                                        {
                                            curInvoice.Calculation ?
                                                JSON.parse(curInvoice.Calculation)?.map((item) => {
                                                    return <p>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.TotalWithPDV.replace(curInvoice.Currency, '')).replace('€', curInvoice.Currency)}</p>
                                                })
                                                : ''
                                        }
                                    </td>
                                    <td style={{ display: calcEdit ? 'block' : 'none', textAlign: 'right' }}>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Number(newTotalWpdv).toFixed(2)).replace('€', '')}{curInvoice.Currency}</td>
                                </tr>
                                <tr>
                                    <td onClick={() => avansModalHandler()}>{language.Avans}({calcEdit ? Number(newAvansPercent).toFixed(2) : avansPercent}%)</td>
                                    <td style={{ display: !calcEdit ? 'block' : 'none' }}>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(avans.replace(curInvoice.Currency, '')).replace('€', curInvoice.Currency)}</td>
                                    <td style={{ display: calcEdit ? 'block' : 'none', textAlign: 'right' }}>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Number(newAvans).toFixed(2)).replace('€', '')}{curInvoice.Currency}</td>
                                </tr>
                                <tr>
                                    <td>{language.Ukupno}</td>
                                    <td style={{ display: !calcEdit > 0 ? 'block' : 'none' }}>
                                        {
                                            curInvoice.Calculation ?
                                                JSON.parse(curInvoice.Calculation)?.map((item) => {
                                                    return <p>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.Total.replace(curInvoice.Currency, '')).replace('€', curInvoice.Currency)}</p>
                                                })
                                                : ''
                                        }
                                    </td>
                                    <td style={{ display: calcEdit ? 'block' : 'none', textAlign: 'right' }}>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Number(newTotal).toFixed(2)).replace('€', '')}{curInvoice.Currency}</td>
                                </tr>
                                <tr>
                                    <td onClick={() => paidModalHandler()}>{language.Placeno}</td>
                                    <td style={{ display: !calcEdit ? ' block' : 'none' }}>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(paid.replace(curInvoice.Currency, '')).replace('€', curInvoice.Currency)}</td>
                                    <td style={{ display: calcEdit ? 'block' : 'none', textAlign: 'right' }}>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Number(newPaid).toFixed(2)).replace('€', '')}{curInvoice.Currency}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <br></br>
                    <hr></hr>
                    <br></br>

                    <div className='component__invoice__file__amountDue'>
                        <div>

                        </div>
                        <div>
                            <p> {language.Dug} ({curInvoice.Currency})</p>
                        </div>
                        <div style={{ display: !calcEdit ? 'block' : 'none' }}>
                            <h3>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(due.replace(curInvoice.Currency, '')).replace('€', curInvoice.Currency)}</h3>
                        </div>
                        <div style={{ display: calcEdit > 0 ? 'block' : 'none' }}>
                            <h3>{curInvoice?.Currency && new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(newDue).replace('€', curInvoice.Currency)}</h3>
                        </div>
                    </div>
                    <strong id='estimate-number' style={{ display: curInvoice.Type == 'FAKT.' && curInvoice.EstimateNumber != '' ? 'block' : 'none' }}>{language.PozivNaBroj} {curInvoice.EstimateNumber}</strong>
                    <div className='component__invoice__file__remark'>
                        <img style={{ display: editInvoice ? 'block' : 'none' }} onClick={() => editThing('remark')} id='editRemarkPen' src='images/editPen.png' alt='' />
                        {
                            curInvoice.Remark && !editRemark ?
                                JSON.parse(curInvoice.Remark)?.map((item) => {
                                    return <p>{item.Text}</p>
                                })
                                : ''
                        }
                        <div style={{ display: editRemark ? 'block' : 'none' }} className='component__invoice__file__remark__newRemark'>
                            <br></br>
                            <textarea placeholder='type...' ref={newRemarkRef} />
                        </div>
                    </div>

                    <div className='component__invoice__file__signature'>
                        <hr></hr>
                        <p>{language.Potpis}</p>
                    </div>

                    {
                        businesses.data?.map((item, index) => {
                            if (id == item.ID) {
                                return <div className='component__invoice__file__notes'>
                                    <div>
                                        <h1>{language.NaseAdrese}</h1>
                                        <p>{item.Address}, {item.City}, {item.ZIP}</p>
                                        <p>{item.WebsiteURL}</p>
                                    </div>
                                    <div>
                                        <h1>{language.PravniPodaci}</h1>
                                        <p>ID Broj:{item.IDnumber}</p>
                                        <p>PDV broj: {item.PDVnumber}</p>
                                        <p>IBAN: {item.IBAN}</p>
                                        <p>SWIFT: {item.SWIFT}</p>
                                    </div>
                                    <div>
                                        <h1>{language.Kontakti}</h1>
                                        <p>{item.PhoneNumber} - {item.FirstPerson}</p>
                                        <p>{item.SecPhoneNumber} - {item.SecPersonName}</p>
                                        <p>{item.Email}</p>
                                        <p>{item.WebsiteURL}</p>
                                    </div>
                                </div>
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}
