import React from 'react';
import "./index.scss";
import moment from 'moment';
import axios from 'axios';
import * as backendModule from "../../modules/backendModule";
import { useParams } from 'react-router-dom';
import Button from '../customComponents/Button';
import { FilteredCustomTable } from "../customComponents/Table";

export default function TravelOrder(props) {

  const [currency, setCurrency] = React.useState('BAM');
  const [currencyModal, setCurrencyModal] = React.useState(false);
  const [noData, setNoData] = React.useState(false);
  const [serverError, setServerError] = React.useState(false);
  const [currrencyData, setCurrencyData] = React.useState([]);
  const [filters, setFilters] = React.useState([]);

  const getaAllCurrencies = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/currencies/getAllCurrencies`,
      data: {
        filters: filters
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        !res.data.data.length > 0 ? setNoData(true) : setNoData(false);
        setCurrencyData(res.data);
      };
    }).catch(() => {
      setCurrencyData({ status: 'error', data: 'Data fetch failed' });
      return setServerError(true);
    });
  }

  React.useEffect(() => {
    getaAllCurrencies();
  }, []);

  const changeCurrency = (cur) => {
    setCurrency(cur);
    setCurrencyModal(false);
  }

  const { id } = useParams();
  const [workers, setWorkers] = React.useState([]);
  const getAllWorkers = (id) => {
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/workers/getAllWorkers`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id }
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        //res.data.data.length = 0;
        res.data.data.length > 0 ? setNoData(false) : setNoData(true);
        setWorkers(res.data);
      };
    }).catch(() => {
      setServerError({ type: 'SERVER_ERROR', data: 'Failed to fetch data' });
    });
  }

  React.useEffect(() => {
    getAllWorkers(id);
  }, [filters]);

  const [workersModal, setWorkersModal] = React.useState(false);
  const workersModalHandler = () => {
    setWorkersModal(w => !w);
  }

  const [selectedWorkers, setSelectedWorkers] = React.useState([]);
  const workersRef = React.useRef(null);

  const selectWorker = (worker) => {
    let counter = 0;
    selectedWorkers.map((item) => {
      if (item === worker) {
        counter++;
      }
    });

    if (counter === 0) {
      let tmp = selectedWorkers;
      tmp.push(worker);
      setSelectedWorkers(tmp);
      workersModalHandler();
    }
  }

  React.useEffect(() => {
    if (selectedWorkers.length > 0) {
      workersRef.current.value = workersRef.current.value + ' ' + selectedWorkers[selectedWorkers.length - 1];
    }
  }, [selectedWorkers.length]);

  const numRef = React.useRef(null);
  React.useEffect(() => {
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/travelorders/getAllTravelOrders`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id }
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        if (res.data.data.length === 0) {
          numRef.current.innerText = `${String(moment().year()).slice(2, 4)}/5000/${res.data.data.length + 1}`;
        }
        else {
          numRef.current.innerText = `${String(moment().year()).slice(2, 4)}/5000/${res.data.data.length + 1}`;
        }
      };
    }).catch(() => {
      setServerError({ type: 'SERVER_ERROR', data: 'Failed to fetch data' });
    });
  }, []);

  const [vehicles, setVehicles] = React.useState([]);
  const [vehiclesModal, setVehiclesModal] = React.useState(false);
  const getAllVehicles = (id) => {
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/vehicles/getAllVehicles`,
      data: {
        filters: [
          ...filters,
          { name: 'BusinessID', op: 'eq', value: id }
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setVehicles(res.data);
      };
    }).catch(() => {
      setServerError({ type: 'SERVER_ERROR', data: 'Failed to fetch data' });
    });
  }

  React.useEffect(() => {
    getAllVehicles(id);
  }, [filters]);

  const vehiclesModalHandler = () => {
    setVehiclesModal(v => !v);
  }

  const [selectedVehicles, setSelectedVehicles] = React.useState([]);
  const selectVehicle = (regmark) => {
    let counter = 0;
    selectedVehicles.map((item) => {
      if (item === regmark) {
        counter++;
      }
    });

    if (counter === 0) {
      let tmp = selectedVehicles;
      tmp.push(regmark);
      setSelectedVehicles(tmp);
      vehiclesModalHandler();
    }
  }

  const vehiclesRef = React.useRef(null);

  React.useEffect(() => {
    if (selectedVehicles.length > 0) {
      vehiclesRef.current.value = vehiclesRef.current.value + ' ' + selectedVehicles[selectedVehicles.length - 1];
    }
  }, [selectedVehicles.length]);

  const workPlaceRef = React.useRef(null);
  const whereRef = React.useRef(null);
  const whyRef = React.useRef(null);
  const dateStartRef = React.useRef(null);
  const dateEndRef = React.useRef(null);
  const expensesRef = React.useRef(null);
  const relationRef = React.useRef(null);
  const akontacijaRef = React.useRef(null);
  const brRef = React.useRef(null);
  const msgRef = React.useRef(null);

  const addOrder = (id) => {
    if (
      !workPlaceRef.current.value ||
      !whereRef.current.value ||
      !whyRef.current.value ||
      !dateStartRef.current.value ||
      !dateEndRef.current.value ||
      !expensesRef.current.value ||
      !relationRef.current.value ||
      !akontacijaRef.current.value ||
      !brRef.current.value
    ) {
      msgRef.current.innerText = 'Info missing!';
      msgRef.current.style.display = 'flex';
      msgRef.current.style.background = 'red';
      return setTimeout(() => {
        msgRef.current.style.display = 'none';
      }, 1500);
    }

    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/travelorders/addTravelOrder`,
      data: {
        BusinessID: id,
        Currency: currency,
        Travels: workersRef.current.value,
        WorkPlace: workPlaceRef.current.value,
        Where: whereRef.current.value,
        Why: whyRef.current.value,
        Date: moment(),
        DateStart: dateStartRef.current.value,
        DateEnd: dateEndRef.current.value,
        Expenses: expensesRef.current.value,
        With: vehiclesRef.current.value,
        Relation: relationRef.current.value,
        Akontacija: akontacijaRef.current.value,
        Br: brRef.current.value
      },
      ...backendModule.axiosConfig
    }).then(res => {
      msgRef.current.innerText = 'You have successfully created a travel order!';
      msgRef.current.style.display = 'flex';
      msgRef.current.style.background = 'rgb(103, 229, 100)';
      return setTimeout(() => {
        msgRef.current.style.display = 'none';
        props.handler();
        props.getAll();
      }, 1500);
    }).catch(() => {
      setServerError({ type: 'SERVER_ERROR', data: 'Failed to fetch data' });
    });
  }

  const cleanWorkers = () => {
    setSelectedWorkers([]);
    workersRef.current.value = '';
  }

  const cleanVehicles = () => {
    setSelectedVehicles([]);
    vehiclesRef.current.value = '';
  }

  const addWorkersToTable = () => {
    if (workers.data) {
      return workers?.data.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.Name}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.Department}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info'><h3>{item.PhoneNumber}</h3></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "button", text: "Select", onClick: e => { selectWorker(item.Name) } },
            ]
          }
        ];
      });
    }
    else {
      return null;
    }
  }

  const addVehiclesToTable = () => {
    if (vehicles.data) {
      return vehicles?.data.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.Manufacturer}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.Model}</h3></div> },
          { keyID: String(item.ID), type: "custom", data: <div id='table-company-info' ><h3>{item.RegistrationMarks}</h3></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "button", text: "Select", onClick: e => { selectVehicle(item.RegistrationMarks) } },
            ]
          }
        ];
      });
    }
    else {
      return null;
    }
  }

  return (
    <div className='component__travelOrder'>
      <p ref={msgRef} id='msg'></p>
      <div style={{ display: vehiclesModal ? 'block' : 'none' }} className='component__travelOrder__vehiclesModal'>
        <div id='box'>
          <div id='list'>
            <FilteredCustomTable
              id='table'
              accent='#00A3FF'
              theme='light'
              headers={['Manufacturer', 'Model', 'Reg. marks']}
              filters={[
                { name: "Manufacturer", friendlyName: "Manufacturer", type: "string" }
              ]}
              filterCB={f => setFilters(f)}

              data={!serverError && !noData ? addVehiclesToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
          </div>
          <br></br>
          <Button value='Close' onClick={() => vehiclesModalHandler()} />
        </div>
      </div>
      <div style={{ display: workersModal ? 'block' : 'none' }} className='component__travelOrder__workersModal'>
        <div id='box'>
          <div id='list'>
            <FilteredCustomTable
              id='table'
              accent='#00A3FF'
              theme='light'
              headers={['Name', 'Department', 'Phone number']}
              filters={[
                { name: "Name", friendlyName: "Name", type: "string" }
              ]}
              filterCB={f => setFilters(f)}

              data={!serverError && !noData ? addWorkersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
          </div>
          <br></br>
          <Button className='closeBtn' value='Close' onClick={() => workersModalHandler()} />
        </div>
      </div>
      <div className='component__travelOrder__currencyModal'>
        <strong onClick={() => { setCurrencyModal(m => !m) }}>Select currency</strong>
        <div style={{ display: currencyModal ? 'block' : 'none' }}>
          {
            currrencyData?.data?.map((item) => {
              return <p style={{ cursor: 'pointer' }} onClick={() => changeCurrency(item.Code)}>{item.Code}</p>
            })
          }
        </div>
      </div>
      <div className='component__travelOrder__left'>
        <h1>IZVJEŠTAJ</h1>
        <h2>o izvršenom <br></br> putovanju</h2>
        <p>(potpis podnosioca izvještaja)</p>
      </div>
      <div className='component__travelOrder__right'>
        <Button value='Save' className='saveOrderBtn' onClick={() => addOrder(id)} />
        <span><p style={{ marginRight: '5px' }}>Broj:</p><p ref={numRef}> 564544</p></span>
        <span><p style={{ marginRight: '5px' }}>Datum:</p><p>{moment().format('DD.MM.YYYY.')}</p></span>

        <span><p>na osnovu člana 13. Uredbe o naknadama troškova za službena putovanja ("Službene novine Federacije "BiH", br.<input ref={brRef} id='small-input' type='text' />), Obscale izdaje</p></span>

        <h1>PUTNI NALOG ZA SLUŽBENO PUTOVANJE</h1>
        <span><p>kojim se državni službenik </p><img onClick={() => cleanWorkers()} id='clearBtn' src='images/close2.png' alt='' /><input ref={workersRef} onClick={() => workersModalHandler()} /><p>, raspoređen na poziciju</p></span>
        <span><input ref={workPlaceRef} type='text' /><p>, upućuje na službeno putovanje u</p></span>
        <span><input ref={whereRef} id='long-input' type='text' />, u svrhu</span>
        <span><input ref={whyRef} id='super-long-input' type='text' /></span>
        <br></br>
        <span><p>Službeno putovanje će trajati od </p><input ref={dateStartRef} id='date-input' type='date' /><p>do </p><input ref={dateEndRef} id='date-input' type='date' /></span>
        <span><p>Odobravaju se troškovi za noćenja u iznosu do</p><input ref={expensesRef} id='money-input' type='number' /><p>{currency}</p></span>
        <span><p>odobarava se upotreba</p><img onClick={() => cleanVehicles()} id='clearBtn' src='images/close2.png' alt='' /><input ref={vehiclesRef} id='medium' type='text' onClick={() => vehiclesModalHandler()} /><p>kao prijevoznog sredstva za službeni</p></span>
        <span><p>put na relaciji</p><input ref={relationRef} id='long-input' type='text' /></span>
        <span><p>Odobarava se isplata akontacije u iznosu od</p><input ref={akontacijaRef} id='money-input' type='number' /><p>{currency}</p></span>
        <p>
          Državni službenik je dužan u roku od 5 dana od dana završetka službenog putovanja sačiniti izvještaj o obavljenom službenom putu, te isti zajedno sa ovim putnim nalogom i računima vezanim uz izdatke tokom službenog puta dostaviti izdavaocu putnog naloga.
        </p>
        <span id='foo'><p>M.P.</p><p>Ovlašteno lice</p></span>
      </div>
    </div>
  )
}
