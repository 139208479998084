import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import { setAxiosDataHandler } from "./modules/backendModule";

import allReducers from "./reducers";
import {createStore} from "redux";
import {Provider} from "react-redux";
import {composeWithDevTools} from "redux-devtools-extension/developmentOnly";

setAxiosDataHandler();

let store = createStore(allReducers, composeWithDevTools());

const rootElem = createRoot(document.querySelector("#root"));
rootElem.render(<Provider store={store}>
    <App />
</Provider>);